import { Footer, FooterBottomLinks } from "../Footer"
import { Header } from "../Header"
import style from "./style.module.scss"

import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};

export const Layout = ({ children }) => {

    return (
        <ScrollToTop>
            <Header />
            {children}
            <Footer radius />
        </ScrollToTop>
    )
}

export const BasicLayoutContainerWithoutHeader = ({ children }) => {
    return (
        <ScrollToTop>
            <div className={style["page-content-container"]}>

                {children}
                <Footer radius />
            </div>
        </ScrollToTop>

    )
}

export const BasicLayout = ({ children, footer = true, isLight }) => {
    return (
        <ScrollToTop>
            <div className={style["page-content-container"]}>
                <Header isLight={isLight} />
                <div className={style.contents}>
                    {children}
                </div>
                {
                    footer && (
                        <div className={style.footer}>
                            <FooterBottomLinks radius />
                        </div>
                    )
                }

            </div>
        </ScrollToTop>

    )
}