import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import style from "./style.module.scss"
import { setCookie, getCookie } from '../../../../helpers/func'
import { clx } from "../../../../helpers/components"

export function BandeauBasPage() {
  const { t } = useTranslation("translations") 

  const [visibleCookieNotif, showCookieNotif] = useState(false); 

  useEffect(() => {
    showCookieNotif(!getCookie("cookie_acceptation"));
  }, []);

  const handleRefused = () => {
    showCookieNotif(false);
  }

  const handleAccept = () => { 
    showCookieNotif(!visibleCookieNotif);  
    setCookie("cookie_acceptation", "true", 365);
  };

  return <div> {(
    visibleCookieNotif && (
          <div className={clx("container", style.bandeau)} id="bandeau_id">
              <p className={style.pp}>{t('cookie.subject')} <a href="/cookies" target="_blank" className={style.a}>{t('cookie.sjt1')}</a></p>
              <p className={style.pbtn}>
                <button onClick={handleRefused} id={style.refuser} >{t('cookie.refuse')}</button>
                <button onClick={handleAccept} id={style.accepter}>{t('cookie.accept')}</button>
              </p>
          </div>
      )
  )} 
  </div>
};