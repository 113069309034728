import React from "react"
import { useMemo } from "react";
import { useLocation } from "react-router-dom";



/**
 * 
 * @param el string | element
 */
export const useIntersectionListener = (el, props) => {
    const { onlyFirstIntersection = false } = props ?? {}
    // const { onlyFirstIntersection = false, cover = 0.8 } = props ?? {}
    const [data, setData] = React.useState({
        isIntersecting: false,
        entry: null
    })
    const router = useLocation()
    

    let intersection = React.useRef(null);
    let elRef = React.useRef(null);

    const listener = () => {
        intersection.current = new IntersectionObserver((entries) => {
            const intersectEl = entries[0]

            setData((prev) => {
                if (onlyFirstIntersection && prev.isIntersecting) {
                    return prev;
                }

                return ({
                    ...prev,
                    isIntersecting: intersectEl.isIntersecting,
                    entry: intersectEl
                })
            })
        })

        intersection.current.observe(elRef.current)


    }

    const handleIntersection = () => {
        if (!elRef.current) {
            elRef.current = typeof el === "string" ? document.querySelector(el) : el
        }

        if (elRef.current && window && !intersection.current) {
            if (document.readyState === "complete" || document.readyState === "loaded") {
                listener()
            }else{
                window.addEventListener("load", listener)
            }
           

            return () => {
                if (intersection.current) {
                    intersection.current.disconnect()
                    intersection.current = null;
                }

                //elRef.current = null
                window.removeEventListener("load", listener)
            }
        }

    }



    React.useEffect(handleIntersection, [el, router?.pathname])

    /*React.useEffect(()=>{
        handleViewportIntersection()
    }, [data])*/

    const { iterationCount, ...d } = data
    return {
        ...d,

    }
}


export const useScrollPosition = (handler, el) => {
    const element = useMemo(() => {
        return (el ?? window)
    }, [el])
    const handleScroll = (e) => {
        if (handler instanceof Function) {
            handler(element?.scrollX, element?.scrollY, e)
        }

    }
    React.useEffect((() => {
        if (element) {
            element.addEventListener("scroll", handleScroll);
            return () => {
                element.removeEventListener("scroll", handleScroll);
            }
        }

    }), [element])
}

