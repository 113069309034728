import React from "react";
import { useLocation } from "react-router-dom";
import { asyncLoadScript } from "../../../helpers/components";

export const RecaptchaContext=React.createContext()

export function RecaptchaProvider({children, apiKey}){

    const scriptTagRef=React.useRef() 
    const router=useLocation()
    React.useEffect(()=>{
        if(!scriptTagRef.current){
            scriptTagRef.current=asyncLoadScript("https://www.google.com/recaptcha/api.js?render="+apiKey);
            return ()=>{
                if(scriptTagRef.current){
                    scriptTagRef.current.remove()
                    scriptTagRef.current=null
                    document.querySelector(".grecaptcha-badge")?.remove()
                }
            }
        }
        
        return()=>{
            document.querySelector(".grecaptcha-badge")?.remove()
        }
        
    }, [scriptTagRef.current, router.pathname, apiKey])

    return(
        <RecaptchaContext.Provider value={{
            apiKey,
            scriptTagRef
        }} >
            {children}
        </RecaptchaContext.Provider>
    )
}