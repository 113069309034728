import { SpeakerFormular } from "../components/pages/speakerForm";
import { BasicLayout } from "../components/shared/Layout";


export function SpeakerForm() {
    return (
        <BasicLayout >
            <SpeakerFormular/>
        </BasicLayout >
    )
}