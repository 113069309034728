import { useTranslation } from "react-i18next";
import { clx } from "../../../../helpers/components";
import { Text } from "../../../ui/Text";
import style from "./style.module.scss"

import styleglobal from "./style.module.scss"
export function Banner() {
    const { t } = useTranslation("translations")
    return (
        <div className={clx(styleglobal.container, style.banner)}>
            <div className={clx(style.bannerContainer)}>
                <div className={style.subtitlebarContainer}>
                    <Text variant="h6" color="izi-primary-color">
                        {t('services-page.main-subtitle')}
                    </Text>
                    <p className={style.subtitlebar}></p>
                </div>
                <Text variant="h2" className={style.headerTitle}>
                    {t('services-page.second-subtitle')}
                    
                </Text>
                <Text className={clx(style.headerDescription)}>
                    {t('services-page.main-description')}
                </Text>
            </div>
            <div className={clx(style.bannerImage)}>
                <div className={clx(style.onMobile)}><img src="/images/service_ph.png" alt="service_image" width="100%" height="100%" /></div>
                <div className={clx(style.inner)}><img src="/images/service_ph.png" alt="service_image" width="100%" height="100%" /></div> 
            </div>
        </div>
    )
}





