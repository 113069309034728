import axios from "axios";
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { ProgressForm } from "../../shared/ProgessForm";
import { BASE_URL } from "../../../helpers/func";


export function AmbassadorRegistrationForm() {
    const { t, i18n } = useTranslation("translations")

    useEffect(() => {
        const CheckAndHideFooter = () => {
            const notifExist = localStorage.getItem('notif')
            const ftElement = document.querySelector('#preloader');
            const footers = document.getElementById('footerCustom');
            if(notifExist || !notifExist) {
                if(ftElement)
                    ftElement.style.display = 'none';
                if (footers) 
                    footers.style.display = 'none'; 
                
            } 
        }
        
        CheckAndHideFooter();
    }, [])


    const stepLen = 3
    const form = {
        email: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty", "email"],
            step: 1,
            type: "text",
            label: ("ambassadors-form.form.email.label"),
            placeholder: ("ambassadors-form.form.email.placeholder")
        },
        fullname: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "text",
            label: ("ambassadors-form.form.fullname.label"),
            placeholder: ("ambassadors-form.form.fullname.placeholder")
        },
        country: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "country-select",
            label: ("ambassadors-form.form.country.label")
        },
        whatsappNumber: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "tel",
            label: ("partners.partnersForm.whatsappNumber.label"),
            placeholder: ("partners.partnersForm.whatsappNumber.placeholder")
        },
        question1: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "textfield",
            label: ("ambassadors-form.form.questions.1.label"),
            placeholder: ("ambassadors-form.form.questions.1.placeholder")
        },
        question2: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "textfield",
            label: ("ambassadors-form.form.questions.2.label"),
            placeholder: ("ambassadors-form.form.questions.2.placeholder")
        },
        question3: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "textfield",
            label: ("ambassadors-form.form.questions.3.label"),
            placeholder: ("ambassadors-form.form.questions.3.placeholder")
        },
        question4: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "textfield",
            label: ("ambassadors-form.form.questions.4.label"),
            placeholder: ("ambassadors-form.form.questions.4.placeholder")
        },
        question5: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "textfield",
            label: ("ambassadors-form.form.questions.5.label"),
            placeholder: ("ambassadors-form.form.questions.5.placeholder")
        }
    }
    const imgSrc = "/images/amabssador-form.png"
    const handleSubmit = (form, setLoading, setSuccessModal, setOpenErrorModal) => {
        const params = new URLSearchParams();
        const datas = {
            "email": form.email.value,
            "whatsappTel": form.whatsappNumber.value,
            "additionnalFields": JSON.stringify({
                "fullname": form.fullname.value,
                "whatsappTel": form.whatsappNumber.value,
                "country": form.country.value,
                "question1":form.question1.value,
                "question2":form.question2.value,
                "question3":form.question3.value,
                "question4":form.question4.value,
                "question5":form.question5.value,
            })
        }
        Object.keys(datas).forEach((key) => {
            params.append(key, datas[key]);
        })

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'appversion':"2.0.1"
            },
            data: params,
            url: BASE_URL+"/api/pu_new/ambassadors"
        };
        setLoading(true)
        axios(options)
            .then(({ data }) => { 
                setSuccessModal(true)
            })
            .catch((error) => {
                setOpenErrorModal("global-form.errors.submission-error-retry")
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <ProgressForm
            defaultForm={form}
            stepLen={stepLen}
            imgSrc={imgSrc}
            successModalMessage={t("ambassadors-form.form.successModal.title")}
            successModalBtnTitle={t("ambassadors-form.form.successModal.btnTitle")}
            imgAlt={t("global-form.errors.should-fill-all-field")}
            onSubmit={handleSubmit}
            nextBtnLabel={t("ambassadors-form.form.nextBtn")}
            prevBtnLabel={t("ambassadors-form.form.prevBtn")}
        />
    )
}




