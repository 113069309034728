import React, {useRef} from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { clx } from "../../../helpers/components"
import { Button } from "../../ui/Button"
import { Logo } from "../Logo"
import style from "./style.module.scss"
import { useLocation } from "react-router-dom"
import { AUTH_TOKEN_KEY, getCookie, getMobileDownloadLink, hasTokenExpired, redirectToSecure } from "../../../helpers/func"
import { QRCode } from "../QrCode"
// import { DownloadPDF } from "../../pages/generalConditions"

export function Header({isLight}) {
    const [smOpen, setSmOpen] = React.useState(false)
    const [isLogged, setIsLogged] = React.useState(null)
    const { t, i18n } = useTranslation("translations")
    const location = useLocation()
    const languageMenuRef = useRef(null);
    
    const [downloadLink, setDownloadLink] = React.useState(undefined)

    React.useEffect(() => {
        setDownloadLink(getMobileDownloadLink())
    }, [])

    React.useEffect(() => {
        const token = getCookie(AUTH_TOKEN_KEY) 
        if (!token) {
            setIsLogged(false)
        } else {
            setIsLogged(!hasTokenExpired(token))
        }

    }, [])

    React.useEffect(() => {
        const lng = localStorage.getItem("izi_lng--active")
        if (lng && ["fr", "en"].includes(lng)) {
            handleLanguageChange(lng)
        }
    }, [])

    React.useEffect(() => {
        if (smOpen) {
            document.querySelector("body").style["overflow"] = "hidden"
        } else {
            document.querySelector("body").style["overflow"] = "auto"
        }
    }, [smOpen])

    React.useEffect(() => {
        function handleClickOutside(event) {
          if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
            //setSmOpen(false);
          }
        }
    
        // Attachez le gestionnaire d'événements
        document.addEventListener("click", handleClickOutside);
    
        // Nettoyez le gestionnaire d'événements lorsque le composant se démonte
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, []);

    const handleLanguageChange = (lng) => {
        document.querySelector('html').setAttribute('lang', lng);
        i18n.changeLanguage(lng)
        localStorage.setItem("izi_lng--active", lng)
        
        setOpen(false);
    }

    const Menu = {

        leftMenus: [
            {
                label: t('pages-header-links.services'),
                href: "/services",
                className: "cursor-pointer " + (location.pathname.includes("/services") ? style.active : "")
            },
            {
                label: t('pages-header-links.about-us'),
                href: "/a-propos",
                className: "cursor-pointer " + (location.pathname.includes("/a-propos") ? style.active : "")
            },
            {
                label: t('pages-header-links.blog'),
                href: "https://blog.izichange.com",
                className: "cursor-pointer"
            },
            {
                label: t('pages-header-links.partners'),
                href: "/partners",
                className: "cursor-pointer " + (location.pathname.includes("/partners") ? style.active : "")
            },
            
        ],
        rightMenus: [
            
            {
                label: t('pages-header-links.app-download'),
                href: downloadLink ?downloadLink: "#",
                className: "cursor-pointer",
                children: <>
                    <div className={style.qrCodeContainer}>
                        <QRCode
                            fgColor="var(--color-dark-light)"

                            className={style.qrCode}
                        />
                        <p>
                            {t("pages-header-links.app-dowload-description")}
                        </p>
                    </div>
                </>
            },
            {
                label: t('pages-header-links.current-language'),
                className: "",
                showOnMobile: true,
                children: [
                    {
                        "label": "Français",
                        "href": "#",
                        className: i18n.language === "fr" && style.active,
                        onClick: () => handleLanguageChange("fr")
                    },
                    {
                        "label": "English",
                        "href": "#",
                        className: i18n.language === "en" && style.active,
                        onClick: () => handleLanguageChange("en")
                    }
                ]
            },
            {
                label: t('pages-header-links.current-language'),
                className: "",
                showOnMobile: false,
                children: [
                    {
                        "label": "Français",
                        "href": "#"
                    },
                    {
                        "label": "English",
                        "href": "#"
                    }
                ]
            }
        ],
        authMenus: isLogged === false ? [

            {
                label: t('pages-header-links.login'),
                variant: location.pathname.includes("/sign-up") ? "contained" : "cleared",
                href: "/login",
                className: "cursor-pointer " + ( (location.pathname.includes("/login") ? " d-none" : "")),
            },
            {
                label: t('pages-header-links.register'),
                variant: "contained",
                href: "/sign-up",
                className: "cursor-pointer " + ( (location.pathname.includes("/sign-up") ? " d-none" : "")),
            },
        ] : isLogged === true ? [
            {
                label: t('pages-header-links.dashboard'),
                variant: "contained",
                href: "#",
                onClick: (e) => {
                    e.preventDefault()
                    redirectToSecure("dashboard/pu_echange", i18n.language, true, true);
                },
                className: "cursor-pointer " + style.active,
            },
        ] : []
    }
    const [open, setOpen] = React.useState(false)
    
    //
    const headerClassName = isLight ?  style.darkModeHeader  : "";
    const MenuClassName = isLight ?  style.darkModeHeaderMenu : "";
    
    return (

        <>
            <div className={clx(style.headerContainer)}>
                <div className={clx(style.header, smOpen && style.fixed)} id="headerStyleCafe">
                    <div className={clx(style.headerContentContainer, headerClassName)}>
                        <div className={clx(style.headerContent, "container sm:hide")}>
                            <Link to="/"  aria-label="logo izichange">
                                <Logo />
                            </Link>
                            <div className={style.btnContainer}>
                                <div className={style.leftButtonsContainer}>
                                    {
                                        Menu.leftMenus.map((m, i) => (
                                            m.href.includes("http") ?
                                                <a href={m.href} key={i}  aria-label={`Link to ${m.label}`} target="_blank" rel="noopener noreferrer">
                                                    <Button  className={clx(style["primary-focus"], m.className, MenuClassName)} variant="cleared" bgColor="transparent" color="izi-primary-dark-color" ariaLabel={m.label}>{m.label}</Button>
                                                </a>
                                                :
                                                <Link to={m.href} key={i} >
                                                    <Button  className={clx(style["primary-focus"], m.className, MenuClassName)} variant="cleared" bgColor="transparent" color="izi-primary-dark-color" ariaLabel={m.label}>{m.label}</Button>
                                                </Link>
                                        ))
                                    }
                                </div>
                                <div className={style.righButtonsContainer}>
                                    {
                                        Menu.authMenus.map((a, i) => (
                                            a.variant === "contained" ? (
                                                <Link to={a.href} aria-label={a.href} key={i} onClick={a.onClick}>
                                                    <Button className={clx(a.className,MenuClassName)} ariaLabel={a.label}>{a.label}</Button>
                                                </Link>
                                            ) :
                                                <Link to={a.href} aria-label={a.href} key={i} onClick={a.onClick}>
                                                    <Button className={clx(style["primary-focus"], a.className,MenuClassName)} color="izi-primary-dark-color" ariaLabel={a.label} variant="cleared" bgColor="transparent">{a.label}</Button>
                                                </Link>
                                        ))
                                    }
                                    <Button  
                                        onClick={() => setSmOpen(prev => !prev)}
                                        className={clx(style["primary-focus"], style["mobile-menu-flag"])}
                                        color="izi-primary-dark-color"
                                        variant="cleared"
                                        bgColor="transparent"
                                        ariaLabel="download"
                                    >
                                        {
                                            smOpen ? (
                                                <svg className="close" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 15L15 2M2 2L15 15" stroke="#008080" strokeWidth="2.5" strokeLinecap="round" />
                                                </svg>
                                            ) :
                                                (
                                                    <svg className="open" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.47619 16L17.6667 16M10.9524 2L17.6667 2M2 9H17.6667" stroke="#008080" strokeWidth="2.5" strokeLinecap="round" />
                                                    </svg>
                                                )
                                        }



                                    </Button>
                                    <div className={style["right-menu-buttons"]}>
                                        {
                                            Menu.rightMenus.filter(m => m && (m.showOnMobile === undefined || m.showOnMobile === false)).map((m, i) => {
                                                const id = "right-" + i

                                                return (
                                                    (
                                                        <div key={id} className={clx(style.link, (id === open ? style.active : ""))}>

                                                            {
                                                                m.children ? (

                                                                    <>
                                                                        <Button onClick={() => {
                                                                            setOpen(prev => prev ? false : id)
                                                                        }} className={clx(style["primary-focus"], m.className,MenuClassName)} variant="cleared" bgColor="transparent" color="izi-primary-dark-color" ariaLabel={m.label}>
                                                                            {m.label}
                                                                            <span>
                                                                                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1" stroke={isLight? "#fff": "#2D264B"} strokeWidth="1.5" strokeLinecap="round" />
                                                                                </svg>
                                                                            </span>

                                                                        </Button>

                                                                        <div className="closeup">
                                                                            <div className={style.sublinkbox} ref={languageMenuRef}>
                                                                                {
                                                                                    m.children instanceof Array && m.children.length ?
                                                                                        <>
                                                                                            <Button className={clx(style["primary-focus"], i18n.language === "fr" && clx(style.active, "bolded"))} color={"izi-primary-color"} onClick={() => handleLanguageChange("fr")} ariaLabel="fr">Français</Button>
                                                                                            <Button className={clx(style["primary-focus"], i18n.language === "en" && clx(style.active, "bolded"))} color="izi-primary-color" onClick={() => handleLanguageChange("en")} ariaLabel="en">English</Button>

                                                                                        </>
                                                                                        :
                                                                                        <>{m.children}</>   
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                    :
                                                                    (
                                                                        m.href.includes("http") ?
                                                                            <a href={m.href} aria-label={m.href} key={i}>
                                                                                <Button className={clx(style["primary-focus"], m.className)} ariaLabel={m.label} variant="cleared" bgColor="transparent" color="izi-primary-dark-color">
                                                                                    {m.label}
                                                                                    <span >
                                                                                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1" stroke="#2D264B" strokeWidth="1.5" strokeLinecap="round" />
                                                                                        </svg>
                                                                                    </span>

                                                                                </Button>
                                                                            </a> :
                                                                            <Link to={m.href} aria-label={m.href} key={i}>
                                                                                <Button className={clx(style["primary-focus"], m.className)} ariaLabel={m.label} variant="cleared" bgColor="transparent" color="izi-primary-dark-color">
                                                                                    {m.label}
                                                                                    <span >
                                                                                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1" stroke="#2D264B" strokeWidth="1.5" strokeLinecap="round" />
                                                                                        </svg>
                                                                                    </span>

                                                                                </Button>
                                                                            </Link>
                                                                    )
                                                            }
                                                        </div>
                                                    )
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clx(style.overlayMenu)}>
                        <div className="container">
                            {
                                [...Menu.leftMenus, ...Menu.rightMenus, ...Menu.authMenus].filter(m => m && (m.showOnMobile === undefined || m.showOnMobile === true)).map((m, i) => (
                                    !Boolean(m.children?.length) ?
                                        (
                                            m.href.includes("http") ?
                                                <a key={i} href={m.href} aria-label="lien menu" className={style["btn-link"]}>
                                                    {m.label}
                                                </a> :
                                                <Link key={i} to={m.href} aria-label={m.href} className={style["btn-link"]}>
                                                    {m.label}
                                                </Link>
                                        )
                                        :
                                        <SubMenuButton
                                            label={m.label}
                                            menu={m.children}
                                            key={i}
                                        />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const LinkButton = ({ label, href }) => {
    return (
        <Link to={href} aria-label={label}>{label}</Link>
    )
}

export const SubMenuButton = ({ label, menu }) => {
    const [open, setOpen] = React.useState(false)
    return (
        <div className={clx(style.submenubutton, open && style.open)}>
            <Link onClick={() =>  {
                setOpen(prev => !prev)
            }} className={clx(style["btn-link"], " justify-content-between")} to={"#"} aria-label={label}>
                {label}
                <span className="ml-1rem">
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1" stroke="#2D264B" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </span>
            </Link>
            <div className={clx(style.submenu, "ml-1rem")}>
                {
                    menu instanceof Array ? menu.map((m, i) => (
                        <Link onClick={m.onClick} aria-label={m.label} className={clx(style["btn-link"], style["link"], m.className)} to={m.href} key={i}>{m.label}</Link>
                    ))
                        : menu
                }

            </div>
        </div>

    )
}

