import style from "./style.module.scss"
import React from "react";
import { clx } from "../../../helpers/components";

export function ModalContainerADP({ isOpened, children, wrapperApdp, elContainer, container }) {
    const { className: containerClassName = "", ...containerAttributes } = container ?? {}
    const { className: wrapperClassName = "", ...wrapperAttributes } = wrapperApdp ?? {}
    const { className: elContainerClassName = "", ...elContainerAttributes } = elContainer ?? {}

    React.useEffect(() => {
        if (isOpened) {
            document.querySelector("body").style.overflow = "hidden"
        } else {
            document.querySelector("body").style.overflow = "auto"
        }
    }, [isOpened])
    
    return (
        isOpened &&
        <div className={clx(style.wrapperApdp, wrapperClassName, )} {...wrapperAttributes}>
            <div className={clx(style.modalContainerADP, containerClassName, "show-fade-top", isOpened && "show")} onClick={(e) => e.stopPropagation()} {...containerAttributes}>
                <div className={clx(style.modalElADP, elContainerClassName)} {...elContainerAttributes}>
                {children}
                </div>
                
            </div>
        </div>
    )
}

