import { useTranslation } from "react-i18next";
import { clx } from "../../../../helpers/components";
import { SectionHeaderLink } from "../../../shared/TitleHeader";
import style from "./style.module.scss"

const CountryItem = ({
    label,
    bgColor,
    srcFlag
}) => {
    return (
        <div
            className={clx(style.countryItem, " rounded-full")}
            style={{
                "--item-bg-color": bgColor
            }}
        >
            <img src={srcFlag} alt={label} width='30px' height='30px'/>
            <h4>{label}</h4>
        </div>
    )
}

export const CountryList = () => {
    const { t } = useTranslation("translations")
    
    const countries = {
        firstRow: [
            {
                label: t("home-page.country-list.countries.1"),
                src: "/images/benin.png",
                bgColor: "rgba(220, 63, 77, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.2"),
                src: "/images/niger.png",
                bgColor: "rgba(50, 87, 168, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.3"),
                src: "/images/cmr.png",
                bgColor: "rgba(0, 128, 128, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.4"),
                src: "/images/togo.png",
                bgColor: "rgba(254, 255, 131, 0.3)"
            },
            {
                label:  t("home-page.country-list.countries.5"),
                src: "/images/mali.png",
                bgColor: "rgba(0, 128, 128, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.6"),
                src: "/images/congo.png",
                bgColor: "rgba(50, 87, 168, 0.2)"
            }
        ],
        lastRow: [
            {
                label: t("home-page.country-list.countries.7"),
                src: "/images/bkina.png",
                bgColor: "rgba(0, 128, 128, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.8"),
                src: "/images/senegal.png",
                bgColor: "rgba(254, 255, 131, 0.3)"
            },
            {
                label: t("home-page.country-list.countries.9"),
                src: "/images/guinee.png",
                bgColor: "rgba(50, 87, 168, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.10"),
                src: "/images/gabon.png",
                bgColor: "rgba(220, 63, 77, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.11"),
                src: "/images/ci.png",
                bgColor: "rgba(0, 128, 128, 0.2)"
            },
            {
                label: t("home-page.country-list.countries.12"),
                src: "/images/kenya.png",
                bgColor: "rgba(50, 87, 168, 0.2)"
            }
        ]
    }
    return (
        <div className="py-5rem">
            <div className={clx("container", style.sectionContainer)}>
                <div >
                    <SectionHeaderLink
                        title={t("home-page.country-list.title")}
                    />
                    <div >
                        <div className="mt-4rem">
                            <div className={clx(style.leftContainer, "w-fit", style.animate, style.showOnMobile)}>
                                <div className={style.centered}>
                                    <div className={clx(style.containerList, "w-full")}>
                                        {
                                            countries.firstRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }
                                        {
                                            countries.firstRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }

                                    </div>
                                    <div className={clx(style.containerList, "w-fit", "mt-2rem")}>
                                        {
                                            countries.lastRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }
                                        {
                                            countries.lastRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }

                                    </div>
                                </div>

                            </div>
                            <div className={clx(style.leftContainer, style.hideOnMobile)}>
                                <div className={style.centered}>
                                    <div className={clx(style.containerList)} >
                                        {
                                            countries.firstRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }


                                    </div>
                                    <div className={clx(style.containerList, "mt-2rem")}>
                                        {
                                            countries.lastRow.map((c, key) => (
                                                <CountryItem
                                                    key={key}
                                                    srcFlag={c.src}
                                                    bgColor={c.bgColor}
                                                    label={c.label}
                                                />
                                            ))
                                        }


                                    </div>
                                </div>

                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}