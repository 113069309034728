import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { clx, IntersectionWatcher } from "../../../../helpers/components"
import { Button } from "../../../ui/Button"
import style from "./style.module.scss"

export function AmbassadorBanner() {
    const { t } = useTranslation("translations")
    return (
        <div className={style.bannerContainer}>
            <div className={clx("container", style.banner)}>
                <div className={style.bannerContent}>
                    <IntersectionWatcher onlyFirstIntersection>
                        {
                            ({ ref, isIntersecting }) => {
                                return (
                                    <h2
                                        ref={ref}
                                        className={clx("bold", style["show-fade-left"], isIntersecting && style.show)}
                                    >
                                        {t("ambassador-page.banner.title-1")} <br /> {t("ambassador-page.banner.title-2")}</h2>
                                )
                            }
                        }
                    </IntersectionWatcher>
                    <IntersectionWatcher onlyFirstIntersection>
                        {
                            ({ ref, isIntersecting }) => {
                                return (
                                    <p ref={ref} className={clx(style["show-fade-bottom"], isIntersecting && style.show)}>{t("ambassador-page.banner.description")}</p>
                                )
                            }
                        }
                    </IntersectionWatcher >

                    <div>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => {
                                    return (
                                        <span ref={ref}>
                                            <Link to={"/new-ambassador"}>
                                                <Button className={clx("button", style["show-fade"], style.button, isIntersecting && style.show)} ariaLabel={t("ambassador-page.banner.ctaTitle")}>
                                                    {t("ambassador-page.banner.ctaTitle")}
                                                    <span
                                                        className="move"
                                                        dangerouslySetInnerHTML={{
                                                            __html: `
                                        <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 4.79283C1.30964 4.79283 0.75 5.35247 0.75 6.04283C0.75 6.73318 1.30964 7.29283 2 7.29283V4.79283ZM18.6087 7.29283C19.2991 7.29283 19.8587 6.73318 19.8587 6.04283C19.8587 5.35247 19.2991 4.79283 18.6087 4.79283V7.29283ZM18.6087 3.66393H17.3587H18.6087ZM21.2827 2.34785L20.5202 3.33835V3.33835L21.2827 2.34785ZM21.2827 9.73781L20.5202 8.7473L21.2827 9.73781ZM18.6087 8.42173H19.8587H18.6087ZM24.373 7.35891L25.1355 8.34942L24.373 7.35891ZM24.373 4.72675L25.1355 3.73624V3.73624L24.373 4.72675ZM2 7.29283H18.6087V4.79283H2V7.29283ZM23.6105 6.3684L20.5202 8.7473L22.0452 10.7283L25.1355 8.34942L23.6105 6.3684ZM19.8587 8.42173V3.66393H17.3587V8.42173H19.8587ZM20.5202 3.33835L23.6105 5.71725L25.1355 3.73624L22.0452 1.35734L20.5202 3.33835ZM19.8587 3.66393C19.8587 3.32297 20.25 3.13037 20.5202 3.33835L22.0452 1.35734C20.1311 -0.116135 17.3587 1.24837 17.3587 3.66393L19.8587 3.66393ZM20.5202 8.7473C20.25 8.95528 19.8587 8.76269 19.8587 8.42173L17.3587 8.42173C17.3587 10.8373 20.1311 12.2018 22.0452 10.7283L20.5202 8.7473ZM25.1355 8.34942C26.6491 7.18419 26.6491 4.90147 25.1355 3.73624L23.6105 5.71725C23.8241 5.88173 23.8241 6.20393 23.6105 6.3684L25.1355 8.34942Z" fill="white"/>
                                        </svg>
                                        
                                        `
                                                        }}
                                                    ></span>
                                                </Button>
                                            </Link>
                                        </span>
                                    )
                                }
                            }
                        </IntersectionWatcher>

                    </div>
                </div>
                <div className={clx(style.bannerImg)}>
                    <IntersectionWatcher onlyFirstIntersection>
                        {
                            ({ ref, isIntersecting }) => {
                                return (
                                    <div
                                        ref={ref}
                                        className={clx(style["show-fade-bottom"], isIntersecting && style.show)}
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "1500ms"
                                        }}
                                    >
                                        <img
                                            className={style.ambassadorImg}
                                            src="/images/ambassador-banner.png"
                                            alt="image ambassador banner"
                                        />
                                    </div>
                                )
                            }
                        }
                    </IntersectionWatcher>

                    <IntersectionWatcher onlyFirstIntersection>
                        {
                            ({ ref, isIntersecting }) => (
                                <div
                                    ref={ref}
                                    className={clx(style.userCard, style["show-fade"], isIntersecting && style.show)}
                                    style={{
                                        "--animation-duration": "400ms",
                                        "--animation-delay": "1900ms"
                                    }}
                                >
                                    <div className={style.userFlag}>
                                        <img src="/images/p_4.png" alt='bann' />
                                    </div>
                                    <div>
                                        <div className={style.cardTitle}>
                                            <h6 className="bold">{t('ambassador-page.banner.card-title')}</h6>
                                            <div>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: `
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 26C20.1796 26 26 20.1796 26 13C26 5.82029 20.1796 0 13 0C5.82029 0 0 5.82029 0 13C0 20.1796 5.82029 26 13 26ZM17.5961 8.40381C18.1039 7.89612 18.927 7.89612 19.4347 8.40381C19.9424 8.91149 19.9424 9.73461 19.4347 10.2423L12.0856 17.5913L12.0808 17.5961C11.9495 17.7274 11.7971 17.8248 11.6345 17.8883C11.4041 17.978 11.1533 17.9997 10.9132 17.9531C10.6674 17.9055 10.4326 17.7866 10.2423 17.5961L10.2374 17.5912L6.56536 13.9192C6.05767 13.4116 6.05767 12.5884 6.56536 12.0808C7.07304 11.5731 7.89616 11.5731 8.40384 12.0808L11.1615 14.8385L17.5961 8.40381Z" fill="#008080"/>
                                        </svg>
                                    `
                                                    }}
                                                >

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <p>{t('ambassador-page.banner.card-description')}</p>
                                </div>
                            )
                        }
                    </IntersectionWatcher>
                </div>
            </div>
        </div>
    )
}