import { useTranslation } from "react-i18next"
import { clx } from "../../../helpers/components"
import { Button } from "../../ui/Button"
// import { PartenerSectionTitle } from "../../ui/PartenerSectionTItle"
// import { TextField } from "../../ui/TextField"
import style from "./style.module.scss"
// import ReactDOM from "react-dom"
import React, {useState, useEffect} from "react"
import { ModalContainerADP } from "../../ui/ModalContainer"
import { ProgressForm } from "../../shared/ProgessForm"
import axios from "axios"
import { BASE_URL } from "../../../helpers/func"

export function PartnersFormular() {

    const { t, i18n } = useTranslation("translations")
    // const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)

    useEffect(() => {
        const CheckAndHideFooter = () => {
            // const notifExist = localStorage.getItem('notif')
            const ftElement = document.querySelector('#preloader');
            const footers = document.getElementById('footerCustom');
            // if(notifExist || !notifExist) {
            if (ftElement || footers) { 
                if(ftElement)
                    ftElement.style.display = 'none';
                if (footers) 
                    footers.style.display = 'none'; 
            }
                
            // } 
        }
        
        CheckAndHideFooter();
        
    }, [])

    
    
    const stepLen = 3
    const form = {
        lastname: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "text",
            label: ("partners.partnersForm.name.label"),
            placeholder: ("partners.partnersForm.name.placeholder")
        },
        firstname: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "text",
            label: ("partners.partnersForm.firstname.label"),
            placeholder: ("partners.partnersForm.firstname.placeholder")
        },
        email: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty", "email"],
            step: 1,
            type: "text",
            label: ("partners.partnersForm.email.label"),
            placeholder: ("partners.partnersForm.email.placeholder")
        },
        whatsappNumber: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "tel",
            label: ("partners.partnersForm.whatsappNumber.label"),
            placeholder: ("partners.partnersForm.whatsappNumber.placeholder")
        },
        workDomain: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "text",
            label: ("partners.partnersForm.workDomain.label"),
            placeholder: ("partners.partnersForm.workDomain.placeholder")
        },
        companyName: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "text",
            label: ("partners.partnersForm.companyName.label"),
            placeholder: ("partners.partnersForm.companyName.placeholder")
        },
        otherSource: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "text",
            label: ("partners.partnersForm.otherSource.label"),
            placeholder: ("partners.partnersForm.otherSource.placeholder")
        },
        country: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "country-select",
            label: ("partners.partnersForm.country.label"),
            placeholder: ("partners.partnersForm.country.placeholder")
        },
        transaction: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "text",
            label: ("partners.partnersForm.transaction.label"),
            placeholder: ("partners.partnersForm.transaction.placeholder")
        }
    }
    const imgSrc = "/images/partners.png" 
    const handleSubmit = (form, setLoading, setSuccessModal, setOpenErrorModal,reset) => {
        const params = new URLSearchParams();
        const datas = {
            "email": form.email.value,
            "firstname": form.firstname.value,
            "lastname": form.lastname.value,
            "whatsappTel": form.whatsappNumber.value,
            "additionnalFields": JSON.stringify({
                "country": form.country.value,
                "transaction": form.transaction.value,
                "otherSource": form.otherSource.value,
                "companyName": form.companyName.value,
                "workDomain": form.workDomain.value
            })
        }
        Object.keys(datas).forEach((key) => {
            params.append(key, datas[key]);
        })
        

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'appversion':"2.0.1"
            },
            data: params,
            url: BASE_URL+"/api/pu_new/partners"
        };
        setLoading(true)
        axios(options)
            .then(({ data }) => {   
                if(data.status==201){ 
                    reset();
                    setSuccessModal(true);
                }
                else
                    setOpenErrorModal("global-form.errors.submission-error-retry")
            })
            .catch((error) => {
                setOpenErrorModal("global-form.errors.submission-error-retry")
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <ProgressForm key={"partner"} 
                defaultForm={form}
                stepLen={stepLen}
                imgSrc={imgSrc}
                imgAlt={t("global-form.errors.should-fill-all-field")}
                successModalMessage={t("partners.partnersForm.successModal.title")}
                successModalBtnTitle={t("partners.partnersForm.successModal.btnTitle")}
                onSubmit={handleSubmit} 
                nextBtnLabel={t("partners.partnersForm.nextBtn")}
                prevBtnLabel={t("partners.partnersForm.prevBtn")}
            />
        </>
    )
}

export function ModalSuccess({ isOpen = false, onClose,
    successModalMessage,
    successModalBtnTitle
}) {

    return (
        <ModalContainerADP isOpened={isOpen}>
            <div className={clx(style.modal)}>
                <p></p>
                <img
                    src="/images/success_partenariat.png"  style={{width: '100%', height:'100%'}}
                />
                <p>{successModalMessage}</p>
                <Button onClick={onClose} ariaLabel={successModalBtnTitle}>{successModalBtnTitle}</Button>
                <p></p>
            </div>
        </ModalContainerADP>
    )
}

