import style from "./style.module.scss";
import {motion, AnimatePresence} from "framer-motion"
import { clx } from "../../../helpers/components";
import { MdClose } from "react-icons/md";

export function Alert({
    open = false,
    children,
    className,
    theme = "success",
    onClose = null,
    ...props
}) {
    const handleClose = (e) => {
        if (onClose instanceof Function) onClose(e)
    }
    return (
        <AnimatePresence>
            {
                open && (
                    <motion.div
                        style={{
                            marginTop: 20
                        }}
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0
                        }}
                        className={clx(style.alert, style[theme], className)}
                        {...props}
                    >
                        <div className="" style={{ width: "100%" }}>
                            {children}
                        </div>
                        {
                            onClose &&
                            (
                                <div className={style.btnContainer}>
                                    <button onClick={handleClose} className={style.btn}><MdClose /></button>
                                </div>
                            )
                        }
                    </motion.div>
                )
            }
        </AnimatePresence>
    )
}