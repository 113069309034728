import { Trans, useTranslation } from "react-i18next"
import { clx } from "../../../../helpers/components"
import { SectionHeader } from "../../../shared/TitleHeader"
import { Text } from "../../../ui/Text"
import style from "./style.module.scss"

export const TagSection = () => {
    const {t}=useTranslation("translations")
    return (
        <div className={clx("container", style.container)}>
            <SectionHeader
                subtitle={"Izichange"}
                position="center"
                title={<><Trans i18nKey={"home-page.tag-section.title-1"} t={t}></Trans> <span className="bolded">{t("home-page.tag-section.title-2")}</span></>}
            />
            <div className={style.descriptionContainer}>
                <div className={style.smImage}>
                    <div style={{}}> 
                    {/* width: '100%', height: '100%' */}
                        <img src="/images/plateform_secure.png" className={style.mainImage} alt={t("home-page.tag-section.mainImgAlt")} width='100%' height='auto'/>
                    </div>
                </div>
                <div>
                    <Tag
                        imgSrc={"/images/tag1.png"}
                        title={t("home-page.tag-section.tag-1.title")}
                        description={t("home-page.tag-section.tag-1.description")}
                    />
                    <Tag
                        imgSrc={"/images/tag2.png"}
                        title={t("home-page.tag-section.tag-2.title")}
                        description={t("home-page.tag-section.tag-2.description")}
                    />
                </div>
                <div style={{width: '250px', height: '220px', margin: '0px 2rem'}}>
                    <div style={{}}>
                    {/* width: '100%', height: '100%' */}
                        <img src="/images/plateform_secure.png" className={style.mainImage} alt={t("home-page.tag-section.mainImgAlt")} width='100%' height='auto'/>
                    </div>
                </div>
                <div>
                    <Tag
                        imgSrc={"/images/tag3.png"}
                        title={t("home-page.tag-section.tag-3.title")}
                        description={t("home-page.tag-section.tag-3.description")}
                    />
                </div>
            </div>
        </div>
    )
}

export const Tag = ({ title, description, imgSrc }) => {
    return (
        <div className={style.tag}>
            <div className={style.tagImg}>
                <img src={imgSrc} alt={title} />
            </div>
            <div>
                <Text variant="h6">{title}</Text>
                <Text>{description}</Text>
            </div>
        </div>
    )
}