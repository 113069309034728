import { useTranslation } from "react-i18next"
import { clx, IntersectionWatcher } from "../../../../helpers/components"
import { PartenerSectionTitle } from "../../../ui/PartenerSectionTItle"
import style from "./style.module.scss"

export function Advantages() {
    const { t } = useTranslation("translations")
    return (
        <div className={clx("container", style.wrapper)}>
            <div className={style.advantageWrapper}>
                <IntersectionWatcher onlyFirstIntersection>
                    {
                        ({ ref, isIntersecting }) => (
                            <div

                                ref={ref}
                                className={clx(style["show-fade-left"], isIntersecting && style.show)}

                            >
                                <PartenerSectionTitle
                                    title={t("partners.advantages.title")}
                                />
                            </div>
                        )
                    }
                </IntersectionWatcher>
                <div className={style.advantagesContainer}>
                    {
                        [
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="35" cy="35" r="35" fill="#DC3F4D" fill-opacity="0.2"/>
                                        <path d="M49.732 31.0705H43.8391C43.297 31.0705 42.857 31.5096 42.857 32.0526V49.7312C42.857 50.2744 43.297 50.7132 43.8391 50.7132H49.732C50.274 50.7132 50.714 50.2742 50.714 49.7312L50.7143 32.0531C50.7143 31.51 50.2743 31.0705 49.732 31.0705ZM48.7499 48.7491H44.8213V33.0347H48.7499V48.7491Z" fill="#DC3F4D"/>
                                        <path d="M37.946 38.9271H32.0531C31.5111 38.9271 31.0711 39.3661 31.0711 39.9091V49.7309C31.0711 50.2741 31.5111 50.7129 32.0531 50.7129H37.946C38.4881 50.7129 38.9281 50.2738 38.9281 49.7309L38.9283 39.9091C38.9283 39.3662 38.4883 38.9271 37.946 38.9271ZM36.964 48.7488H33.0354V40.8913H36.964V48.7488Z" fill="#DC3F4D"/>
                                        <path d="M26.1607 35H20.2678C19.7257 35 19.2857 35.4391 19.2857 35.982V49.7322C19.2857 50.2754 19.7257 50.7143 20.2678 50.7143H26.1607C26.7027 50.7143 27.1427 50.2752 27.1427 49.7322V35.982C27.1427 35.4391 26.7027 35 26.1607 35ZM25.1784 48.7502H21.2498V36.9642H25.1784V48.7502Z" fill="#DC3F4D"/>
                                        <path d="M32.3408 34.7121C32.7249 35.0962 33.3454 35.0962 33.7295 34.7121L45.8029 22.6387V24.1963C45.8029 24.7395 46.2429 25.1784 46.7849 25.1784C47.327 25.1784 47.767 24.7393 47.767 24.1963L47.7672 20.2677C47.7672 19.7245 47.3272 19.2856 46.7852 19.2856H42.8565C42.3145 19.2856 41.8745 19.7247 41.8745 20.2677C41.8745 20.8109 42.3145 21.2498 42.8565 21.2498H44.4142L33.0349 32.629L27.8363 27.4307C27.4522 27.0466 26.8316 27.0466 26.4476 27.4307C26.0635 27.8148 26.0635 28.4353 26.4476 28.8194L32.3408 34.7121Z" fill="#DC3F4D"/>
                                        <g filter="url(#filter0_dii_1_48455)">
                                        <path d="M49.732 31.0705H43.8391C43.297 31.0705 42.857 31.5096 42.857 32.0526V49.7312C42.857 50.2744 43.297 50.7132 43.8391 50.7132H49.732C50.274 50.7132 50.714 50.2742 50.714 49.7312L50.7143 32.0531C50.7143 31.51 50.2743 31.0705 49.732 31.0705ZM48.7499 48.7491H44.8213V33.0347H48.7499V48.7491Z" fill="url(#paint0_linear_1_48455)"/>
                                        <path d="M37.946 38.9271H32.0531C31.5111 38.9271 31.0711 39.3661 31.0711 39.9091V49.7309C31.0711 50.2741 31.5111 50.7129 32.0531 50.7129H37.946C38.4881 50.7129 38.9281 50.2738 38.9281 49.7309L38.9283 39.9091C38.9283 39.3662 38.4883 38.9271 37.946 38.9271ZM36.964 48.7488H33.0354V40.8913H36.964V48.7488Z" fill="url(#paint1_linear_1_48455)"/>
                                        <path d="M26.1607 35H20.2678C19.7257 35 19.2857 35.4391 19.2857 35.982V49.7322C19.2857 50.2754 19.7257 50.7143 20.2678 50.7143H26.1607C26.7027 50.7143 27.1427 50.2752 27.1427 49.7322V35.982C27.1427 35.4391 26.7027 35 26.1607 35ZM25.1784 48.7502H21.2498V36.9642H25.1784V48.7502Z" fill="url(#paint2_linear_1_48455)"/>
                                        <path d="M32.3408 34.7121C32.7249 35.0962 33.3454 35.0962 33.7295 34.7121L45.8029 22.6387V24.1963C45.8029 24.7395 46.2429 25.1784 46.7849 25.1784C47.327 25.1784 47.767 24.7393 47.767 24.1963L47.7672 20.2677C47.7672 19.7245 47.3272 19.2856 46.7852 19.2856H42.8565C42.3145 19.2856 41.8745 19.7247 41.8745 20.2677C41.8745 20.8109 42.3145 21.2498 42.8565 21.2498H44.4142L33.0349 32.629L27.8363 27.4307C27.4522 27.0466 26.8316 27.0466 26.4476 27.4307C26.0635 27.8148 26.0635 28.4353 26.4476 28.8194L32.3408 34.7121Z" fill="url(#paint3_linear_1_48455)"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_dii_1_48455" x="12.1754" y="11.1642" width="54.8952" height="54.8954" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="4.623" dy="3.61188"/>
                                        <feGaussianBlur stdDeviation="5.86667"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.0494118 0 0 0 0 0.0603922 0 0 0 0.2155 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_48455"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_48455" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="1.36635" dy="1.06751"/>
                                        <feGaussianBlur stdDeviation="0.866964"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.988706 0 0 0 0 0.283129 0 0 0 0 0.346047 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_48455"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-1.36635" dy="-1.06751"/>
                                        <feGaussianBlur stdDeviation="0.866964"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.705294 0 0 0 0 0.201971 0 0 0 0 0.246853 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect2_innerShadow_1_48455" result="effect3_innerShadow_1_48455"/>
                                        </filter>
                                        <linearGradient id="paint0_linear_1_48455" x1="21.5237" y1="16.4212" x2="46.2898" y2="35.7705" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F44655"/>
                                        <stop offset="1" stop-color="#BC3642"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_1_48455" x1="21.5237" y1="16.4212" x2="46.2898" y2="35.7705" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F44655"/>
                                        <stop offset="1" stop-color="#BC3642"/>
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_1_48455" x1="21.5237" y1="16.4212" x2="46.2898" y2="35.7705" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F44655"/>
                                        <stop offset="1" stop-color="#BC3642"/>
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_1_48455" x1="21.5237" y1="16.4212" x2="46.2898" y2="35.7705" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F44655"/>
                                        <stop offset="1" stop-color="#BC3642"/>
                                        </linearGradient>
                                        </defs>
                                        </svg>
                                    `
                                }}
                            ></span>,
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="74" height="82" viewBox="0 0 74 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="35" cy="35.9082" r="35" fill="#3257A8" fill-opacity="0.2"/>
                                        <path d="M34.9999 14.5454C27.8374 14.5454 21.7708 17.9096 18.8648 21.6423V21.6427C18.6736 21.8899 18.5704 22.1937 18.5714 22.5059V40.3145C18.5714 44.188 20.9334 47.1681 23.9549 49.621C26.976 52.074 30.7648 54.1539 34.2227 56.4547C34.4532 56.6073 34.7236 56.6883 35 56.6883C35.2763 56.6883 35.5468 56.6073 35.7773 56.4547C39.2351 54.1539 43.024 52.074 46.0451 49.621C49.0662 47.168 51.4285 44.1879 51.4285 40.3145V22.5059C51.4295 22.1937 51.3264 21.8899 51.1352 21.6427C48.2297 17.91 42.1624 14.5457 35 14.5457L34.9999 14.5454ZM34.9999 17.3551C41.0382 17.3551 46.2761 20.3195 48.6122 23.0766V40.3141C48.6122 43.0607 46.9846 45.2246 44.2555 47.4405C41.7252 49.4946 38.3361 51.4577 34.9994 53.6303C31.6633 51.458 28.2739 49.4949 25.7432 47.4405C23.0142 45.2246 21.3866 43.0607 21.3866 40.3141V23.0766C23.7223 20.3195 28.9604 17.3551 34.9988 17.3551H34.9999ZM43.4925 27.1884C43.4198 27.1838 43.3596 27.1939 43.2872 27.2027C42.9712 27.2445 42.671 27.3967 42.451 27.627L32.1831 37.9578L27.5331 34.0948C26.9831 33.5363 25.9369 33.5801 25.4357 34.1827C24.9344 34.7853 25.0919 35.8096 25.7438 36.246L31.3764 40.9138C31.9209 41.376 32.7784 41.3283 33.2685 40.8406L44.4458 29.6026C44.8649 29.195 44.9785 28.5041 44.7244 27.9783C44.5021 27.5181 44.0015 27.2097 43.4923 27.1882L43.4925 27.1884Z" fill="#3257A8"/>
                                        <g filter="url(#filter0_dii_1_48463)">
                                        <path d="M34.9999 14.5454C27.8374 14.5454 21.7708 17.9096 18.8648 21.6423V21.6427C18.6736 21.8899 18.5704 22.1937 18.5714 22.5059V40.3145C18.5714 44.188 20.9334 47.1681 23.9549 49.621C26.976 52.074 30.7648 54.1539 34.2227 56.4547C34.4532 56.6073 34.7236 56.6883 35 56.6883C35.2763 56.6883 35.5468 56.6073 35.7773 56.4547C39.2351 54.1539 43.024 52.074 46.0451 49.621C49.0662 47.168 51.4285 44.1879 51.4285 40.3145V22.5059C51.4295 22.1937 51.3264 21.8899 51.1352 21.6427C48.2297 17.91 42.1624 14.5457 35 14.5457L34.9999 14.5454ZM34.9999 17.3551C41.0382 17.3551 46.2761 20.3195 48.6122 23.0766V40.3141C48.6122 43.0607 46.9846 45.2246 44.2555 47.4405C41.7252 49.4946 38.3361 51.4577 34.9994 53.6303C31.6633 51.458 28.2739 49.4949 25.7432 47.4405C23.0142 45.2246 21.3866 43.0607 21.3866 40.3141V23.0766C23.7223 20.3195 28.9604 17.3551 34.9988 17.3551H34.9999ZM43.4925 27.1884C43.4198 27.1838 43.3596 27.1939 43.2872 27.2027C42.9712 27.2445 42.671 27.3967 42.451 27.627L32.1831 37.9578L27.5331 34.0948C26.9831 33.5363 25.9369 33.5801 25.4357 34.1827C24.9344 34.7853 25.0919 35.8096 25.7438 36.246L31.3764 40.9138C31.9209 41.376 32.7784 41.3283 33.2685 40.8406L44.4458 29.6026C44.8649 29.195 44.9785 28.5041 44.7244 27.9783C44.5021 27.5181 44.0015 27.2097 43.4923 27.1882L43.4925 27.1884Z" fill="url(#paint0_linear_1_48463)"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_dii_1_48463" x="6.03057" y="4.35331" width="67.6733" height="76.9592" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="4.86724" dy="7.21597"/>
                                        <feGaussianBlur stdDeviation="8.70404"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0682353 0 0 0 0 0.131765 0 0 0 0.24 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_48463"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_48463" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="1.51425" dy="2.24497"/>
                                        <feGaussianBlur stdDeviation="1.35396"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.395765 0 0 0 0 0.764235 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_48463"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-1.51425" dy="-2.24497"/>
                                        <feGaussianBlur stdDeviation="1.35396"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.272941 0 0 0 0 0.527059 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect2_innerShadow_1_48463" result="effect3_innerShadow_1_48463"/>
                                        </filter>
                                        <linearGradient id="paint0_linear_1_48463" x1="27.5343" y1="6.79132" x2="52.6866" y2="35.8647" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#3861BC"/>
                                        <stop offset="1" stop-color="#2A498D"/>
                                        </linearGradient>
                                        </defs>
                                        </svg>
                                    `
                                }}
                            ></span>,
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="70" height="75" viewBox="0 0 70 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="35" cy="35" r="35" fill="#008080" fill-opacity="0.2"/>
                                        <path d="M34.9997 32.7226C31.2524 32.7226 28.4324 27.782 28.4324 24.5782C28.4324 21.2335 31.3782 18.5125 34.9997 18.5125C38.6211 18.5125 41.5669 21.2335 41.5669 24.5782C41.5669 27.7817 38.7468 32.7226 34.9997 32.7226ZM34.9997 20.1791C32.2882 20.1791 30.0831 22.1526 30.0831 24.5781C30.0831 27.0769 32.3335 31.0554 34.9997 31.0554C37.6659 31.0554 39.9162 27.0766 39.9162 24.5781C39.9162 22.1526 37.7111 20.1791 34.9997 20.1791Z" fill="#008080"/>
                                        <path d="M44.2822 28.1726C43.8259 28.1726 43.4568 27.7996 43.4568 27.3392V24.292C43.4568 20.0871 39.6631 16.6669 34.9997 16.6669C30.3362 16.6669 26.5425 20.0874 26.5425 24.292V27.3392C26.5425 27.7995 26.1734 28.1726 25.7171 28.1726C25.2608 28.1726 24.8917 27.7996 24.8917 27.3392V24.292C24.8917 19.1685 29.4263 15 34.9997 15C40.573 15 45.1076 19.1683 45.1076 24.2912V27.3384C45.1076 27.7988 44.7385 28.1726 44.2822 28.1726Z" fill="#008080"/>
                                        <path d="M25.7171 30.4708H22.1795C21.7232 30.4708 21.3541 30.0978 21.3541 29.6374V23.9281C21.3541 23.4678 21.7232 23.0947 22.1795 23.0947H25.7171C26.1734 23.0947 26.5425 23.4677 26.5425 23.9281V29.6374C26.5425 30.0978 26.1734 30.4708 25.7171 30.4708ZM23.0048 28.8041H24.8917V24.7615H23.0048V28.8041Z" fill="#008080"/>
                                        <path d="M47.8198 30.4708H44.2822C43.8259 30.4708 43.4568 30.0978 43.4568 29.6374V23.9281C43.4568 23.4678 43.8259 23.0947 44.2822 23.0947H47.8198C48.2761 23.0947 48.6452 23.4677 48.6452 23.9281V29.6374C48.6452 30.0978 48.2761 30.4708 47.8198 30.4708ZM45.1075 28.8041H46.9944V24.7615H45.1075V28.8041Z" fill="#008080"/>
                                        <path d="M40.7361 25.6438C40.637 25.6438 40.537 25.6253 40.4388 25.5875C37.8283 24.5704 36.0057 23.4256 34.9974 22.1731C34.4912 23.2371 33.8068 24.0589 32.9486 24.629C31.1012 25.8571 29.1483 25.4079 29.0678 25.3892C28.6238 25.2825 28.3496 24.8339 28.4553 24.3857C28.5592 23.9392 29.0049 23.6659 29.4441 23.7663C29.4821 23.7745 30.8305 24.0541 32.0621 23.2231C33.038 22.5645 33.7191 21.3823 34.0883 19.7056C34.1131 19.3199 34.2179 19.0669 34.2535 18.9907C34.4307 18.6132 34.8546 18.4261 35.2498 18.5515C35.6441 18.6776 35.8842 19.079 35.8132 19.4898C35.7842 19.6536 35.7537 19.8141 35.7198 19.9716C35.7788 20.72 36.7557 22.3653 41.0337 24.032C41.4585 24.198 41.6704 24.6801 41.5061 25.1096C41.3794 25.4406 41.0675 25.6438 40.7361 25.6438Z" fill="#008080"/>
                                        <path d="M45.9468 40.621C45.6782 40.621 45.4148 40.4892 45.2567 40.2458C43.5078 37.5622 39.4818 35.8278 34.9994 35.8278C30.5169 35.8278 26.4909 37.5619 24.742 40.2455C24.4929 40.63 23.981 40.737 23.599 40.4834C23.2185 40.2304 23.1131 39.7135 23.3637 39.329C25.4093 36.1903 29.9768 34.1614 34.999 34.1614C40.0213 34.1614 44.5889 36.1903 46.6344 39.3297C46.8849 39.7141 46.7796 40.231 46.3991 40.484C46.259 40.5765 46.1018 40.621 45.9461 40.621H45.9468Z" fill="#008080"/>
                                        <path d="M45.3789 52.7443H24.6212C24.244 52.7443 23.9153 52.4868 23.8215 52.118L20.7404 39.9946C20.6766 39.7453 20.7314 39.4802 20.888 39.2768C21.0443 39.0733 21.2853 38.9542 21.54 38.9542H48.4603C48.715 38.9542 48.956 39.0733 49.1123 39.2768C49.2686 39.4802 49.3234 39.745 49.2599 39.9946L46.1785 52.1188C46.0848 52.4873 45.7561 52.7443 45.3789 52.7443ZM25.2611 51.0779H44.7389L47.3965 40.6206H22.6037L25.2611 51.0779Z" fill="#008080"/>
                                        <path d="M48.2145 55H21.784C21.3278 55 20.9586 54.627 20.9586 54.1666C20.9586 53.7062 21.3277 53.3332 21.784 53.3332H48.2145C48.6708 53.3332 49.0399 53.7062 49.0399 54.1666C49.0399 54.627 48.6708 55 48.2145 55Z" fill="#008080"/>
                                        <path d="M29.2579 33.1846H24.0536C23.5974 33.1846 23.2282 32.8116 23.2282 32.3512V29.6371C23.2282 29.1768 23.5973 28.8037 24.0536 28.8037C24.5099 28.8037 24.879 29.1767 24.879 29.6371V31.5179H29.2579C29.7142 31.5179 30.0833 31.8909 30.0833 32.3513C30.0833 32.8113 29.7141 33.1846 29.2579 33.1846Z" fill="#008080"/>
                                        <g filter="url(#filter0_dii_1_48470)">
                                        <path d="M34.9997 32.7226C31.2524 32.7226 28.4324 27.782 28.4324 24.5782C28.4324 21.2335 31.3782 18.5125 34.9997 18.5125C38.6211 18.5125 41.5669 21.2335 41.5669 24.5782C41.5669 27.7817 38.7468 32.7226 34.9997 32.7226ZM34.9997 20.1791C32.2882 20.1791 30.0831 22.1526 30.0831 24.5781C30.0831 27.0769 32.3335 31.0554 34.9997 31.0554C37.6659 31.0554 39.9162 27.0766 39.9162 24.5781C39.9162 22.1526 37.7111 20.1791 34.9997 20.1791Z" fill="url(#paint0_linear_1_48470)"/>
                                        <path d="M44.2822 28.1726C43.8259 28.1726 43.4568 27.7996 43.4568 27.3392V24.292C43.4568 20.0871 39.6631 16.6669 34.9997 16.6669C30.3362 16.6669 26.5425 20.0874 26.5425 24.292V27.3392C26.5425 27.7995 26.1734 28.1726 25.7171 28.1726C25.2608 28.1726 24.8917 27.7996 24.8917 27.3392V24.292C24.8917 19.1685 29.4263 15 34.9997 15C40.573 15 45.1076 19.1683 45.1076 24.2912V27.3384C45.1076 27.7988 44.7385 28.1726 44.2822 28.1726Z" fill="url(#paint1_linear_1_48470)"/>
                                        <path d="M25.7171 30.4708H22.1795C21.7232 30.4708 21.3541 30.0978 21.3541 29.6374V23.9281C21.3541 23.4678 21.7232 23.0947 22.1795 23.0947H25.7171C26.1734 23.0947 26.5425 23.4677 26.5425 23.9281V29.6374C26.5425 30.0978 26.1734 30.4708 25.7171 30.4708ZM23.0048 28.8041H24.8917V24.7615H23.0048V28.8041Z" fill="url(#paint2_linear_1_48470)"/>
                                        <path d="M47.8198 30.4708H44.2822C43.8259 30.4708 43.4568 30.0978 43.4568 29.6374V23.9281C43.4568 23.4678 43.8259 23.0947 44.2822 23.0947H47.8198C48.2761 23.0947 48.6452 23.4677 48.6452 23.9281V29.6374C48.6452 30.0978 48.2761 30.4708 47.8198 30.4708ZM45.1075 28.8041H46.9944V24.7615H45.1075V28.8041Z" fill="url(#paint3_linear_1_48470)"/>
                                        <path d="M40.7361 25.6438C40.637 25.6438 40.537 25.6253 40.4388 25.5875C37.8283 24.5704 36.0057 23.4256 34.9974 22.1731C34.4912 23.2371 33.8068 24.0589 32.9486 24.629C31.1012 25.8571 29.1483 25.4079 29.0678 25.3892C28.6238 25.2825 28.3496 24.8339 28.4553 24.3857C28.5592 23.9392 29.0049 23.6659 29.4441 23.7663C29.4821 23.7745 30.8305 24.0541 32.0621 23.2231C33.038 22.5645 33.7191 21.3823 34.0883 19.7056C34.1131 19.3199 34.2179 19.0669 34.2535 18.9907C34.4307 18.6132 34.8546 18.4261 35.2498 18.5515C35.6441 18.6776 35.8842 19.079 35.8132 19.4898C35.7842 19.6536 35.7537 19.8141 35.7198 19.9716C35.7788 20.72 36.7557 22.3653 41.0337 24.032C41.4585 24.198 41.6704 24.6801 41.5061 25.1096C41.3794 25.4406 41.0675 25.6438 40.7361 25.6438Z" fill="url(#paint4_linear_1_48470)"/>
                                        <path d="M45.9468 40.621C45.6782 40.621 45.4148 40.4892 45.2567 40.2458C43.5078 37.5622 39.4818 35.8278 34.9994 35.8278C30.5169 35.8278 26.4909 37.5619 24.742 40.2455C24.4929 40.63 23.981 40.737 23.599 40.4834C23.2185 40.2304 23.1131 39.7135 23.3637 39.329C25.4093 36.1903 29.9768 34.1614 34.999 34.1614C40.0213 34.1614 44.5889 36.1903 46.6344 39.3297C46.8849 39.7141 46.7796 40.231 46.3991 40.484C46.259 40.5765 46.1018 40.621 45.9461 40.621H45.9468Z" fill="url(#paint5_linear_1_48470)"/>
                                        <path d="M45.3789 52.7443H24.6212C24.244 52.7443 23.9153 52.4868 23.8215 52.118L20.7404 39.9946C20.6766 39.7453 20.7314 39.4802 20.888 39.2768C21.0443 39.0733 21.2853 38.9542 21.54 38.9542H48.4603C48.715 38.9542 48.956 39.0733 49.1123 39.2768C49.2686 39.4802 49.3234 39.745 49.2599 39.9946L46.1785 52.1188C46.0848 52.4873 45.7561 52.7443 45.3789 52.7443ZM25.2611 51.0779H44.7389L47.3965 40.6206H22.6037L25.2611 51.0779Z" fill="url(#paint6_linear_1_48470)"/>
                                        <path d="M48.2145 55H21.784C21.3278 55 20.9586 54.627 20.9586 54.1666C20.9586 53.7062 21.3277 53.3332 21.784 53.3332H48.2145C48.6708 53.3332 49.0399 53.7062 49.0399 54.1666C49.0399 54.627 48.6708 55 48.2145 55Z" fill="url(#paint7_linear_1_48470)"/>
                                        <path d="M29.2579 33.1846H24.0536C23.5974 33.1846 23.2282 32.8116 23.2282 32.3512V29.6371C23.2282 29.1768 23.5973 28.8037 24.0536 28.8037C24.5099 28.8037 24.879 29.1767 24.879 29.6371V31.5179H29.2579C29.7142 31.5179 30.0833 31.8909 30.0833 32.3513C30.0833 32.8113 29.7141 33.1846 29.2579 33.1846Z" fill="url(#paint8_linear_1_48470)"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_dii_1_48470" x="7.91436" y="8.6" width="54.1715" height="65.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="6.4"/>
                                        <feGaussianBlur stdDeviation="6.4"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.100392 0 0 0 0 0.100392 0 0 0 0.25 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_48470"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_48470" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="2.13333"/>
                                        <feGaussianBlur stdDeviation="1.06667"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.582275 0 0 0 0 0.582275 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_48470"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="-2.13333"/>
                                        <feGaussianBlur stdDeviation="1.06667"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.401569 0 0 0 0 0.401569 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect2_innerShadow_1_48470" result="effect3_innerShadow_1_48470"/>
                                        </filter>
                                        <linearGradient id="paint0_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint4_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint5_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint6_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint7_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        <linearGradient id="paint8_linear_1_48470" x1="35.0001" y1="15" x2="35.0001" y2="55" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#008F8F"/>
                                        <stop offset="1" stop-color="#006C6C"/>
                                        </linearGradient>
                                        </defs>
                                        </svg>

                                
                                `

                                }}
                            ></span>
                        ].map((icon, i) => (
                            <IntersectionWatcher key={i} onlyFirstIntersection>
                                {
                                    ({ ref, isIntersecting }) => (
                                        <div
                                            style={{
                                                "--animation-duration": "400ms",
                                                "--animation-delay":400+ ((i+1) * 300)+"ms"
                                            }}
                                            ref={ref}
                                            className={clx(style["show-fade-bottom"], isIntersecting && style.show)}
                                        >
                                            <Advantage
                                                icon={icon}
                                                title={t(`partners.advantages.advantages.${i + 1}.title`)}
                                                description={t(`partners.advantages.advantages.${i + 1}.description`)}
                                            />
                                        </div>
                                    )
                                }
                            </IntersectionWatcher>

                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export function Advantage({
    icon,
    title,
    description
}) {
    return (
        <div className={style.advantage}>
            <div>
                {icon}
            </div>
            <h5 className="bold">{title}</h5>
            <p>{description}</p>
        </div>
    )
}