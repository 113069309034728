import { GeneralConditions, Lexique, MentionLegales, Cookies, Warning, PolitiqueConf } from "../components/pages/generalConditions";
import { Layout } from "../components/shared/Layout";

export function GeneralConditionsPage() {
    
    return (

        <Layout>
           <GeneralConditions/>
        </Layout>
    )
}

export function PolitiqueConfPage() {
    return (
        <Layout>
            <PolitiqueConf />
        </Layout>
    )
}



export function LexiquePage() {
    
    return (

        <Layout>
           <Lexique/>
        </Layout>
    )
}

export function MentionLegalesPage() {
    
    return (

        <Layout>
           <MentionLegales/>
        </Layout>
    )
}




export function CookiesPolicyPage() {
    
    return (

        <Layout>
           <Cookies/>
        </Layout>
    )
}

export function WarningPage() {
    
    return (

        <Layout>
           <Warning/>
        </Layout>
    )
}

