import React from "react"
import { useIntersectionListener } from "../../helpers/hooks"

export const fadeSlideY = {
    initial: {
        position: "relative",
        top: 100,
        opacity: 0
    },
    animate: {
        position: "relative",
        top: 0,
        opacity: 1,
        transition: {
            delay:0.5,
            duration: 1,
            staggerChildren: 0.1,
            stiffness: 60,
        }
    },
    "animate-fade":{
        opacity:1
    }
}

export const fadeScaleOut={
    initial: {
        position: "relative",
        scale:0.8,
        opacity: 0
    },
    animate:{
        scale:1,
        opacity: 1,
        transition:{
            delay:0.5,
            duration:0.5
        }
    }
}

export const useEntryAnimation=(ref)=>{
    const {isIntersecting, entry}=useIntersectionListener(ref)
    const [hasAnimated, setHasAnimated]=React.useState(false)
    React.useEffect(()=>{
        if(isIntersecting && !hasAnimated){
            setHasAnimated(true)
        }
    }, [isIntersecting])
    return {hasAnimated, setHasAnimated, isIntersecting, entry}
}
