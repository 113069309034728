
  
import style from "./style.module.scss"
import { useTranslation } from "react-i18next";
export function Apdp({handleCheck,show=false}){ 
    const { t } = useTranslation("translations")
    const handlePDF = () => {
        const pdfUrl = 'images/modèle-de-lettre-plainte-Validé.pdf'; 
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Modèle-de-lettre-plainte-Validé.pdf'; 
        link.target = '_blank'; 
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);
    }

    const ftt = {
        width: '80%',
        marginTop: '5rem'
    }

    const textD = {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#008080',
        marginTop: '0.5rem'
    }

    const span = {
        fontWeight: 'bold'
    }

    const link = {
        color: '#000',
        cursor: 'pointer',
        fontWeight: 'bold'
    }

    const closeIcon = {
        marginTop: '2rem',
        padding: '0.5rem 3rem',
        cursor: 'pointer',
        position: 'relative', 
        border: 'none',
        borderRadius: '0.5rem',
        fontFamily: "Typold Medium",
        transition: 'all 100ms',
        marginBottom: '1rem',
        background: '#008080',
        color: '#FFFFFF'
    }

    return  (!show?<></>:
    <div className={style.ftMobile}>
        <div style={ftt} className={style.fttMobl} id="ide">
            <div className={style.dg}>
                    
                    <div className={style.centre}>
                        <img src="/images/exclamation.png" className="" alt="Logo" width='80px' height='80px' /><br/><br/>
                        <span style={textD}>INFORMATION</span><br/>
                        
                    </div>
                    <>
                        <span style={span}>{t('warning-msg.text1')}</span><br/><br/>
                        {t('warning-msg.text2')}<br/><br/>
                        {t('warning-msg.text3')}<br/><br/>
                        {t('warning-msg.text4')} <a href="mailto:compliance@izichange.com" rel="noreferrer" target='_blank'  style={link}>compliance@izichange.com</a> {t('warning-msg.text5')}  <strong>{t('warning-msg.text6')}, {t('warning-msg.text7')}, {t('warning-msg.text8')} </strong> {t('warning-msg.text9')} <br/><br/>
                        {t('warning-msg.text10')} <span onClick={handlePDF} style={link}>http://apdp.bj/wp-content/uploads/2018/11/Modèle-de-
                        lettre-plainte-Validé.pdf</span> .<br/><br/>
                        {t('warning-msg.text11')}
                    </>

                    <div className={style["btn-confirm"]}><button onClick={handleCheck} style={closeIcon} className={style.closeIconMobile}>{t('warning-msg.btn')}</button></div>
            </div>
            
            
        </div>
    </div>)

}