import { clx } from "../../../../helpers/components"
import React from "react"
import { SectionHeader } from "../../../shared/TitleHeader"
import style from "./style.module.scss"
import { useTranslation } from "react-i18next"


const ListItem = ({
    src,
    libcompte,
    libelle,
    bgColor,
}) => {
    return (
        <div
            className={clx(style.countryItem, " rounded-full")}
            style={{
                "--item-bg-color": bgColor
            }}
        >
            <img src={src} alt={libelle}  style={{}}/>
            <div  className={clx(style.display, " rounded-full")}>
                <h4>{libelle}</h4>
                <h5>{libcompte}</h5>
            </div>
        </div>
    )
}


export const CryptoArray = () => {
    const { t } = useTranslation("translations")

    const cryptoList = {
        firstRow : [
            {
                src: "/images/btc.webp", 
                libcompte: "BTC", 
                libelle: "Bitcoin"
            }, 
            {
                src: "/images/xrp.webp", 
                libcompte: "XRP", 
                libelle: "Ripple"
            }, 
            {
                src: "/images/shiba_inu.webp", 
                libcompte: "SHIB", 
                libelle: "Shiba Inu"
            },
            // {
            //     src: "/images/ltc.svg",
            //     libcompte: "LTC", 
            //     libelle: "Litecoin"
            // }, 
            {
                src: "/images/trc.webp", 
                libcompte: "TRX", 
                libelle: "Tron"
            }, 
            {
                src: "/images/doge.webp", 
                libcompte: "DOGE", 
                libelle: "Dogecoin"
            },
            {
                src: "/images/bnb.webp", 
                libcompte: "BNB", 
                libelle: "Binance Coin"
            },
        ], 
        mediumRow: [
             
            {
                src: "/images/monero.webp", 
                libcompte: "XMR", 
                libelle: "Monero"
            }, 
            // {
            //     src: "/images/Ada_1.webp", 
            //     libcompte: "ADA", 
            //     libelle: "Cardano"
            // }, 
            {
                src: "/images/tether.webp", 
                libcompte: "USDT", 
                libelle: "Tether"
            }, 
            {
                src: "/images/waves.webp", 
                libcompte: "WAVES", 
                libelle: "Waves"
            }, 
            {
                src: "/images/stellar.webp", 
                libcompte: "XLM", 
                libelle: "Stellar"
            },
            {
                src: "/images/bttc.webp", 
                libcompte: "Btt", 
                libelle: "BitTorrent"
            },
            {
                src: "/images/mana.webp", 
                libcompte: "Mana", 
                libelle: "Mana"
            },
        ],
        lastRow: [
             
            {
                src: "/images/busd.webp", 
                libcompte: "BUSD", 
                libelle: "Busd"
            }, 
            {
                src: "/images/bitcoin_cash.webp", 
                libcompte: "BCH", 
                libelle: "Bitcoin Cash"
            }, 
            {
                src: "/images/eth.webp", 
                libcompte: "ETH", 
                libelle: "Ethereum"
            },
             
            {
                src: "/images/trsut_wallet.webp", 
                libcompte: "TWT", 
                libelle: "Trust Wallet"
            }, 
            {
                src: "/images/polkadot_.webp", 
                libcompte: "DOT", 
                libelle: "Polkadot"
            }, 
            {
                src: "/images/cake.webp", 
                libcompte: "CAKE", 
                libelle: "PancakeSwap"
            }
        ]
    }

    return (
        <div className="">
            <div className={clx("container", style.sectionContainer)}>
                <div >
                    <div>
                    <div className={style.header}>
                        <SectionHeader
                            title={t("home-page.crypto-list.title")}
                        />
                        {/* <Link onClick={() => {
                            setIsOpen(true)
                        }} title={t("home-page.crypto-list.cta-button-title")} /> */}
                    </div>
                        <div className="mt-4rem">
                            <div className={clx(style.leftContainer, "w-fit", style.animate, style.showOnMobile)}>
                                <div className={style.centered}>
                                    <div className={clx(style.containerList, "w-full")}>
                                        {
                                            cryptoList.firstRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libelle={c.libelle}
                                                    libcompte={c.libcompte} 
                                                    bgColor={c.bgColor}
                                                />
                                            ))
                                        }
                                        {
                                            cryptoList.firstRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }

                                    </div>

                                    <div className={clx(style.containerList, "w-fit", "mt-3rem")}>
                                        {
                                            cryptoList.mediumRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libelle={c.libelle}
                                                    libcompte={c.libcompte}
                                                    bgColor={c.bgColor}
                                                />
                                            ))
                                        }
                                        {
                                            cryptoList.mediumRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }

                                    </div>

                                    <div className={clx(style.containerList, "w-fit", "mt-3rem")}>
                                        {
                                            cryptoList.lastRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libelle={c.libelle}
                                                    libcompte={c.libcompte}
                                                    bgColor={c.bgColor}
                                                />
                                            ))
                                        }
                                        {
                                            cryptoList.lastRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }

                                    </div>
                                </div>

                            </div>
                            <div className={clx(style.leftContainer, style.hideOnMobile)}>
                                <div className={style.centered}>
                                    <div className={clx(style.containerList)} >
                                        {
                                            cryptoList.firstRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }


                                    </div>
                                    <div className={clx(style.containerList, "mt-2rem")}>
                                        {
                                            cryptoList.mediumRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }


                                    </div>
                                    <div className={clx(style.containerList, "mt-2rem")}>
                                        {
                                            cryptoList.lastRow.map((c, key) => (
                                                <ListItem
                                                    key={key}
                                                    src={c.src}
                                                    libcompte={c.libcompte}
                                                    libelle={c.libelle}
                                                />
                                            ))
                                        }


                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
