import React from "react"
import { useTranslation } from "react-i18next"
import { Text } from "../../ui/Text"
import "./style.css"

export function AnimatedBannerImage({
    open = true
}) {
    const timer = React.useRef(null)
    React.useEffect(() => {
        const africaMap = document.querySelector(".africa-map-container")
        const globe = document.querySelector(".globe-base-wrapper")
        const notes = document.querySelector(".__animation_transaction_container")
        timer.current = setInterval(() => {

            if (africaMap.classList.contains("scale-fade-show")) {
                africaMap.classList.remove("scale-fade-show", "draw")
                africaMap.classList.add("scale-fade-hide")
            } else {
                africaMap.classList.remove("scale-fade-hide")
                africaMap.classList.add("scale-fade-show", "draw")
            }

            if (globe.classList.contains("scale-fade-show")) {
                globe.classList.remove("scale-fade-show", "draw")
                globe.classList.add("scale-fade-hide")
                notes.classList.remove("move")
            } else {
                globe.classList.remove("scale-fade-hide")
                globe.classList.add("scale-fade-show", "draw")
                notes.classList.add("move")
            }
        }, 15000)
        return () => clearInterval(timer.current)

    }, [open])
    return (
        <div className="__animation_wrapper">
            <div className="animation-container " dangerouslySetInnerHTML={{
                __html: `

            <div class="africa-map-container scale-fade-show draw ">
                <svg class="map" width="801" height="671" viewBox="0 0 801 671" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path class="africa-bg"
                        d="M399 620C555.849 620 683 492.849 683 336C683 179.151 555.849 52 399 52C242.151 52 115 179.151 115 336C115 492.849 242.151 620 399 620Z"
                        fill="#CCE6E6" fill-opacity="0.2" />
                    <path pathLength="1" class="africa-line-border"
                        d="M398.5 668C582.135 668 731 519.135 731 335.5C731 151.865 582.135 3 398.5 3C214.865 3 66 151.865 66 335.5C66 519.135 214.865 668 398.5 668Z"
                        stroke="#CCE6E6" stroke-opacity="0.5" stroke-width="6" stroke-linecap="round" />
                    <path class="africa-line" pathLength="1"
                        d="M164.716 226.896L243.555 321.607L354.761 321.82L375.8 467.824L421.347 608.294L493.481 602.76L593.127 474.422L628.27 358.002L677.284 297.77L608.849 281.807L534.403 157.725L444.004 135.59L350.6 97.706L230.608 118.776L164.716 226.896Z"
                        stroke="#008080" stroke-opacity="0.3" stroke-width="10" stroke-miterlimit="10" />
                    <path class="africa-line" pathLength="1"
                        d="M177 229.487L252.983 320.75L360.161 320.955L380.438 461.644L424.334 597L493.855 591.668L589.892 468.001L623.761 355.82L671 297.78L605.044 282.399L533.295 162.834L446.171 141.505L356.15 105L240.505 125.303L177 229.487Z"
                        stroke="url(#paint0_linear_0_1)" stroke-width="10" stroke-miterlimit="10" />

                    <g class="africa-dots">
                        <path class="africa-dot"
                            d="M250.259 311.604C244.479 308.198 236.619 309.688 232.92 315.222C229.22 320.543 230.839 327.779 236.85 331.185C242.63 334.59 250.491 333.1 254.19 327.566C257.889 322.245 256.271 315.222 250.259 311.604Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M357.304 333.1C364.009 331.823 368.402 325.651 367.015 319.266C365.628 313.094 358.923 309.05 351.987 310.327C345.051 311.604 340.889 317.776 342.277 324.161C343.895 330.546 350.6 334.377 357.304 333.1Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M379.962 480.381C386.667 479.104 391.06 472.932 389.673 466.547C388.285 460.375 381.581 456.331 374.645 457.608C367.94 458.885 363.547 465.057 364.934 471.442C366.321 477.614 373.026 481.658 379.962 480.381Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M426.284 611.009C432.989 609.732 437.382 603.56 435.994 597.175C434.607 591.003 427.902 586.959 420.966 588.236C414.262 589.513 409.869 595.685 411.256 602.07C412.874 608.455 419.579 612.499 426.284 611.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M500.284 608.009C506.989 606.732 511.382 600.56 509.994 594.175C508.607 588.003 501.902 583.959 494.966 585.236C488.262 586.513 483.869 592.685 485.256 599.07C486.874 605.455 493.579 609.286 500.284 608.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M593.284 480.009C599.989 478.732 604.382 472.56 602.994 466.175C601.607 460.003 594.902 455.959 587.966 457.236C581.262 458.513 576.869 464.685 578.256 471.07C579.874 477.455 586.579 481.499 593.284 480.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M629.426 369.92C636.131 368.643 640.524 362.471 639.136 356.086C637.749 349.914 631.044 345.87 624.108 347.147C617.404 348.424 613.011 354.596 614.398 360.981C615.785 367.366 622.49 371.197 629.426 369.92Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M673.284 310.009C679.989 308.732 684.382 302.56 682.994 296.175C681.607 290.003 674.902 285.959 667.966 287.236C661.262 288.513 656.869 294.685 658.256 301.07C659.643 307.242 666.348 311.286 673.284 310.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M611.624 293.3C618.329 292.023 622.721 285.851 621.334 279.466C619.947 273.294 613.242 269.25 606.306 270.527C599.601 271.804 595.209 277.976 596.596 284.361C597.983 290.533 604.688 294.577 611.624 293.3Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M536.946 169.005C543.651 167.728 548.044 161.556 546.656 155.171C545.269 148.999 538.564 144.955 531.628 146.232C524.924 147.509 520.531 153.681 521.918 160.066C523.537 166.451 530.241 170.495 536.946 169.005Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M446.779 146.87C453.483 145.593 457.876 139.421 456.489 133.036C455.102 126.864 448.397 122.82 441.461 124.097C434.756 125.374 430.363 131.546 431.751 137.931C433.138 144.316 439.843 148.36 446.779 146.87Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M357.284 116.009C363.989 114.732 368.382 108.56 366.994 102.175C365.607 96.003 358.902 91.959 351.966 93.236C345.262 94.513 340.869 100.685 342.256 107.07C343.643 113.455 350.348 117.286 357.284 116.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M246.284 136.009C252.989 134.732 257.382 128.56 255.994 122.175C254.607 116.003 247.902 111.959 240.966 113.236C234.262 114.513 229.869 120.685 231.256 127.07C232.643 133.242 239.348 137.286 246.284 136.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M246.284 135.009C252.989 133.732 257.382 127.56 255.994 121.175C254.607 115.003 247.902 110.959 240.966 112.236C234.262 113.513 229.869 119.685 231.256 126.07C232.643 132.242 239.348 136.286 246.284 135.009Z"
                            fill="#008080" />
                        <path class="africa-dot"
                            d="M179.284 238.009C185.989 236.732 190.382 230.56 188.994 224.175C187.607 218.003 180.902 213.959 173.966 215.236C167.262 216.513 162.869 222.685 164.256 229.07C165.874 235.455 172.579 239.499 179.284 238.009Z"
                            fill="#008080" />
                    </g>
                    <g class="start-sphere">
                        <g class="sphere-container">
                            <g class="sphere">
                                <path
                                    d="M127.67 462.421C128.333 469.116 126.739 475.84 123.143 481.526C119.547 487.211 114.154 491.532 107.821 493.802C101.488 496.071 94.5785 496.16 88.1895 494.053C81.8005 491.946 76.2987 487.765 72.558 482.174C72.1168 481.523 71.7098 480.87 71.3259 480.166C70.4969 478.693 69.7953 477.153 69.2286 475.561C68.6536 473.973 68.214 472.339 67.9143 470.677C67.7796 469.901 67.666 469.13 67.5923 468.341C66.9348 461.646 68.5323 454.923 72.1314 449.239C75.7305 443.555 81.1248 439.236 87.4583 436.968C93.7917 434.699 100.701 434.611 107.091 436.718C113.48 438.824 118.983 443.004 122.726 448.594C123.167 449.244 123.574 449.898 123.958 450.602C124.782 452.077 125.483 453.617 126.055 455.206C126.065 455.316 126.13 455.42 126.162 455.535C126.677 457.019 127.076 458.54 127.355 460.085C127.497 460.867 127.602 461.619 127.67 462.421Z"
                                    fill="#DC3F4D" />
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M126.156 455.528C126.423 461.486 124.733 467.368 121.344 472.275C117.956 477.183 113.055 480.847 107.389 482.709C101.723 484.571 95.604 484.529 89.9648 482.588C84.3256 480.647 79.4762 476.915 76.1564 471.96C75.7546 471.361 75.3522 470.756 75.0307 470.129C74.2838 468.798 73.6516 467.407 73.141 465.969C72.6188 464.53 72.2189 463.049 71.9457 461.543C71.8336 460.862 71.7246 460.139 71.6585 459.433C71.277 455.562 71.7277 451.655 72.9805 447.973C75.8086 443.978 79.5651 440.731 83.9266 438.51C88.2881 436.289 93.124 435.161 98.018 435.223C102.912 435.286 107.718 436.537 112.021 438.868C116.324 441.2 119.997 444.542 122.722 448.608C123.163 449.258 123.57 449.912 123.954 450.616C124.778 452.091 125.48 453.63 126.051 455.22C126.088 455.313 126.125 455.421 126.156 455.528Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M123.166 456.07C123.094 455.68 123.003 455.293 122.892 454.913C122.641 454.325 122.256 453.804 121.767 453.392C121.369 453.065 120.894 452.845 120.387 452.754C119.857 452.665 119.313 452.715 118.81 452.901C118.335 453.09 117.916 453.395 117.59 453.788C117.265 454.181 117.043 454.649 116.945 455.15C116.851 455.559 116.818 455.979 116.847 456.398C116.852 456.452 116.857 456.5 116.861 456.548C116.838 456.751 116.808 456.948 116.772 457.145C116.735 457.342 116.707 457.49 116.667 457.659C116.628 457.829 116.622 457.843 116.603 457.935L116.566 458.789C116.576 458.906 116.622 459.019 116.646 459.135C116.697 459.611 116.861 460.067 117.125 460.466C117.39 460.865 117.746 461.195 118.165 461.427C118.583 461.659 119.052 461.788 119.53 461.801C120.009 461.814 120.483 461.711 120.914 461.502L121 461.46C121.463 461.251 121.874 460.941 122.201 460.552C122.403 460.29 122.576 460.007 122.717 459.708C122.879 459.345 123.01 458.97 123.11 458.586C123.248 458.041 123.305 457.478 123.28 456.917C123.268 456.631 123.23 456.348 123.166 456.07Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M121.163 465.049C120.97 464.782 120.712 464.568 120.413 464.428C120.196 464.314 119.952 464.26 119.707 464.272C119.458 464.23 119.203 464.254 118.966 464.342C118.664 464.459 118.397 464.651 118.188 464.899C118.132 464.965 118.085 465.039 118.05 465.119C117.9 465.313 117.804 465.544 117.773 465.788C117.773 465.788 117.777 465.822 117.778 465.836C117.779 465.85 117.784 465.905 117.787 465.939C117.663 466.369 117.67 466.825 117.806 467.251L118.041 467.693C118.041 467.693 118.094 467.743 118.118 467.775C118.142 467.808 118.17 467.881 118.202 467.934C118.355 468.143 118.558 468.31 118.794 468.418C119.037 468.533 119.307 468.579 119.574 468.552C119.725 468.536 119.873 468.499 120.013 468.442L120.048 468.439C120.302 468.403 120.543 468.302 120.747 468.145L121.109 467.807C121.323 467.55 121.466 467.241 121.523 466.911C121.552 466.652 121.567 466.392 121.568 466.132C121.564 466.091 121.56 466.043 121.555 465.995C121.521 465.648 121.384 465.319 121.163 465.049Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M111.684 475.183C111.549 474.978 111.364 474.809 111.147 474.694C110.949 474.545 110.717 474.45 110.471 474.418C110.139 474.359 109.797 474.389 109.479 474.505C109.162 474.621 108.881 474.818 108.665 475.078C108.484 475.277 108.328 475.499 108.201 475.737C108.07 476.027 108.017 476.347 108.047 476.664C108.076 476.982 108.188 477.286 108.371 477.547C108.552 477.799 108.795 478 109.075 478.131C109.367 478.269 109.69 478.325 110.01 478.293C110.285 478.252 110.554 478.18 110.813 478.08C111.121 477.962 111.394 477.768 111.604 477.515C111.814 477.258 111.954 476.952 112.012 476.626L112.034 476.119C112.003 475.781 111.882 475.458 111.684 475.183Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M86.8806 445.549C86.8207 445.118 86.6466 444.712 86.3763 444.371C85.8721 443.676 85.1193 443.201 84.2743 443.046L83.3926 443.011C82.8118 443.064 82.2557 443.271 81.7818 443.611C81.3011 443.871 80.8279 444.136 80.3648 444.435L79.7105 444.835C79.3644 445.03 79.0618 445.294 78.8215 445.61C78.6386 445.862 78.4714 446.125 78.321 446.397C78.1845 446.666 78.0902 446.955 78.0414 447.253L78.0135 448.065C78.0288 448.472 78.1567 448.867 78.383 449.206C78.4696 449.46 78.6246 449.685 78.8311 449.856C79.0751 450.164 79.3926 450.405 79.7544 450.558C80.0311 450.676 80.3199 450.762 80.6154 450.817C80.9362 450.848 81.2584 450.862 81.5807 450.858C81.9876 450.837 82.384 450.722 82.7389 450.521L83.4731 450.162C84.2073 449.803 84.9245 449.411 85.6418 449.019C86.1743 448.645 86.5801 448.118 86.8048 447.507C87.0295 446.897 87.0626 446.232 86.8994 445.602L86.8806 445.549Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M78.9301 451.839C78.8823 451.618 78.7824 451.411 78.6386 451.237C78.5185 451.064 78.3711 450.912 78.2023 450.786L77.7539 450.503C77.5137 450.375 77.2422 450.316 76.9704 450.334C76.7865 450.296 76.5954 450.314 76.4218 450.386C76.1513 450.418 75.8954 450.526 75.6832 450.697C75.5128 450.818 75.3587 450.96 75.2248 451.12C74.9833 451.417 74.8212 451.77 74.7535 452.146L74.7599 452.215C74.7116 452.443 74.7009 452.678 74.728 452.909C74.75 453.305 74.8788 453.688 75.1007 454.017C75.3226 454.346 75.6294 454.608 75.9885 454.777C76.5261 454.994 77.1227 455.017 77.6752 454.84C78.027 454.709 78.3379 454.486 78.5763 454.196C78.8301 453.901 78.9954 453.54 79.0532 453.155C79.0549 453.132 79.0527 453.109 79.0467 453.087C79.0764 452.877 79.0867 452.666 79.0776 452.454C79.042 452.246 78.9927 452.041 78.9301 451.839Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M84.6258 453.878C84.5125 453.624 84.3372 453.403 84.116 453.234C83.8204 452.989 83.465 452.826 83.086 452.763C82.7071 452.7 82.3182 452.739 81.9591 452.875L81.8731 452.918L81.3856 453.178C81.1561 453.331 80.9687 453.538 80.8404 453.782C80.7241 453.925 80.6449 454.094 80.6097 454.274C80.5025 454.538 80.4672 454.825 80.5077 455.107C80.5256 455.299 80.5909 455.479 80.6363 455.669C80.6816 455.858 80.8117 455.991 80.8963 456.156C81.047 456.388 81.2553 456.575 81.5006 456.702C81.7124 456.862 81.9596 456.97 82.2215 457.015L82.3106 457.006C82.6888 457.074 83.0781 457.04 83.4389 456.908C83.7996 456.776 84.1188 456.55 84.3637 456.254L84.6587 455.784C84.8207 455.435 84.8873 455.049 84.8516 454.666C84.8404 454.389 84.7629 454.119 84.6258 453.878Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M103.856 440.254C103.712 439.878 103.471 439.547 103.158 439.295C103.141 439.275 103.121 439.258 103.098 439.246C102.785 438.983 102.408 438.809 102.006 438.739C101.777 438.696 101.543 438.69 101.313 438.721L101.045 438.746C100.679 438.779 100.328 438.91 100.029 439.124C99.808 439.282 99.62 439.482 99.476 439.713C99.332 439.944 99.236 440.201 99.191 440.469C99.147 440.737 99.156 441.011 99.218 441.276C99.28 441.541 99.393 441.791 99.552 442.012L99.699 442.254L99.704 442.309L100.119 442.747C100.421 443.001 100.785 443.17 101.174 443.236C101.412 443.318 101.666 443.337 101.913 443.292C102.311 443.256 102.692 443.113 103.015 442.878C103.47 442.516 103.789 442.011 103.919 441.444C104 441.049 103.979 440.639 103.856 440.254Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M78.2669 469.067C78.256 468.951 78.1772 468.847 78.1326 468.741C78.051 468.534 77.9757 468.32 77.9141 468.104C77.8524 467.889 77.7509 467.691 77.6824 467.476C77.5084 466.924 77.1792 466.434 76.7342 466.064C76.5283 465.902 76.3043 465.765 76.0667 465.656C75.8342 465.566 75.5961 465.491 75.3541 465.432C74.8708 465.345 74.3731 465.392 73.9142 465.566C73.6843 465.67 73.4644 465.795 73.2573 465.939C72.8462 466.235 72.5188 466.633 72.307 467.093C72.0854 467.594 71.9939 468.142 72.041 468.688L72.0545 468.832C72.0529 469.062 72.0744 469.292 72.1186 469.518C72.2252 469.992 72.3255 470.473 72.4795 470.936C72.5777 471.291 72.7403 471.625 72.9589 471.922C72.9887 471.961 73.0221 471.998 73.059 472.03C73.3867 472.466 73.813 472.817 74.3029 473.055C74.7789 473.277 75.3057 473.366 75.828 473.314C76.6022 473.209 77.3143 472.834 77.8385 472.254C78.1659 471.859 78.386 471.386 78.4779 470.881C78.5673 470.268 78.4945 469.643 78.2669 469.067Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M75.833 475.499L75.7929 475.44L75.7535 475.389C75.5618 475.12 75.3034 474.906 75.0037 474.767C74.8583 474.683 74.6949 474.634 74.527 474.625L74.479 474.629C74.3177 474.609 74.1552 474.601 73.9927 474.606C73.8319 474.613 73.6737 474.649 73.5253 474.712C73.2416 474.82 72.9913 475 72.7997 475.236C72.6006 475.477 72.4678 475.766 72.4146 476.074L72.4184 476.115C72.3596 476.449 72.3917 476.793 72.5114 477.11L72.7375 477.531C72.8451 477.646 72.9532 477.767 73.0676 477.881L73.1064 477.925L73.152 477.97C73.2089 478.03 73.2796 478.076 73.3581 478.103C73.549 478.248 73.7747 478.342 74.0128 478.373C74.0128 478.373 74.3539 478.397 74.5191 478.388L74.5877 478.382L75.0345 478.278C75.2456 478.21 75.4347 478.088 75.5823 477.922C75.7039 477.837 75.8005 477.72 75.862 477.585C75.9971 477.409 76.0827 477.201 76.1098 476.981L76.1021 476.898C76.1363 476.732 76.146 476.561 76.1308 476.391C76.1108 476.073 76.0082 475.765 75.833 475.499Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M94.1802 489.887L93.8195 489.506C93.6344 489.332 93.42 489.193 93.1862 489.095L92.7151 488.938C92.4849 488.847 92.2468 488.776 92.0038 488.728L91.7437 488.683L91.5185 488.642L91.4088 488.652C91.1383 488.608 90.8617 488.62 90.5959 488.687C90.293 488.719 90.0034 488.828 89.7547 489.004C89.5059 489.179 89.3064 489.416 89.175 489.691C89.0471 489.971 88.9948 490.28 89.0235 490.587C89.0522 490.894 89.1608 491.188 89.3384 491.439L89.4235 491.535C89.4917 491.653 89.5788 491.76 89.6811 491.85C89.8356 492.048 90.0382 492.203 90.2696 492.3L90.3283 492.336L90.5269 492.463C90.6644 492.559 90.8088 492.645 90.959 492.72L91.4196 492.912C91.6431 493.018 91.8833 493.084 92.1296 493.108L92.6921 493.132C93.0506 493.097 93.394 492.97 93.6881 492.762L94.0694 492.401C94.1743 492.261 94.2691 492.113 94.3532 491.96C94.5045 491.63 94.5664 491.266 94.5327 490.905C94.4989 490.544 94.3707 490.198 94.1609 489.903L94.1802 489.887Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M99.3 491.117C99.213 490.863 99.058 490.638 98.852 490.467L98.529 490.262C98.288 490.147 98.021 490.1 97.755 490.127L97.646 490.137L97.611 490.14L97.226 490.232L96.882 490.409L96.609 490.67L96.553 490.806C96.469 490.901 96.4 491.008 96.347 491.123L96.247 491.451C96.219 491.628 96.236 491.809 96.296 491.979C96.32 492.154 96.39 492.319 96.5 492.458C96.5 492.458 96.716 492.693 96.738 492.712L96.791 492.755L97.107 492.961L97.477 493.072L97.873 493.09L98.017 493.077C98.218 493.067 98.413 493.004 98.581 492.892C98.762 492.821 98.919 492.698 99.032 492.539C99.163 492.395 99.253 492.22 99.296 492.03C99.374 491.847 99.4 491.646 99.373 491.449L99.3 491.117Z"
                                        fill="white" />
                                </g>
                                <g style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M119.135 480.849C119.071 480.665 118.963 480.499 118.82 480.367C118.7 480.215 118.544 480.096 118.366 480.022C117.997 479.869 117.586 479.853 117.207 479.978C116.953 480.065 116.728 480.22 116.557 480.426L116.309 480.733L116.246 480.801C116.246 480.801 116.246 480.801 116.215 480.839L116.106 481.008C115.992 481.159 115.921 481.338 115.899 481.525C115.903 481.573 115.874 481.631 115.859 481.688C115.817 481.92 115.838 482.159 115.919 482.38C116.001 482.601 116.14 482.796 116.322 482.945C116.499 483.095 116.714 483.195 116.944 483.233C117.174 483.276 117.411 483.254 117.629 483.169L117.808 483.152C117.981 483.092 118.14 482.995 118.272 482.867L118.56 482.618C118.771 482.47 118.938 482.268 119.045 482.034C119.082 481.913 119.126 481.791 119.162 481.663C119.226 481.394 119.217 481.113 119.135 480.849Z"
                                        fill="white" />
                                </g>
                                <path
                                    d="M163.851 435.586C152.078 438.983 137.997 443.439 122.723 448.595C123.157 449.246 123.571 449.899 123.955 450.603C160.834 438.164 187.017 431.097 192.341 431.45C188.457 435.105 163.708 446.265 127.352 460.086C118.67 463.395 109.31 466.85 99.432 470.394C89.5544 473.939 80.1281 477.206 71.2958 480.169C34.4171 492.608 8.22749 499.676 2.90323 499.323C6.78759 495.668 31.5291 484.502 67.898 470.679C67.7564 469.904 67.6491 469.125 67.5698 468.351C52.5209 474.067 38.7834 479.605 27.5579 484.417C-0.984581 496.726 -0.18961 498.941 0.28012 500.267C0.74986 501.593 1.54355 503.794 31.4134 495.178C43.18 491.789 57.2806 487.324 72.5554 482.175C81.4393 479.173 90.7379 475.945 100.201 472.55C109.663 469.154 118.932 465.75 127.674 462.422C142.743 456.697 156.457 451.203 167.706 446.348C196.255 434.03 195.461 431.822 194.991 430.503C194.522 429.185 193.72 426.963 163.851 435.586Z"
                                    fill="#466EB6" />
                            </g>
                        </g>
                    </g>

                    <g class="end-sphere">
                        <g class="sphere-container">
                            <g class="sphere">
                                <path class="last-sphere"
                                    d="M732.67 201.421C733.333 208.116 731.739 214.84 728.143 220.526C724.547 226.211 719.154 230.532 712.821 232.802C706.488 235.071 699.579 235.16 693.19 233.053C686.801 230.946 681.299 226.765 677.558 221.174C677.117 220.523 676.71 219.87 676.326 219.166C675.497 217.693 674.795 216.153 674.229 214.561C673.654 212.973 673.214 211.339 672.914 209.677C672.78 208.901 672.666 208.13 672.592 207.341C671.935 200.646 673.532 193.923 677.131 188.239C680.73 182.555 686.125 178.236 692.458 175.968C698.792 173.699 705.701 173.611 712.091 175.718C718.48 177.824 723.983 182.004 727.726 187.594C728.167 188.244 728.574 188.898 728.958 189.602C729.782 191.077 730.483 192.617 731.055 194.206C731.065 194.316 731.13 194.42 731.162 194.535C731.677 196.019 732.076 197.54 732.355 199.085C732.497 199.867 732.602 200.619 732.67 201.421Z"
                                    fill="#3257A8" />
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M731.156 194.528C731.423 200.486 729.733 206.368 726.344 211.275C722.956 216.183 718.055 219.847 712.389 221.709C706.723 223.571 700.604 223.529 694.965 221.588C689.326 219.647 684.476 215.915 681.156 210.96C680.755 210.361 680.352 209.756 680.031 209.129C679.284 207.798 678.652 206.407 678.141 204.969C677.619 203.53 677.219 202.049 676.946 200.543C676.834 199.862 676.725 199.139 676.659 198.433C676.277 194.562 676.728 190.655 677.981 186.973C680.809 182.978 684.565 179.731 688.927 177.51C693.288 175.289 698.124 174.161 703.018 174.223C707.912 174.286 712.718 175.537 717.021 177.868C721.324 180.2 724.997 183.542 727.722 187.608C728.163 188.258 728.57 188.912 728.954 189.616C729.778 191.091 730.48 192.63 731.051 194.22C731.088 194.313 731.125 194.421 731.156 194.528Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M728.166 195.07C728.094 194.68 728.003 194.293 727.892 193.913C727.641 193.325 727.256 192.804 726.767 192.392C726.369 192.065 725.894 191.845 725.387 191.754C724.857 191.665 724.313 191.715 723.81 191.901C723.335 192.09 722.916 192.395 722.59 192.788C722.265 193.181 722.043 193.649 721.945 194.15C721.851 194.559 721.818 194.979 721.847 195.398C721.852 195.452 721.857 195.5 721.861 195.548C721.838 195.751 721.808 195.948 721.772 196.145C721.735 196.342 721.707 196.49 721.667 196.659C721.628 196.829 721.622 196.843 721.603 196.935L721.566 197.789C721.576 197.906 721.622 198.019 721.646 198.135C721.697 198.611 721.861 199.067 722.125 199.466C722.39 199.865 722.746 200.195 723.165 200.427C723.583 200.659 724.052 200.788 724.53 200.801C725.009 200.814 725.483 200.711 725.914 200.502L726 200.46C726.463 200.251 726.874 199.941 727.201 199.552C727.403 199.29 727.576 199.007 727.717 198.708C727.879 198.345 728.01 197.97 728.11 197.586C728.248 197.041 728.305 196.478 728.28 195.917C728.268 195.631 728.23 195.348 728.166 195.07Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M726.163 204.049C725.97 203.782 725.712 203.568 725.413 203.428C725.196 203.314 724.952 203.26 724.707 203.272C724.458 203.23 724.203 203.254 723.966 203.342C723.664 203.459 723.397 203.651 723.188 203.899C723.132 203.965 723.085 204.039 723.05 204.119C722.9 204.313 722.804 204.544 722.773 204.788C722.773 204.788 722.777 204.822 722.778 204.836C722.779 204.85 722.784 204.905 722.787 204.939C722.663 205.369 722.67 205.825 722.806 206.251L723.041 206.693C723.041 206.693 723.094 206.743 723.118 206.775C723.142 206.808 723.17 206.881 723.202 206.934C723.355 207.143 723.558 207.31 723.794 207.418C724.037 207.533 724.307 207.579 724.574 207.552C724.725 207.536 724.873 207.499 725.013 207.442L725.048 207.439C725.302 207.403 725.543 207.302 725.747 207.145L726.109 206.807C726.323 206.55 726.466 206.241 726.523 205.911C726.552 205.652 726.567 205.392 726.568 205.132C726.564 205.091 726.56 205.043 726.555 204.995C726.521 204.648 726.384 204.319 726.163 204.049Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M716.684 214.183C716.549 213.978 716.364 213.809 716.147 213.694C715.949 213.545 715.717 213.45 715.471 213.418C715.139 213.359 714.797 213.389 714.479 213.505C714.162 213.621 713.881 213.818 713.665 214.078C713.484 214.277 713.328 214.499 713.201 214.737C713.07 215.027 713.017 215.347 713.047 215.664C713.076 215.982 713.188 216.286 713.371 216.547C713.552 216.799 713.795 217 714.075 217.131C714.367 217.269 714.69 217.325 715.01 217.293C715.285 217.252 715.554 217.18 715.813 217.08C716.121 216.962 716.394 216.768 716.604 216.515C716.814 216.258 716.954 215.952 717.012 215.626L717.034 215.119C717.003 214.781 716.882 214.458 716.684 214.183Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M691.881 184.549C691.821 184.118 691.647 183.712 691.376 183.371C690.872 182.676 690.119 182.201 689.274 182.046L688.393 182.011C687.812 182.064 687.256 182.271 686.782 182.611C686.301 182.871 685.828 183.136 685.365 183.435L684.711 183.835C684.364 184.03 684.062 184.294 683.822 184.61C683.639 184.862 683.471 185.125 683.321 185.397C683.184 185.666 683.09 185.955 683.041 186.253L683.014 187.065C683.029 187.472 683.157 187.867 683.383 188.206C683.47 188.46 683.625 188.685 683.831 188.856C684.075 189.164 684.393 189.405 684.754 189.558C685.031 189.676 685.32 189.762 685.615 189.817C685.936 189.848 686.258 189.862 686.581 189.858C686.988 189.837 687.384 189.722 687.739 189.521L688.473 189.162C689.207 188.803 689.925 188.411 690.642 188.019C691.174 187.645 691.58 187.118 691.805 186.507C692.03 185.897 692.063 185.232 691.899 184.602L691.881 184.549Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M683.93 190.839C683.882 190.618 683.782 190.411 683.639 190.237C683.518 190.064 683.371 189.912 683.202 189.786L682.754 189.503C682.514 189.375 682.242 189.316 681.97 189.334C681.787 189.296 681.595 189.314 681.422 189.386C681.151 189.418 680.895 189.526 680.683 189.697C680.513 189.818 680.359 189.96 680.225 190.12C679.983 190.417 679.821 190.77 679.754 191.146L679.76 191.215C679.712 191.443 679.701 191.678 679.728 191.909C679.75 192.305 679.879 192.688 680.101 193.017C680.323 193.346 680.629 193.608 680.988 193.777C681.526 193.994 682.123 194.017 682.675 193.84C683.027 193.709 683.338 193.486 683.576 193.196C683.83 192.901 683.995 192.54 684.053 192.155C684.055 192.132 684.053 192.109 684.047 192.087C684.076 191.877 684.087 191.666 684.078 191.454C684.042 191.246 683.993 191.041 683.93 190.839Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M689.626 192.878C689.512 192.624 689.337 192.403 689.116 192.234C688.82 191.989 688.465 191.826 688.086 191.763C687.707 191.7 687.318 191.739 686.959 191.875L686.873 191.918L686.386 192.178C686.156 192.331 685.969 192.538 685.84 192.782C685.724 192.925 685.645 193.094 685.61 193.274C685.502 193.538 685.467 193.825 685.508 194.107C685.526 194.299 685.591 194.479 685.636 194.669C685.682 194.858 685.812 194.991 685.896 195.156C686.047 195.388 686.255 195.575 686.501 195.702C686.712 195.862 686.96 195.97 687.221 196.015L687.311 196.006C687.689 196.074 688.078 196.04 688.439 195.908C688.8 195.776 689.119 195.55 689.364 195.254L689.659 194.784C689.821 194.435 689.887 194.049 689.852 193.666C689.84 193.389 689.763 193.119 689.626 192.878Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M708.856 179.254C708.712 178.878 708.471 178.547 708.158 178.295C708.141 178.275 708.121 178.258 708.098 178.246C707.785 177.983 707.408 177.809 707.006 177.739C706.777 177.696 706.543 177.69 706.313 177.721L706.045 177.746C705.679 177.779 705.328 177.91 705.029 178.124C704.808 178.282 704.62 178.482 704.476 178.713C704.332 178.944 704.236 179.201 704.191 179.469C704.147 179.737 704.156 180.011 704.218 180.276C704.28 180.541 704.393 180.791 704.552 181.012L704.699 181.254L704.704 181.309L705.119 181.747C705.421 182.001 705.785 182.17 706.174 182.236C706.412 182.318 706.666 182.337 706.913 182.292C707.311 182.256 707.692 182.113 708.015 181.878C708.47 181.516 708.789 181.011 708.919 180.444C709 180.049 708.979 179.639 708.856 179.254Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M683.267 208.067C683.256 207.951 683.177 207.847 683.133 207.741C683.051 207.534 682.976 207.32 682.914 207.104C682.852 206.889 682.751 206.691 682.682 206.476C682.508 205.924 682.179 205.434 681.734 205.064C681.528 204.902 681.304 204.765 681.067 204.656C680.834 204.566 680.596 204.491 680.354 204.432C679.871 204.345 679.373 204.392 678.914 204.566C678.684 204.67 678.464 204.795 678.257 204.939C677.846 205.235 677.519 205.633 677.307 206.093C677.085 206.594 676.994 207.142 677.041 207.688L677.055 207.832C677.053 208.062 677.074 208.292 677.119 208.518C677.225 208.992 677.326 209.473 677.479 209.936C677.578 210.291 677.74 210.625 677.959 210.922C677.989 210.961 678.022 210.998 678.059 211.03C678.387 211.466 678.813 211.817 679.303 212.055C679.779 212.277 680.306 212.366 680.828 212.314C681.602 212.209 682.314 211.834 682.839 211.254C683.166 210.859 683.386 210.386 683.478 209.881C683.567 209.268 683.495 208.643 683.267 208.067Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M680.833 214.499L680.793 214.44L680.754 214.389C680.562 214.12 680.303 213.906 680.004 213.767C679.858 213.683 679.695 213.634 679.527 213.625L679.479 213.629C679.318 213.609 679.155 213.601 678.993 213.606C678.832 213.613 678.674 213.649 678.525 213.712C678.242 213.82 677.991 214 677.8 214.236C677.601 214.477 677.468 214.766 677.415 215.074L677.418 215.115C677.36 215.449 677.392 215.793 677.511 216.11L677.738 216.531C677.845 216.646 677.953 216.767 678.068 216.881L678.106 216.925L678.152 216.97C678.209 217.03 678.28 217.076 678.358 217.103C678.549 217.248 678.775 217.342 679.013 217.373C679.013 217.373 679.354 217.397 679.519 217.388L679.588 217.382L680.034 217.278C680.246 217.21 680.435 217.088 680.582 216.922C680.704 216.837 680.8 216.72 680.862 216.585C680.997 216.409 681.083 216.201 681.11 215.981L681.102 215.898C681.136 215.732 681.146 215.561 681.131 215.391C681.111 215.073 681.008 214.765 680.833 214.499Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M699.18 228.887L698.82 228.506C698.634 228.332 698.42 228.193 698.186 228.095L697.715 227.938C697.485 227.847 697.247 227.776 697.004 227.728L696.744 227.683L696.518 227.642L696.409 227.652C696.138 227.608 695.862 227.62 695.596 227.687C695.293 227.719 695.003 227.828 694.755 228.004C694.506 228.179 694.306 228.416 694.175 228.691C694.047 228.971 693.995 229.28 694.024 229.587C694.052 229.894 694.161 230.188 694.338 230.439L694.423 230.535C694.492 230.653 694.579 230.76 694.681 230.85C694.836 231.048 695.038 231.203 695.27 231.3L695.328 231.336L695.527 231.463C695.664 231.559 695.809 231.645 695.959 231.72L696.42 231.912C696.643 232.018 696.883 232.084 697.13 232.108L697.692 232.132C698.051 232.097 698.394 231.97 698.688 231.762L699.069 231.401C699.174 231.261 699.269 231.113 699.353 230.96C699.505 230.63 699.566 230.266 699.533 229.905C699.499 229.544 699.371 229.198 699.161 228.903L699.18 228.887Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M704.3 230.117C704.213 229.863 704.058 229.638 703.852 229.467L703.529 229.262C703.288 229.147 703.021 229.1 702.755 229.127L702.646 229.137L702.611 229.14L702.226 229.232L701.882 229.409L701.609 229.67L701.553 229.806C701.469 229.901 701.4 230.008 701.347 230.123L701.247 230.451C701.219 230.628 701.236 230.809 701.296 230.979C701.32 231.154 701.39 231.319 701.5 231.458C701.5 231.458 701.716 231.693 701.738 231.712L701.791 231.755L702.107 231.961L702.477 232.072L702.873 232.09L703.017 232.077C703.218 232.067 703.413 232.004 703.581 231.892C703.762 231.821 703.919 231.698 704.032 231.539C704.163 231.395 704.253 231.22 704.296 231.03C704.374 230.847 704.4 230.646 704.373 230.449L704.3 230.117Z"
                                        fill="white" />
                                </g>
                                <g class="last-sphere" style="mix-blend-mode:overlay" opacity="0.25">
                                    <path
                                        d="M724.135 219.849C724.071 219.665 723.963 219.499 723.82 219.367C723.7 219.215 723.544 219.096 723.366 219.022C722.997 218.869 722.586 218.853 722.207 218.978C721.953 219.065 721.728 219.22 721.557 219.426L721.309 219.733L721.246 219.801C721.246 219.801 721.246 219.801 721.215 219.839L721.106 220.008C720.992 220.159 720.921 220.338 720.899 220.525C720.903 220.573 720.874 220.631 720.859 220.688C720.817 220.92 720.838 221.159 720.919 221.38C721.001 221.601 721.14 221.796 721.322 221.945C721.499 222.095 721.714 222.195 721.944 222.233C722.174 222.276 722.411 222.254 722.629 222.169L722.808 222.152C722.981 222.092 723.14 221.995 723.272 221.867L723.56 221.618C723.771 221.47 723.938 221.268 724.045 221.034C724.082 220.913 724.126 220.791 724.162 220.663C724.226 220.394 724.217 220.113 724.135 219.849Z"
                                        fill="white" />
                                </g>
                                <path class="last-sphere"
                                    d="M768.851 174.586C757.078 177.983 742.997 182.439 727.723 187.595C728.157 188.246 728.571 188.899 728.955 189.603C765.834 177.164 792.017 170.097 797.341 170.45C793.457 174.105 768.708 185.265 732.352 199.086C723.67 202.395 714.31 205.85 704.432 209.394C694.554 212.939 685.128 216.206 676.296 219.169C639.417 231.608 613.227 238.676 607.903 238.323C611.788 234.668 636.529 223.502 672.898 209.679C672.756 208.904 672.649 208.125 672.57 207.351C657.521 213.067 643.783 218.605 632.558 223.417C604.015 235.726 604.81 237.941 605.28 239.267C605.75 240.593 606.544 242.794 636.413 234.178C648.18 230.789 662.281 226.324 677.555 221.175C686.439 218.173 695.738 214.945 705.201 211.55C714.663 208.154 723.932 204.75 732.674 201.422C747.743 195.697 761.457 190.203 772.706 185.348C801.255 173.03 800.461 170.822 799.991 169.503C799.522 168.185 798.72 165.963 768.851 174.586Z"
                                    fill="#DC3F4D" />
                            </g>
                        </g>
                    </g>

                    <defs>
                        <linearGradient id="paint0_linear_0_1" x1="424" y1="105" x2="424" y2="597"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009D9D" />
                            <stop offset="1" stop-color="#005A5A" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="globe-base-wrapper">

                <div class="globe-container">
                    <div class="globe-wrapper">
                        <div class="img">
                            <img src="./images/globe.png" alt="globe" />
                            <img src="./images/globe.png" alt="globe" />
                        </div>
                    </div>
                    <svg class="globe-border" width="1179" height="1152" viewBox="0 0 1179 1152" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path pathLength="1"
                            d="M334.326 882.433C286.784 840.832 249.565 788.747 225.608 730.292C201.651 671.837 191.612 608.613 196.284 545.613C200.956 482.612 220.21 421.56 252.528 367.279C284.845 312.997 329.339 266.972 382.497 232.838C435.656 198.704 496.022 177.396 558.828 170.597C621.635 163.798 685.162 171.694 744.393 193.661C803.625 215.629 856.938 251.066 900.123 297.174C943.307 343.282 975.181 398.799 993.227 459.34"
                            stroke="black"></path>
                        <path pathLength="1"
                            d="M1004.79 648.64C1013.37 664.833 1015.93 679.803 1012.64 693.203C1009.34 706.602 1000.18 718.513 985.151 728.551C955.073 748.642 902.782 760.368 833.826 762.389C764.893 764.409 681.994 756.653 594.053 739.949C506.113 723.245 416.474 698.228 334.785 667.593C253.093 636.957 182.462 601.867 130.485 566.107C104.497 548.227 83.6757 530.526 68.4327 513.478C53.1864 496.426 43.5431 480.052 39.8701 464.829C36.2015 449.624 38.5536 435.84 46.684 423.775C54.8252 411.694 68.7879 401.296 88.4086 392.947C127.656 376.246 187.89 368.446 262.665 370.429"
                            stroke="#050505"></path>
                        <path pathLength="1"
                            d="M744.537 203.285C799.611 142.519 849.675 104 888.524 92.3818C907.933 86.5773 924.054 87.6324 936.552 95.1925C949.055 102.756 958.037 116.891 963.03 137.469C973.02 178.646 966.532 243.539 944.284 324.159C922.042 404.757 885.047 497.461 837.876 590.785C790.707 684.108 735.44 773.941 678.925 849.156C622.405 924.378 567.139 981.646 519.979 1013.9C496.398 1030.02 475.396 1039.52 457.537 1042.34C439.696 1045.16 425.016 1041.31 413.989 1030.79C402.942 1020.24 395.739 1003.16 392.55 980.179C389.362 957.207 390.194 928.403 395.163 894.489"
                            stroke="#050505"></path>
                        <path pathLength="1"
                            d="M838.407 907.01C820.956 952.118 791.357 980.223 752.688 988.55C713.993 996.883 667.503 985.135 617.98 954.392C568.464 923.652 517.759 875.063 471.093 813.609C424.428 752.157 383.499 680.076 352.52 604.785C321.541 529.492 301.636 453.721 294.846 385.27C288.056 316.813 294.634 258.189 313.854 215.404C333.065 172.639 364.201 147.259 404.047 141.774C443.925 136.285 491.182 150.907 540.967 184.251"
                            stroke="#050505">

                        </path>
                    </svg>
                </div>
            </div>
            `
            }}>

            </div>

            <div className="__animation_transaction_container">
                <TransactionItem
                    amount={"1130574.07"}
                    gatewayImg={"/images/Mtn.png"}
                    personImg={"/images/p_1.png"}
                />
                <TransactionItem
                    amount={"0.0513674"}
                    gatewayImg={"/images/bitcoin.png"}
                    personImg={"/images/p_2.png"}
                />
                <TransactionItem
                    amount={"0.51135747"}
                    gatewayImg={"/images/Ether.png"}
                    personImg={"/images/p_3.png"}
                />
                <TransactionItem
                    amount={"413974.67"}
                    gatewayImg={"/images/Moov.png"}
                    personImg={"/images/p_4.png"}
                />
                <div
                    className="__animation_transaction_arrow_left"
                    dangerouslySetInnerHTML={{
                        __html: `
                        <svg pathLength="1"  width="116" height="110" viewBox="0 0 116 110" fill="none" xmlns="http://www.w3.org/2000/svg">
<path pathLength="1" d="M89.3547 20.6016C78.8113 22.1384 68.1617 23.1728 58.4621 32.4789C50.4928 40.1249 43.8859 53.13 52.9823 54.9562C64.6435 57.2973 69.5967 43.9722 64.0525 40.1252C58.65 36.3765 44.868 41.2416 39.6346 50.1249C34.0249 59.647 33.4641 76.65 39.8633 92.2933" stroke="#008080" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"/>
<path pathLength="1" d="M87.7183 23.8947L90.7359 20.1792L87.1219 18.1728" stroke="#008080" stroke-width="2" stroke-linecap="round"/>
</svg>

                        
                        `
                    }}
                ></div>
                <div
                    className="__animation_transaction_arrow_right"
                    dangerouslySetInnerHTML={{
                        __html: `
                        <svg pathLength="1" width="105" height="98" viewBox="0 0 105 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path pathLength="1" d="M20.9965 78.9201C30.8147 78.0927 40.7028 77.735 50.1778 69.6889C57.9625 63.0782 64.7901 51.4443 56.4961 49.2486C45.8636 46.4338 40.5444 58.4558 45.4463 62.3176C50.2229 66.0807 63.2171 62.3628 68.5458 54.4565C74.2577 45.9817 75.7287 30.3186 70.699 15.5203" stroke="#008080" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"/>
                        <path pathLength="1" d="M22.6914 75.9722L19.6977 79.2326L22.921 81.2872" stroke="#008080" stroke-width="2" stroke-linecap="round"/>
                        </svg>


                        
                        `
                    }}
                ></div>
            </div>
        </div>
    )
}

export const TransactionItem = ({
    amount,
    gatewayImg,
    personImg
}) => {
    const {t}=useTranslation("translations")
    return (
        <div className="flex __animation_transaction">
            <div>
                <img className={"__animation_transaction_user_image"} src={personImg} alt="Transaction User" style={{width: '90%', height:'90%'}}/>
            </div>
            <div className=" __animation_transaction_details_container">
                <div className="flex align-items-center">
                    <Text variant="h6">{amount}</Text>
                    <img src={gatewayImg} className="__animation_transaction_details_fiat_img" alt="Fiat Or Crypto" />
                </div>
                <div className="flex  items-center __animation_transaction_state_container">
                    <Text variant="subtitle">{t("home-page.banner.cardDescription")}</Text>
                    <span dangerouslySetInnerHTML={{
                        __html: `
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12.152C9.31368 12.152 12 9.46569 12 6.15201C12 2.8383 9.31368 0.152008 6 0.152008C2.68629 0.152008 0 2.8383 0 6.15201C0 9.46569 2.68629 12.152 6 12.152ZM8.1213 4.03069C8.35566 3.79637 8.73552 3.79637 8.96988 4.03069C9.20418 4.265 9.20418 4.6449 8.96988 4.87922L5.57796 8.27109L5.57574 8.27331C5.51514 8.33391 5.44482 8.37885 5.36976 8.40813C5.26344 8.44953 5.1477 8.45955 5.03688 8.43807C4.92342 8.41611 4.81506 8.36121 4.72721 8.27331L4.72493 8.27103L3.03017 6.57627C2.79585 6.34197 2.79585 5.96205 3.03017 5.72775C3.26448 5.49345 3.64438 5.49345 3.87869 5.72775L5.15148 7.00053L8.1213 4.03069Z" fill="#008080"/>
                            </svg>
                        
                        `
                    }}>

                    </span>
                </div>
            </div>
        </div>
    )
}