import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import React from 'react';
import { useMemo } from 'react';

/*
<IntlTelInput
    customContainer={clx("intl-tel-input", style.formContainer, className)}
    onPhoneNumberChange={(isValid, value, country, fullNumber, ext) => {
        if (onChange instanceof Function) onChange({ isValid, value, country, fullNumber, ext })
    }}
    onPhoneNumberBlur={(isValid, ...props) => {

        if (onPhoneNumberBlur instanceof Function) {
            onPhoneNumberBlur(isValid, ...props);
        }

    }}

    value={value}
    {...props}
/>
*/

export const IntelInput = React.forwardRef(({
    value = "",
    onChange,
    intlTelInputProps = {},
    getIntelInstance,
    onPhoneNumberChange,
    onBlur,
    onPhoneNumberBlur,
    ...props
}, ref) => {
    const elRef = React.useRef()
    const itiRef = React.useRef(null)
    const currentElRef = useMemo(() => ref ?? elRef)
    React.useEffect(() => {
        if (currentElRef.current && !itiRef.current) {
            itiRef.current = intlTelInput(currentElRef.current, {
                utilsScript: "/js/intl-tel-input/utilScript.js",
                initialCountry: "auto",
                geoIpLookup: function (success, failure) {
                    fetch("https://ipinfo.io?token=6009aa2d5e722a").then(r=>r.json())
                    .then(resp=>{
                        var countryCode = (resp && resp.country) ? resp.country : "us";
                        success(countryCode);
                    })
                },
                ...intlTelInputProps
            })
            if (getIntelInstance instanceof Function) {
                getIntelInstance(itiRef.current)
            }
           
        }
    }, [currentElRef.current])



    const handleChange = (e) => {
        if (itiRef.current) {
            const country = itiRef.current.getSelectedCountryData()
            if (!!(country?.iso2)) itiRef.current.setCountry(country?.iso2)
            if (onChange instanceof Function) {
                onChange(e)
            }
            if (onPhoneNumberChange instanceof Function) {
                onPhoneNumberChange(e, {
                    isValid: itiRef.current.isValidNumber(),
                    value: itiRef.current.getNumber(),
                    country,
                    fullNumber: itiRef.current.getNumber(),
                    ext: itiRef.current.getExtension(),
                    errors: itiRef.current.getValidationError()
                })
            }
        } else {
            if (onChange instanceof Function) {
                onChange(e)
            }
        }
    }

    const handleBlur = (e) => {
        if (itiRef.current) {
            const country = itiRef.current.getSelectedCountryData()
            if (!!(country?.iso2)) itiRef.current.setCountry(country?.iso2)
            if (onBlur instanceof Function) {
                onBlur(e)
            }
            if (onPhoneNumberBlur instanceof Function) {
                onPhoneNumberBlur(e, {
                    isValid: itiRef.current.isValidNumber(),
                    value: itiRef.current.getNumber(),
                    country,
                    fullNumber: itiRef.current.getNumber(),
                    ext: itiRef.current.getExtension(),
                    errors: itiRef.current.getValidationError()
                })
            }
        } else {
            if (onChange instanceof Function) {
                onBlur(e)
            }
        }
    }

    React.useEffect(() => {

    }, [getIntelInstance, itiRef.current])
    return (
        <input onChange={handleChange} onBlur={handleBlur} value={value} ref={currentElRef} type="tel" {...props} />
    )
})