import React from "react";
import { useEntryAnimation } from "../components/shared/FramerMotionAnimations";
import { useIntersectionListener } from "./hooks";

export const getVariableColor = (color, defaultColor = "") => {
    return color ? (color.includes("#") ? color : `var(--${color}, ${color})`) : defaultColor;
}

export const clx = (...classes) => {
    return classes.filter(c => c).join(" ");
}

export const asyncLoadScript = (url, attributesMap = null) => {
    if (!document.querySelector("[src='" + url + "']")) {
        const scriptTag = document.createElement("script")
        scriptTag.setAttribute("src", url)
        scriptTag.async = true
        if (attributesMap && typeof attributesMap === "object" && Object.keys(attributesMap).length) {
            Object.keys(attributesMap).forEach(k => scriptTag.setAttribute(k, attributesMap[k]))
        }
        document.body.appendChild(scriptTag)
        return scriptTag;
    }
}

export const IntersectionAnimation=({
    children
})=>{
    const elRef=React.useRef()
    const {hasAnimated, entry,isIntersecting,setHasAnimated}=useEntryAnimation(elRef.current)
    return(
        children instanceof Function ? children(({elRef, hasAnimated, entry,isIntersecting,setHasAnimated})):<></>
    )
}

export const IntersectionWatcher=({children,onlyFirstIntersection=false, intersectionProps})=>{
    const [ref, setRef]=React.useState(null)
    const intersection = useIntersectionListener(ref, {onlyFirstIntersection, ...intersectionProps})
    return(
        typeof children ==="function" ? children({...intersection, ref:setRef})  : <></>
    )
}