import Joi from "joi"
import React from "react"
import { useTranslation } from "react-i18next"
import { clx } from "../../../helpers/components"
import { ModalSuccess } from "../../pages/partnersForm"
import { Button } from "../../ui/Button"
import { ProgressBar } from "../../ui/ProgressBar"
import { CountrySelectBox, TextField, PhoneField } from "../../ui/TextField"
import { Alert } from "../Alert"
import style from "./style.module.scss"
import {useNavigate} from "react-router-dom"
import { MdWarning } from "react-icons/md"

export function ProgressForm({
    defaultForm,
    stepLen,
    nextBtnLabel,
    prevBtnLabel,
    imgSrc,
    imgAlt,
    onSubmit, 
    successModalMessage,
    successModalBtnTitle
}) {
    const { t } = useTranslation("translations")
    const [form, setForm] = React.useState(defaultForm)
    const [openModal, setOpenModal] = React.useState(null)
    const [currentStep, setCurrentStep] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [openSuccessModal, setSuccessModal] = React.useState(false)
    const navigate=useNavigate()


    const handleForm = (name, validationCallbacks) => (e) => {
        const validatorResult = validationCallbacks.map((v) => v(e.target.value)).find(v => Boolean(v) && !v.isValid)
        setForm(prev => ({
            ...prev,
            [name]: {
                ...prev[name],
                error: false,
                errorMessage: "",
                isValid: true,
                value: e.target.value,
                ...validatorResult
            },

        }))
    }
    const validators = {
        notEmpty: (min = 1) => (value) => {
            if (!(value.toString().length >= min)) {
                return {
                    isValid: false,
                    errorMessage: "global-form.errors.invalid-empty-field"
                }
            } else {
                return {
                    isValid: true
                }
            }
        },
        email: () => (value) => {
            const { error } = Joi.string().email({ tlds: false }).validate(value)
            if (error) {
                return {
                    isValid: false,
                    errorMessage: "global-form.errors.invalid-email"
                }
            } else {
                return {
                    isValid: true
                }
            }
        }
    }

    const field = (name) => {

        return ({
            onChange: handleForm(name, ((form[name].validators || []).map((v) => validators[v]()))),
            error: form[name].error,
            onBlur: () => {
                setForm(prev => ({ ...prev, [name]: { ...prev[name], error: !prev[name].isValid } }))
            },
            value: form[name].value,
            errorMessage: form[name].errorMessage && t(form[name].errorMessage) ? t(form[name].errorMessage) : undefined
        })
    }


    const getCustomField = (name, fielddata) => {
        switch (fielddata.type) {
            case "text":
                return (
                    <div className={style.formContainerForm}>
                        <TextField
                            {...field(name)}
                            label={t(fielddata.label)}
                            placeholder={t(fielddata.placeholder)}

                        />
                    </div>
                );
            case "country-select": {
                return (
                    <div className={style.formContainerForm}>
                        <CountrySelectBox
                            {...field(name)}
                            onChange={(value) => handleForm(name, ((form[name].validators || []).map((v) => validators[v]())))({ target: { value } })}
                            label={t(fielddata.label)}
                        />
                    </div>
                )
            };
            case "textfield": {
                return (
                    <div className={style.formContainerForm}>
                        <TextField
                            {...field(name)}
                            variant="lined"
                            label={t(fielddata.label)}
                            placeholder={t(fielddata.placeholder)}
                        />
                    </div>
                )
            };
            case "tel": {
                return (<div className={style.formContainerForm}>
                    <PhoneField 
                        {...field(name)}
                        label={t(fielddata.label)}
                        placeholder={t(fielddata.placeholder)}
                        value={form.whatsappNumber.value}
                        onChange={({ value }) => {//isValid(add)
                            setForm(prevForm => ({
                                ...prevForm,
                                whatsappNumber: {
                                    ...prevForm.whatsappNumber,
                                    value: value
                                }
                            }));
                        }}
                        endAdornment={
                            form.whatsappNumber.errorMessage && (
                                form.whatsappNumber.isValid ? (
                                    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />
    
                            )
                        }
                        onPhoneNumberBlur={(isValid) => {
                            if (!isValid) {
                                return false;
                            }
                        }}
                    />
                </div>)
            }
            default:
            return (
                <div className={style.formContainerForm}>
                    <p>Champ non pris en charge : {fielddata.type}</p>
                </div>
            );
        }
    }

    const getStepForm = () => {
        const data = []
        for (let field in form) {
            if (form[field].step === currentStep) {
                data.push(getCustomField(field, form[field]))
            }
        }
        return (
            <div>
                {data}
            </div>
        )
    }


    const isFormValid = (step = 1) => {
        const data = {}
        let isFormValid = true
        for (let field in form) {

            if (!((step >= 1 && form[field].step === step) || step === 0)) continue;

            const validatorResult = form[field].validators.map((v) => validators[v]()(form[field].value)).find(v => Boolean(v) && !v.isValid)
            const error = validatorResult && !validatorResult.isValid ? true : false
            data[field] = {
                ...form[field],
                ...validatorResult,
                error,
            };
            isFormValid = isFormValid ? isFormValid && !error : isFormValid
        }
        setForm(prev => ({
            ...prev,
            ...data
        }))

        if (!isFormValid){
            setOpenModal(("global-form.errors.should-fill-all-field"))
        }else{
            setOpenModal(null)
        }

        return isFormValid
    }

   
    const handleSubmit = () => {
        const isValid = isFormValid(currentStep);
        if (isValid) {
            const isAtEndStep = currentStep >= stepLen
            if (isAtEndStep) { 
                setCurrentStep(3)
                onSubmit(form, setLoading, setSuccessModal, setOpenModal,resetForm);
            } else {
                setCurrentStep(prev => ++prev)
            }
        }
    }
    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(prev => --prev)
        }else{
            navigate(-1)
        }
    }
    const resetForm = () =>{
        setForm(defaultForm)
        setCurrentStep(1)
    }

    const handleRedirect = () => {
        setOpenModal(false);
        navigate("/partners");
    }
    return (
        <div className="container">
            <div className={clx(style.formContainer)}>
                <div className={style.imgContainer}>
                    <img src={imgSrc} alt={imgAlt}  style={{width: '100%', height:'100%'}}/>
                </div>
                <div className={style.formContainerFormContainer}>
                    <div className={style.form}>
                        <h2>{t("ambassadors-form.form.title")}</h2>
                        <Alert theme="danger" open={Boolean(openModal)} onClose={() => setOpenModal(false)}>
                            {t(openModal)}
                        </Alert>
                        <ProgressBar progress={Math.round((100 / (stepLen - 1)) * (currentStep - 1))} />
                        <div className={style.formFieldContainer}>
                            {getStepForm()}

                            <div className={style.formContainerForm}>
                                <div className={style.footerBtns}>
                                    <div>
                                        <Button onClick={handleBack} variant="outlined" ariaLabel="button back" className={style.cancel}>{prevBtnLabel}</Button>
                                    </div>
                                    <div>
                                        <Button loading={loading} onClick={handleSubmit} ariaLabel="button next" className={style.submit}>{currentStep === stepLen ? t('ambassadors-form.form.finishBtn') : nextBtnLabel}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSuccess
                successModalMessage={successModalMessage}
                successModalBtnTitle={successModalBtnTitle}
                onClose={handleRedirect}
                isOpen={openSuccessModal}
            />
        </div >
    )
}