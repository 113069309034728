import React, {useEffect} from "react"
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../helpers/func";
import style from "./style.module.scss"
import { Button } from "../../ui/Button";
import { LoaderSpinner } from "../../ui/Spinner";
import { getMobileOperatingSystem } from "../../../helpers/func";
import { getMobileDownloadLink } from "../../../helpers/func";

export function AppDownloader() {
    const { t } = useTranslation("translations");
    const navigate = useNavigate()
    const event = React.useRef(null)
    const [phoneType, setPhoneType] = React.useState(null)


    React.useEffect(() => {
        if (!isMobile()) {
            navigate("/")
        }
    }, [])

    
    React.useEffect(() => {
        const CheckAndHideFooter = () => {
            const notifExist = localStorage.getItem('notif')
            const ftElement = document.querySelector('#preloader');
            const footers = document.getElementById('footerCustom');
            if(notifExist || !notifExist) { 
                if(ftElement)
                    ftElement.style.display = 'none';
                if (footers) 
                    footers.style.display = 'none';
            } 
        }
        
        CheckAndHideFooter();
    }, [])

    React.useEffect(() => {
        if (!event.current) {
            event.current = () => {
                if (!isMobile()) {
                    navigate("/")
                }
            }
            window.addEventListener("resize", event.current)
            return () => {
                window.removeEventListener("resize", event.current)
                event.current = null
            }
        }

    }, [])

    React.useEffect(() => {
        setPhoneType(getMobileOperatingSystem())
    }, [])



    return (
        phoneType === null ? <div className="flex align-items-center justify-content-center" style={{ height: "100vh" }}>
            <LoaderSpinner className={style.spinner} />
        </div> :
            <div className={style.container}>
                <div className={style.el}>
                    <img className={style.logo} src="/images/izichange-app.png" alt="IZICHANGE app logo" />
                    <h3>Izichange APP</h3>
                    <p>
                        {t("mobile-app-download.description")}
                    </p>
                    <div>
                        <h5>
                            <Trans t={t} i18nKey={"mobile-app-download.subtitle"}></Trans>
                        </h5>
                        {
                            phoneType === "iOS" ?
                                <div>
                                    <Button className={style.ios} ariaLabel="telecharger ios app" onClick={()=>{
                                        window.open(getMobileDownloadLink("iOS"))
                                    }}>
                                        <span className={style.contentContainer}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: `
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2646 11.0338C13.2737 10.302 13.4617 9.58435 13.8112 8.94779C14.1606 8.31123 14.66 7.7764 15.2631 7.39306C14.88 6.82735 14.3746 6.36178 13.787 6.03335C13.1995 5.70492 12.546 5.52267 11.8784 5.50108C10.4544 5.34652 9.07379 6.38217 8.34812 6.38217C7.6084 6.38217 6.49111 5.51642 5.28797 5.54202C4.50975 5.56801 3.75131 5.80201 3.08656 6.2212C2.4218 6.64039 1.87339 7.23048 1.49477 7.93398C-0.14533 10.8701 1.07804 15.1851 2.64912 17.5585C3.43517 18.7206 4.35383 20.0188 5.55588 19.9727C6.73216 19.9223 7.17147 19.1972 8.59134 19.1972C9.99803 19.1972 10.4102 19.9727 11.6366 19.9435C12.8988 19.9223 13.6941 18.7761 14.4526 17.603C15.0173 16.7749 15.4519 15.8597 15.7402 14.8913C15.0069 14.5706 14.3811 14.0338 13.9409 13.3478C13.5007 12.6619 13.2655 11.8571 13.2646 11.0338Z" fill="white"/>
                                <path d="M10.9481 3.94018C11.6363 3.08594 11.9753 1.98795 11.8932 0.879395C10.8418 0.99358 9.87059 1.51317 9.17309 2.33464C8.83206 2.73595 8.57087 3.20282 8.40445 3.70857C8.23802 4.21432 8.16963 4.74902 8.20319 5.28211C8.72909 5.28771 9.24936 5.16985 9.72482 4.9374C10.2003 4.70496 10.6185 4.36399 10.9481 3.94018Z" fill="white"/>
                            </svg>
                            `
                                                }}
                                            ></span>
                                            <span className={style.content}>
                                                <span>{t("mobile-app-download.downloadOn")}</span>
                                                <span>App Store</span>
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                                :
                                <div>
                                    <button className={style.android} ariaLabel="android app telecharger" onClick={()=>{
                                        window.open(getMobileDownloadLink("Android"))
                                    }}>
                                        <span className={style.contentContainer}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: `
                                    <svg style="height: 32px;" width="17" height="20" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.957585 0.915013C0.714732 1.17486 0.574463 1.5719 0.574463 2.09159V20.5239C0.574463 21.0436 0.714731 21.4407 0.965959 21.6901L1.03086 21.7462L11.4317 11.419V11.1882L1.02249 0.858887L0.957585 0.915013Z"
                                                    fill="url(#paint0_linear_135_7802)" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M14.892 14.8714L11.423 11.4268V11.1857L14.892 7.74121L14.9674 7.78694L19.0708 10.1027C20.2453 10.7596 20.2453 11.8447 19.0708 12.5099L14.9674 14.8256L14.892 14.8714Z"
                                                    fill="url(#paint1_linear_135_7802)" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M14.9675 14.826L11.4231 11.3066L0.957336 21.6984C1.34046 22.1058 1.98318 22.1515 2.70128 21.7545L14.9675 14.826Z"
                                                    fill="url(#paint2_linear_135_7802)" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M14.9675 7.7874L2.70128 0.869291C1.98318 0.461855 1.33837 0.517981 0.957336 0.925418L11.4231 11.3067L14.9675 7.7874Z"
                                                    fill="url(#paint3_linear_135_7802)" />
                                                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M14.8921 14.751L2.70959 21.6233C2.02918 22.0121 1.42204 21.985 1.03054 21.6316L0.965637 21.6961L1.03054 21.7522C1.42204 22.1035 2.02918 22.1326 2.70959 21.7439L14.9759 14.8258L14.8921 14.751Z"
                                                    fill="black" />
                                                <path opacity="0.12" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M19.0709 12.3897L14.8817 14.7511L14.957 14.826L19.0604 12.5102C19.6487 12.1776 19.9376 11.7411 19.9376 11.3066C19.902 11.7058 19.6026 12.0841 19.0709 12.3897Z"
                                                    fill="black" />
                                                <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M2.70134 0.989584L19.0709 10.2234C19.6027 10.5207 19.9021 10.9094 19.9481 11.3065C19.9481 10.872 19.6592 10.4355 19.0709 10.1029L2.70134 0.869016C1.52685 0.201735 0.57428 0.758842 0.57428 2.09133V2.21189C0.57428 0.877331 1.52685 0.330618 2.70134 0.989584Z"
                                                    fill="white" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_135_7802" x1="10.4988" y1="1.89272" x2="-6.14581"
                                                        y2="6.35929" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#00A0FF" />
                                                        <stop offset="0.00657" stop-color="#00A1FF" />
                                                        <stop offset="0.2601" stop-color="#00BEFF" />
                                                        <stop offset="0.5122" stop-color="#00D2FF" />
                                                        <stop offset="0.7604" stop-color="#00DFFF" />
                                                        <stop offset="1" stop-color="#00E3FF" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_135_7802" x1="20.6025" y1="11.3063" x2="0.288592"
                                                        y2="11.3063" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#FFE000" />
                                                        <stop offset="0.4087" stop-color="#FFBD00" />
                                                        <stop offset="0.7754" stop-color="#FFA500" />
                                                        <stop offset="1" stop-color="#FF9C00" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_135_7802" x1="13.0402" y1="13.2217" x2="-0.194808"
                                                        y2="35.6559" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#FF3A44" />
                                                        <stop offset="1" stop-color="#C31162" />
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_135_7802" x1="-1.67805" y1="-5.21587" x2="4.22657"
                                                        y2="4.80356" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#32A071" />
                                                        <stop offset="0.0685" stop-color="#2DA771" />
                                                        <stop offset="0.4762" stop-color="#15CF74" />
                                                        <stop offset="0.8009" stop-color="#06E775" />
                                                        <stop offset="1" stop-color="#00F076" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                            `
                                                }}
                                            ></span>
                                            <span className={style.content}>
                                                <span>{t("mobile-app-download.downloadOn")}</span>
                                                <span>Play Store</span>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                        }

                        {/**/}
                        <div className={style.bannerBottom}>
                            <img
                                src="/images/imgapp.png" alt="App"
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}