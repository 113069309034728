import { clx } from "../../../helpers/components"
import style from "./style.module.scss"
export function AnimatedGatewayList() {

    return (
        <div className={style.listContainer}>

            <div className={clx(style.lineContainer, style.first)}>
                <div className={clx(style.dot, style.start)}></div>
                <div className={clx(style.dot, style.end)}></div>


            </div>
            <div className={style.centerContainer}>
                <img src="/images/izilogo.png" alt="logo Izichange" width='100%' height='100%'/>

            </div>


            <div className={clx(style.lineContainer, style.last)}>
                <div className={clx(style.dot, style.start)}></div>
                <div className={clx(style.dot, style.end)}></div>
            </div>

            <div className={clx(style.lineContainer, style.first, style.overlay)}>
                <div className={style.gatewayContainer}>
                    <Gateway src="/images/orange_1.png" />
                    <Gateway src="/images/freemoney_1.png" />
                    <Gateway src="/images/wave_1.png" />
                    
                    <Gateway src="/images/moov_1.png" />
                    <Gateway src="/images/airtel.png" />
 
                </div>
            </div>

            <div className={clx(style.lineContainer, style.last, style.overlay)}>
                <div className={style.gatewayContainer}>
                    
                    
                <Gateway src="/images/tmoney_1.png" />
                    <Gateway src="/images/mtn_1.png" />
                    <Gateway src="/images/mpsea_1.png" />
                </div>
            </div>
        </div>
    )
}

export const Gateway = ({ src }) => {
    return (
        <div className={style.gateway}>
            <img src={src} alt="Logo Gateway"  style={{width: '80%', height:'80%'}}/>
        </div>
    )
}