import React from "react"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { clx } from "../../../../helpers/components"
import { SectionHeader } from "../../../shared/TitleHeader"
import style from "./style.module.scss"
import "swiper/css"

export function StepSlide() {
    const { t } = useTranslation("translations")
    const [state, setState] = React.useState(1)
    const timer = React.useRef(null)
    const swiper = React.useRef(null)
    const handleChange = () => {
        timer.current = setInterval(() => {
            setState(prev => {
                const page = prev >= 3 ? 1 : prev + 1
                if (swiper.current) {
                    swiper.current.slideTo(page - 1)
                }
                return (
                    page
                )
            })
        }, 10 * 1000)

    }
    const handleClick = (id) => (e) => {
        if (swiper.current) {
            swiper.current.slideTo(id - 1)
        }
        setState(id)
        clearTimeout(timer.current)
        handleChange()
    }
    React.useEffect(() => {
        if (!timer.current) {
            handleChange()
        }
    }, [])

    return (
        <div className="container my-8rem">
            <SectionHeader
                title={t("home-page.step-slides.title")}
                subtitle={t("home-page.step-slides.subtitle")}
                position="center"
            />
            <div className={clx(style.stepslide)}>
                <div className={clx(style.pics)}>
                    <div className={style.picsContainer}>
                        <div className={clx(style.afficherOnDesktop)}>
                            <img src="/images/Slide_Phone_1.svg" alt='menu' className={clx("mt-2rem", state === 1 && style.active)}  style={{width: '100%', height:'100%'}}/>
                            <img src="/images/Slide_Phone_2.svg" alt='menu' className={clx("mt-2rem", state === 2 && style.active)}  style={{width: '100%', height:'100%'}}/>
                            <img src="/images/Slide_Phone_3.svg" alt='menu' className={clx("mt-2rem", state === 3 && style.active)}  style={{width: '100%', height:'100%'}}/>
                        </div>
                        <div className={clx(style.afficherOnMobile)}>
                            <img src="/images/Slide_Phone_1.svg" alt='menu' className={clx("mt-2rem", state === 1 && style.active)}  style={{width: '100%', height:'100%'}}/>
                            <img src="/images/Slide_Phone_2.svg" alt='menu' className={clx("mt-2rem", state === 2 && style.active)}  style={{width: '100%', height:'100%'}}/>
                            <img src="/images/Slide_Phone_3.svg" alt='menu' className={clx("mt-2rem", state === 3 && style.active)}  style={{width: '100%', height:'100%'}}/>
                        </div>
                    </div>
                </div>
                <div className={clx(style.descriptions,)}>
                    <div>
                        <div
                            className={clx(style.item, state === 1 && style.active)}
                            onClick={handleClick(1)}
                        >
                            <p
                                style={{
                                    "--slides-bg-color": "#E8EFFF",
                                    "--slides-text-color": "var(--color-primary-blue)"
                                }}
                            >
                                <span className={clx(style.statecss)}>{t("home-page.step-slides.1.counter")}</span>
                            </p>
                            <p className="ml-1rem mb-2rem mt-10">
                                {t("home-page.step-slides.1.description_1")}
                                <span className="">{t("home-page.step-slides.1.description_2")}</span>
                            </p>
                        </div>
                        <div
                            className={clx(style.item, state === 2 && style.active)}
                            onClick={handleClick(2)}
                        >
                            <p
                                style={{
                                    "--slides-bg-color": "var(--color-danger-lighten)",
                                    "--slides-text-color": "var(--color-danger)"
                                }}
                            >
                                <span className={clx(style.statecss)}>{t("home-page.step-slides.2.counter")}</span>
                            </p>
                            <p className="ml-1rem mb-2rem mt-10">
                                {t("home-page.step-slides.2.description_1")}
                                <span className="">{t("home-page.step-slides.2.description_2")}</span>
                            </p>
                        </div>
                        <div
                            className={clx(style.item, state === 3 && style.active)}
                            onClick={handleClick(3)}
                        >
                            <p
                                style={{
                                    "--slides-bg-color": "#E8EFFF",
                                    "--slides-text-color": "var(--color-primary-blue)"
                                }}
                            >
                                <span className={clx(style.statecss)}>{t("home-page.step-slides.3.counter")}</span>
                            </p>
                            <p className="ml-1rem mb-2rem mt-10">
                                {t("home-page.step-slides.3.description_1")}
                                <span className="">{t("home-page.step-slides.3.description_2")}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className={style.descriptionSwipe}
                >
                    <Swiper
                        onSlideChange={(swiper)=>{
                            setState(swiper.activeIndex + 1)
                        }}
                        onSwiper={(s) => {
                            s.slideTo()
                            swiper.current = s
                        }}
                    >
                        <SwiperSlide>
                            <div
                                className={clx(style.item, state === 1 && style.active)}
                                onClick={handleClick(1)}
                            >
                                <p
                                    style={{
                                        "--slides-bg-color": "#E8EFFF",
                                        "--slides-text-color": "var(--color-primary-blue)"
                                    }}
                                >
                                    <span className={clx(style.statecss)}>
                                        {t("home-page.step-slides.1.counter")}
                                    </span>
                                </p>
                                <p className="ml-1rem mb-2rem mt-10">
                                    {t("home-page.step-slides.1.description_1")}
                                    <span className="">{t("home-page.step-slides.1.description_2")}</span>
                                </p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                className={clx(style.item, state === 2 && style.active)}
                                onClick={handleClick(2)}
                            >
                                <p
                                    style={{
                                        "--slides-bg-color": "var(--color-danger-lighten)",
                                        "--slides-text-color": "var(--color-danger)"
                                    }}
                                >
                                    <span className={clx(style.statecss)}>{t("home-page.step-slides.2.counter")}</span>
                                </p>
                                <p className="ml-1rem mb-2rem mt-10">
                                    {t("home-page.step-slides.2.description_1")}
                                    <span className="">{t("home-page.step-slides.2.description_2")}</span>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                className={clx(style.item, state === 3 && style.active)}
                                onClick={handleClick(3)}
                            >
                                <p
                                    style={{
                                        "--slides-bg-color": "#E8EFFF",
                                        "--slides-text-color": "var(--color-primary-blue)"
                                    }}
                                >
                                    <span className={clx(style.statecss)}>{t("home-page.step-slides.3.counter")}</span>
                                </p>
                                <p className="ml-1rem mb-2rem mt-10">
                                    {t("home-page.step-slides.3.description_1")}
                                    <span className="">{t("home-page.step-slides.3.description_2")}</span>
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}