import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Banner } from "../components/pages/acceuil/Banner";
import { CountrySection } from "../components/pages/acceuil/CountrySection";
import { CryptoArray } from "../components/pages/acceuil/CryptoArray";
import { StatistiqueSection, StatistiqueSection2 } from "../components/pages/acceuil/StatistiqueSection";
// import { PubSlider } from "../components/pages/acceuil/PubSlider";
// import { StepSession } from "../components/pages/acceuil/StepSection";
import { TagSection } from "../components/pages/acceuil/TagSection";
import { CTASection } from "../components/pages/acceuil/CTASection";
import { TestimonialSection } from "../components/pages/acceuil/TestimonialSection";
import { BlogList } from "../components/pages/acceuil/BlogList";
import { Layout } from "../components/shared/Layout";
import { BannerCTA } from "../components/pages/acceuil/BannerCta";
import { StepSlide } from "../components/pages/acceuil/StepSlide";
import { CountryList } from "../components/pages/acceuil/CountryList";
import { ContactUs } from "../components/pages/acceuil/ContactUs";
import { Apdp } from '../components/pages/acceuil/Apdp/Notification';
import { Loading } from '../components/pages/acceuil/Loading';
import { BASE_URL } from '../helpers/func';
//import { Apdp } from '../components/pages/apdp';
// import { ModalTerms } from '../components/pages/ModalWelcome';


export function Acceuil() {
    const [isLoading, setIsLoading] = useState(true)
    const [isDivShow, setIsDivShow] = useState(false)
    // const [stateNotif, setStateNotif] = useState(false)
    const location = useLocation();
    const currentPathname = location.pathname;
    const [notifFromLocalStorage, setNotifFromLocalStorage] = useState(false)

    var ide = document.getElementById('ide')

    const checkCsrf = async () => {
        // Vérifier si le token CSRF est dans l'URL
        const urlParams = new URLSearchParams(location.search);     
        if (!urlParams.get("token") && !(localStorage.getItem('csrf')) &&  typeof window !== 'undefined' && !window.navigator.userAgent.includes('ReactSnap')) {
          // Si le token n'est pas présent, rediriger vers test.com
          window.location.href = BASE_URL
          return;
        }
      };

    const checkNotificationInLocalStorage = async () => {
        var notifExist = localStorage.getItem('notif')
        setNotifFromLocalStorage(!!notifExist)
        if(notifExist) {
            isLoading && setIsDivShow(false); 
        } else {
            setIsLoading(false);
            setIsDivShow(true); 
        }

        setIsLoading(false);
        
        window.addEventListener('load', () => {
            setIsLoading(false);
        })
        window.addEventListener('beforeunload', () => {
            setIsLoading(true);
        });
        
        return () => {
            window.removeEventListener('beforeunload', () => {
                setIsLoading(true);
            });
            window.removeEventListener('load', () => {
                setIsLoading(false);
            })
        }
    }
    
    useEffect(()=>{ 
        setTimeout(() => {
            checkCsrf();
            checkNotificationInLocalStorage();
        }, 2500);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000); 
        
    },[])

    const handleCheck = () => { 
        window?.scrollTo(0, 0); 
        setIsDivShow(false) 
        setIsLoading(false)
        localStorage.setItem('notif', 'close')
        document.body.style.overflow = 'auto'; 
        const ftElement = document.querySelector('.ftMobile');
        if (ftElement) {
            ftElement.style.display = 'unset'; 
        }
    }
    if(currentPathname === '/' && notifFromLocalStorage === true) {
        if(ide) {
            ide.style.display = 'none'; 
        }
    } 

    // if(useNavigate('/')) {
    //     isLoading && setIsLoading(false);
    // }

    return (
        <Layout>
            {isLoading ? (<Loading />) : (
                        <>
                            {isDivShow && <Apdp handleCheck={handleCheck} show={isDivShow}/>}
                            {!isDivShow && <><Banner />
                            <BannerCTA/>            
                            <CountrySection />
                            <CryptoArray />
                            <StepSlide/>
                            <StatistiqueSection />
                            <TagSection />
                            <CTASection />
                            <CountryList/>
                            <ContactUs/>
                            <TestimonialSection />
                            <StatistiqueSection2 />
                            <BlogList /></>}
                        </>
                )
            }
        </Layout>
    )
}
