import { Text } from "../../../ui/Text"
import style from "./style.module.scss"
import { format } from "date-fns"
import Fr from "date-fns/locale/fr";
import En from "date-fns/locale/en-US";
import { Swiper, SwiperSlide } from "swiper/react"
import { SectionHeader } from "../../../shared/TitleHeader";
import React from "react";
import { useTranslation } from "react-i18next";


export const BlogList = () => {
    const {t} = useTranslation("translations")

    const data = [
        {
            imgSrc: "/images/trophee1.jpg",
            title: t("blog-list.first"),
            createdAt: "2021-12-18 15:16:51",
            readDurationEstimation: "2min",
            link: "https://blog.izichange.com/"
        },
        {
            imgSrc: "/images/trophee2.jpg",
            title: t("blog-list.two"),
            createdAt: "2022-12-16 15:16:51",
            readDurationEstimation: "2min",
            link: "https://blog.izichange.com/"
        },
        {
            imgSrc: "/images/trophee3.jpg",
            title: t("blog-list.third"),
            createdAt: "2023-01-26 15:16:51",
            readDurationEstimation: "2min",
            link: "https://blog.izichange.com/"
        },
        {
            imgSrc: "/images/trophee4.jpg",
            title: t("blog-list.fourth"),
            createdAt: "2022-11-23 15:16:51",
            readDurationEstimation: "2min",
            link: "https://blog.izichange.com/izichange-meilleure-entreprise-dans-la-categorie-blockchain-et-crypto-au-next-fintech-forum-abidjan-2022/"
        }
        // {
        //     imgSrc: "/images/blog.png",
        //     title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam perspiciatis quisquam, vero cum quibusdam, aut eaque voluptates veritatis unde eius suscipit nulla rem eum adipisci harum fugit deserunt distinctio ipsum.",
        //     createdAt: "2022-02-12 15:16:51",
        //     readDurationEstimation: "2min"
        // }
    ]

    

    const [swiper, setSwiper] = React.useState(null)
    // const [forceRefresh, setForceRefresh] = React.useState(false)

    const handleSlide = (isNext) => e => {
        const next = document.getElementById('nextButton')
        const prev =  document.getElementById('prevButton')
        if (swiper) {
            if (isNext) {
                swiper.slideNext()
                if(swiper.isEnd){
                   prev.style.display = "block"
                   next.style.display = "none"
                   
                }
            } else {
                swiper.slidePrev()
                if(swiper.isBeginning) {
                    next.style.display = "block"
                    prev.style.display = "none"
                }
            }
            // setForceRefresh(prev => !prev)
        }
    }

    return (
        <div className="container">
            <div className={style.blogListContainer}>
                <div className={style.navigationContainer}>
                    <button
                        {...(Boolean(swiper?.isBeginning) ? { disabled: "disabled" } : {})}
                        onClick={handleSlide(false)}
                        className={style.navigation}
                        aria-label="slides"
                        dangerouslySetInnerHTML={{
                            __html: `
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 17.0312L31.2889 22.2732C35.5067 26.4535 37.6156 28.5437 37.9403 31.0912C38.0199 31.7154 38.0199 32.3471 37.9403 32.9713C37.6156 35.5188 35.5067 37.609 31.2889 41.7893L26 47.0312M62 32.0312C62 48.5998 48.5685 62.0312 32 62.0312C15.4315 62.0312 2 48.5998 2 32.0312C2 15.4627 15.4315 2.03125 32 2.03125C48.5685 2.03125 62 15.4627 62 32.0312Z" stroke="#151515" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        
                        `
                        }}
                    >
                    </button>
                    <button
                        {...(Boolean(swiper?.isEnd) ? { disabled: "disabled" } : {})}
                        onClick={handleSlide(true)}
                        className={style.navigation}
                        aria-label="slide secondary"
                        dangerouslySetInnerHTML={{
                            __html: `
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 17.0312L31.2889 22.2732C35.5067 26.4535 37.6156 28.5437 37.9403 31.0912C38.0199 31.7154 38.0199 32.3471 37.9403 32.9713C37.6156 35.5188 35.5067 37.609 31.2889 41.7893L26 47.0312M62 32.0312C62 48.5998 48.5685 62.0312 32 62.0312C15.4315 62.0312 2 48.5998 2 32.0312C2 15.4627 15.4315 2.03125 32 2.03125C48.5685 2.03125 62 15.4627 62 32.0312Z" stroke="#151515" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        
                        `
                        }}
                    >
                    </button>
                </div>
                <div className={style.slideContainer}>
                    <div className={style.slideContainerTitle}>
                        <SectionHeader
                            title={t("home-page.blog.title")}
                        />
                        <div className={style.navigationContainer}>
                            <button
                                // {...(Boolean(swiper?.isBeginning) ? { disabled: "disabled" } : {})}
                                onClick={handleSlide(false)}
                                className={`${style.navigation} ${swiper?.isBeginning ? 'disabled' : ''}`}
                                id="prevButton"
                                aria-label="blog"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26 17.0312L31.2889 22.2732C35.5067 26.4535 37.6156 28.5437 37.9403 31.0912C38.0199 31.7154 38.0199 32.3471 37.9403 32.9713C37.6156 35.5188 35.5067 37.609 31.2889 41.7893L26 47.0312M62 32.0312C62 48.5998 48.5685 62.0312 32 62.0312C15.4315 62.0312 2 48.5998 2 32.0312C2 15.4627 15.4315 2.03125 32 2.03125C48.5685 2.03125 62 15.4627 62 32.0312Z" stroke="#151515" stroke-width="3" stroke-linecap="round"/>
                                        </svg>
                                    
                                    `
                                }}
                            >
                            </button>
                            <button
                                // {...(Boolean(swiper?.isEnd) ? { disabled: "disabled" } : {})}
                                onClick={handleSlide(true)}
                                className={`${style.navigation} ${swiper?.isEnd ? 'disabled' : ''}`}
                                id="nextButton"
                                aria-label="second titre"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26 17.0312L31.2889 22.2732C35.5067 26.4535 37.6156 28.5437 37.9403 31.0912C38.0199 31.7154 38.0199 32.3471 37.9403 32.9713C37.6156 35.5188 35.5067 37.609 31.2889 41.7893L26 47.0312M62 32.0312C62 48.5998 48.5685 62.0312 32 62.0312C15.4315 62.0312 2 48.5998 2 32.0312C2 15.4627 15.4315 2.03125 32 2.03125C48.5685 2.03125 62 15.4627 62 32.0312Z" stroke="#151515" stroke-width="3" stroke-linecap="round"/>
                                        </svg>
                                    
                                    `
                                }}
                            >
                            </button>
                        </div>
                    </div>
                    
                    <Swiper
                        onInit={setSwiper}
                        slidesPerView={3}
                        spaceBetween={20}
                        centeredSlides={true}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            600: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            750: {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            960: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                centeredSlides: false
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                                centeredSlides: false
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                centeredSlides: false
                            }
                        }}
                    >
                        {
                            data.map((d, i) => (
                                <SwiperSlide key={i}>
                                    <div className={"flex justify-center " + style.blogItemContainer}>
                                        <BlogItem
                                            title={d.title}
                                            createdAt={d.createdAt}
                                            // readDurationEstimation={d.readDurationEstimation}
                                            imgScr={d.imgSrc}
                                            link={d.link}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                </div>
            </div>
        </div>
    )
}

export const BlogItem = ({
    title,
    imgScr,
    createdAt,link
    // readDurationEstimation
}) => {
    const {i18n}=useTranslation("translations")
    
    const languages={
        en:En,
        fr:Fr
    }

    return (
        <div className={style.blogItem}>
            <a href={link} target="_blank" className={style.linkStyle}  rel="noreferrer" aria-label={`Read more about ${title}`}>
                <div className={style.imgContainer}>
                        <img src={imgScr} alt={title}  style={{width: 'calc(100% + 2px)'}}/>
                </div>
                <div className={style.descriptionContainer}> 
                    <Text variant="h6">{title}</Text>
                    <div >
                        <Text>
                            <span style={{color: '#008080', marginRight: 10}}
                                // dangerouslySetInnerHTML={{
                                //     __html: `
                                //         <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                //             <path d="M2 7.03125H18V5.03125C18 4.47897 17.5523 4.03125 17 4.03125H3C2.44772 4.03125 2 4.47897 2 5.03125V7.03125ZM2 9.03125V17.0312C2 17.5835 2.44772 18.0312 3 18.0312H17C17.5523 18.0312 18 17.5835 18 17.0312V9.03125H2ZM17 2.03125C18.6569 2.03125 20 3.3744 20 5.03125V17.0312C20 18.6881 18.6569 20.0312 17 20.0312H3C1.34315 20.0312 0 18.6881 0 17.0312V5.03125C0 3.3744 1.34315 2.03125 3 2.03125C3.00035 2.01592 3 2.00049 3 1.98498V1.03125C3 0.478965 3.44772 0.03125 4 0.03125C4.55228 0.03125 5 0.478965 5 1.03125V1.98498C5 2.00049 4.99965 2.01592 4.99895 2.03125H15.0011C15.0004 2.01592 15 2.00049 15 1.98498V1.03125C15 0.478965 15.4477 0.03125 16 0.03125C16.5523 0.03125 17 0.478965 17 1.03125V1.98498C17 2.00049 16.9996 2.01592 16.9989 2.03125H17Z" fill="#008080"/>
                                //         </svg>
                                    
                                //     `
                                // }}
                            >
                                ➤
                            </span>
                            {format(createdAt instanceof Date ? createdAt : new Date(createdAt), "dd MMM yyyy", { locale: languages[i18n.language] })}
                        </Text>
                        {/* <Text>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 10.0313C19 15.0022 14.9709 19.0313 10 19.0313C5.02908 19.0313 1 15.0022 1 10.0313C1 5.06033 5.02908 1.03125 10 1.03125C14.9709 1.03125 19 5.06033 19 10.0313Z" stroke="#008080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path opacity="0.6" d="M13.339 12.894L9.6709 10.7057V5.98975" stroke="#008080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                            
                                    
                                    `
                                }}
                            ></span>
                            {readDurationEstimation}
                        </Text> */}
                    </div>
                </div>
            </a>
        </div>
    )
}