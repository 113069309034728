import { useTranslation } from "react-i18next"
import { clx } from "../../../../helpers/components"
import { Text } from "../../../ui/Text"
import { SignUpF } from "../AuthForm"
import style from "./style.module.scss"
export const SignUpForm = () => {
    const {t}=useTranslation("translations")

    return (
        <div>
            <div className={clx(style.pageContainer, "container")}>
                <div className={style.leftContainer}>
                    <Text variant="h3">{t("signup-page.subtitle")}</Text>
                    <Text>
                    {t("signup-page.title")}
                    </Text>
                    <div className={style.svgContainer}>
                       <div dangerouslySetInnerHTML={{
                            __html:`
                            <svg width="348" height="300" viewBox="0 0 348 300" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g clip-path="url(#clip0_3695_2743)">
                                <path d="M144.757 260.626L148.558 248.235C143.985 243.197 138.252 239.352 131.858 237.035C138.105 247.395 132.052 260.911 134.574 272.746C135.389 276.431 137.022 279.885 139.352 282.853C141.682 285.82 144.65 288.225 148.034 289.888L153.802 297.042C156.826 291.754 158.669 285.872 159.203 279.803C159.737 273.733 158.951 267.62 156.897 261.884C155.714 258.678 154.131 255.633 152.188 252.822C148.76 256.856 144.757 260.626 144.757 260.626Z" fill="#F2F2F2" />
                                <path d="M123.858 262.21L121.739 249.423C115.401 246.955 108.557 246.073 101.802 246.854C112.009 253.338 112.613 268.137 120.143 277.604C122.515 280.538 125.515 282.901 128.923 284.517C132.331 286.133 136.058 286.96 139.829 286.938L148.178 290.766C148.529 284.683 147.557 278.597 145.331 272.926C143.105 267.254 139.678 262.134 135.284 257.917C132.796 255.575 130.023 253.557 127.031 251.909C125.761 257.049 123.858 262.21 123.858 262.21Z" fill="#F2F2F2" />
                                <path d="M248.257 293.863L242.402 293.862L239.617 271.258L248.258 271.258L248.257 293.863Z" fill="#CD7E89" />
                                <path d="M249.75 299.543L230.872 299.543V299.304C230.872 297.353 231.646 295.482 233.024 294.103C234.402 292.724 236.271 291.949 238.22 291.949H238.22L241.668 289.33L248.102 291.949L249.75 291.949L249.75 299.543Z" fill="#2F2E41" />
                                <path d="M342.83 272.487L338.839 276.773L320.42 263.401L326.311 257.074L342.83 272.487Z" fill="#CD7E89" />
                                <path d="M348 275.266L335.129 289.089L334.954 288.926C333.529 287.596 332.689 285.753 332.621 283.804C332.552 281.855 333.26 279.958 334.589 278.531L334.589 278.53L335.026 274.22L341.327 271.295L342.45 270.088L348 275.266Z" fill="#2F2E41" />
                                <path d="M334.168 259.782L327.979 272.738L289.98 247.553L284.452 240.014L266.326 215.292L258.958 226.995L253.831 239.229C252.16 240.32 250.752 241.769 249.709 243.471C248.666 245.172 248.013 247.085 247.798 249.07L247.563 251.236L250.803 280.388L238.49 284.173L231.418 245.011L233.729 236.525L236.11 224.613L248.092 186.971H280.159L298.55 226.158L299.895 234.142L305.11 240.133L334.168 259.782Z" fill="#2F2E41" />
                                <path d="M267.528 125.385L272.926 129.499L279.436 131.502C279.436 131.502 282.127 169.396 284.552 171.823C286.977 174.25 284.79 174.316 285.277 177.469C285.765 180.622 286.949 187.086 286.949 187.086C270.58 195.759 256.469 195.992 245.134 185.843C244.989 185.215 245.063 184.556 245.343 183.976C245.623 183.396 246.093 182.929 246.674 182.653C248.784 181.532 244.729 176.303 246.454 175.428C248.178 174.554 245.753 166.968 245.753 166.968L247.343 151.142L246.087 148.612L249.154 133.131L254.476 131.186L256.601 126.059L267.528 125.385Z" fill="#008080" />
                                <path d="M270.91 192.735C270.885 192.051 271.011 191.37 271.279 190.739C271.546 190.109 271.948 189.545 272.457 189.087C272.965 188.629 273.568 188.288 274.223 188.089C274.877 187.889 275.567 187.836 276.244 187.932L283.852 173.131L288.09 180.669L280.216 193.569C279.979 194.697 279.337 195.699 278.411 196.385C277.485 197.07 276.34 197.392 275.193 197.29C274.046 197.187 272.976 196.667 272.187 195.827C271.397 194.988 270.943 193.888 270.91 192.735H270.91Z" fill="#CD7E89" />
                                <path d="M283.721 189.114L300.038 163.8L299.984 163.683C299.923 163.55 296.228 155.562 292.647 148.245C292.096 147.119 291.548 146.008 291.015 144.944C290.786 144.485 290.559 144.036 290.336 143.596C288.833 140.628 287.519 138.176 286.735 137.011C285 134.376 282.418 132.415 279.416 131.453L279.34 131.434L279.268 131.464C277.8 132.084 276.587 133.186 275.829 134.589C275.07 135.992 274.812 137.612 275.097 139.182C276.513 146.752 279.666 153.889 284.309 160.03L286.955 163.52L277.762 183.525L283.721 189.114Z" fill="#008080" />
                                <path d="M227.612 92.0808C228.062 92.5972 228.392 93.2059 228.581 93.8641C228.77 94.5222 228.812 95.2138 228.705 95.8902C228.598 96.5665 228.344 97.2111 227.962 97.7786C227.579 98.3461 227.076 98.8227 226.489 99.1747L229.891 115.468L221.856 112.284L219.858 97.2996C219.333 96.2737 219.201 95.0905 219.489 93.9742C219.777 92.8578 220.464 91.886 221.419 91.2427C222.375 90.5994 223.534 90.3294 224.675 90.4839C225.816 90.6384 226.861 91.2066 227.612 92.0808H227.612Z" fill="#CD7E89" />
                                <path d="M219.938 102.969L223.187 132.919L223.302 132.975C223.396 133.022 227.513 135.043 232.565 137.45C233.418 137.856 233.296 139.615 234.186 140.035C234.8 140.324 236.421 139.274 237.041 139.564C242.563 142.149 248.148 144.663 250.374 145.344C253.378 146.297 256.618 146.193 259.555 145.048L259.626 145.016L259.663 144.947C260.414 143.54 260.662 141.919 260.369 140.351C260.075 138.783 259.257 137.362 258.048 136.322C252.187 131.334 245.247 127.777 237.777 125.933L233.526 124.889L228.083 103.554L219.938 102.969Z" fill="#008080" />
                                <path d="M261.773 123.688C267.67 123.688 272.45 118.903 272.45 113C272.45 107.098 267.67 102.312 261.773 102.312C255.876 102.312 251.095 107.098 251.095 113C251.095 118.903 255.876 123.688 261.773 123.688Z" fill="#CD7E89" />
                                <path d="M251.172 105.838C252.05 103.281 256.421 101.508 263.507 101.261C270.594 101.015 272.391 109.374 272.391 109.374C277.121 113.215 267.34 123.499 266.866 123.129L267.079 121.196C267.431 117.996 268.929 115.499 267.025 112.164L265.196 112.185C262.835 112.639 260.474 113.094 258.112 113.548L260.908 111.084L260.829 110.962C259.64 111.582 258.346 111.975 257.013 112.122C255.51 112.21 253.868 111.612 253.148 110.289C252.956 109.925 252.839 109.526 252.803 109.116C250.777 109.927 249.85 112.427 249.85 112.427C249.85 112.427 250.294 108.396 251.172 105.838Z" fill="#2F2E41" />
                                <path d="M43.0186 106.479C42.8738 106.296 42.7178 106.121 42.5515 105.956C42.5921 105.9 42.6328 105.839 42.6734 105.783L43.0186 106.479Z" fill="#2F2E41" />
                                <path d="M45.0952 149.465C43.3486 152.571 39.8605 154.304 36.4029 155.152C32.5192 156.171 28.4296 156.092 24.5881 154.924C24.1007 154.766 23.6133 154.583 23.1411 154.38C23.3681 153.776 23.548 153.156 23.6793 152.525C23.856 151.671 23.9445 150.8 23.9433 149.928C23.5586 150.679 23.0602 151.367 22.4658 151.966C22.0073 152.448 21.4865 152.867 20.9172 153.211C19.1516 152.138 17.6227 150.717 16.4239 149.033C16.5 148.845 16.5762 148.657 16.6473 148.469C17.016 147.487 17.3079 146.478 17.5205 145.45C17.809 144.061 17.9537 142.646 17.9521 141.227C17.3261 142.449 16.5142 143.567 15.5455 144.541C15.255 144.852 14.9465 145.145 14.6214 145.42C14.118 143.889 13.9234 142.274 14.049 140.668C14.1745 139.062 14.6176 137.497 15.3525 136.063C17.3631 132.343 21.2625 130.132 24.7354 127.728C28.2133 125.324 31.6912 122.072 31.9857 117.848C32.1837 114.997 30.8636 112.141 31.3917 109.335C31.6137 108.152 32.1559 107.053 32.9591 106.157C33.7622 105.261 34.7956 104.603 35.9467 104.254C37.0977 103.906 38.3222 103.88 39.4868 104.18C40.6514 104.48 41.7115 105.094 42.5515 105.956C40.5815 108.751 38.6217 111.643 37.8246 114.967C37.0122 118.351 37.6773 122.321 40.3429 124.562C41.2568 125.334 42.3789 125.883 43.0846 126.849C44.0239 128.13 44.029 129.837 44.1813 131.413C44.4859 134.478 45.4862 137.425 46.0244 140.459C46.5677 143.489 46.5981 146.777 45.0952 149.465Z" fill="#2F2E41" />
                                <path d="M9.29126 275.499L13.9252 279.082L29.9401 262.897L23.1008 257.607L9.29126 275.499Z" fill="#A0616A" />
                                <path d="M9.48837 271.998L17.1512 277.226L14.7262 280.367L17.9739 290.568C18.1343 291.072 18.1297 291.614 17.9607 292.115C17.7917 292.616 17.4671 293.05 17.0343 293.353C16.6015 293.656 16.0833 293.813 15.5552 293.801C15.027 293.788 14.5167 293.607 14.0987 293.284L5.37807 286.539L6.27361 282.269L3.28803 284.922L0 282.379L9.48837 271.998Z" fill="#2F2E41" />
                                <path d="M32.8008 115.51C32.8001 117.322 33.2439 119.107 34.0931 120.707C34.9424 122.308 36.1712 123.675 37.6716 124.69C39.1721 125.704 40.8983 126.334 42.6988 126.524C44.4992 126.715 46.3189 126.46 47.998 125.782C49.6771 125.104 51.1643 124.024 52.3291 122.637C53.4939 121.249 54.3006 119.597 54.6784 117.825C55.0563 116.052 54.9937 114.214 54.4962 112.472C53.9987 110.729 53.0815 109.135 51.825 107.831L51.7719 107.778C51.6835 107.684 51.595 107.592 51.502 107.503C51.5015 107.503 51.5009 107.503 51.5003 107.503C51.4998 107.502 51.4993 107.502 51.4989 107.502C51.4985 107.501 51.4982 107.501 51.4979 107.5C51.4977 107.5 51.4976 107.499 51.4976 107.498C51.3826 107.392 51.2676 107.286 51.1481 107.184C49.2015 105.475 46.4654 103.619 43.6737 103.619C37.5638 103.619 32.8008 109.394 32.8008 115.51Z" fill="#2F2E41" />
                                <path d="M95.8446 109.823C95.8975 110.414 95.8204 111.009 95.6188 111.566C95.4172 112.124 95.096 112.63 94.6777 113.05C94.2594 113.47 93.7542 113.792 93.1978 113.996C92.6414 114.199 92.0472 114.277 91.4572 114.226L64.0683 138.98L59.355 131.528L87.7594 109.539C87.9116 108.553 88.42 107.656 89.1883 107.02C89.9566 106.384 90.9314 106.051 91.928 106.086C92.9246 106.122 93.8738 106.521 94.5956 107.21C95.3174 107.899 95.7618 108.828 95.8446 109.823V109.823Z" fill="#A0616A" />
                                <path d="M52.3627 146.452C52.3627 146.452 53.3817 148.336 56.8627 145.427C59.6495 143.098 73.0008 132.987 75.8771 128.844C76.5339 129.104 77.2586 129.139 77.9371 128.941C78.6155 128.744 79.2091 128.327 79.6244 127.755L71.7435 120.846C69.3855 121.117 69.436 122.703 69.6816 123.603L63.7369 126.299L48.3857 136.12L51.6019 145.103L52.3627 146.452Z" fill="#008080" />
                                <path d="M37.4909 163.81L36.9169 166.546L36.4294 176.64L57.8899 177.821L57.2235 162.296L55.1433 158.875L37.4909 163.81Z" fill="#A0616A" />
                                <path d="M46.5863 127.355C51.7087 127.355 55.8611 123.199 55.8611 118.071C55.8611 112.944 51.7087 108.787 46.5863 108.787C41.464 108.787 37.3115 112.944 37.3115 118.071C37.3115 123.199 41.464 127.355 46.5863 127.355Z" fill="#A0616A" />
                                <path d="M30.12 152.315L30.063 144.597C30.0617 141.821 31.0004 139.125 32.7261 136.951C34.4517 134.777 36.8625 133.253 39.565 132.626L39.6 132.618L41.4339 130.145L48.8851 130.891L50.2508 133.685L55.65 136.903L57.7287 143.592C58.6304 144.193 59.3988 144.974 59.9863 145.886C60.5738 146.797 60.9677 147.82 61.1437 148.89C61.8494 153.402 58.6931 157.917 58.3176 158.435L57.3502 163.889L36.3058 167.606L36.1349 167.627L30.12 152.315Z" fill="#008080" />
                                <path d="M57.9356 116.415C56.8538 117.101 55.7313 117.721 54.5744 118.27C53.4002 118.835 52.1721 119.279 50.9086 119.597C50.5472 118.803 50.1048 118.048 49.5886 117.345C49.7214 118.178 49.7248 119.027 49.5987 119.861C47.5046 120.199 45.3597 120.023 43.3486 119.348L41.602 116.415L41.47 119.688C40.023 119.953 38.581 120.202 37.134 120.461C37.0477 120.471 36.9614 120.491 36.8801 120.507C35.9814 116.451 37.2559 111.984 40.089 109.28C41.4956 107.951 43.2503 107.05 45.1487 106.681C47.0471 106.313 49.0112 106.491 50.8122 107.196C51.0407 107.288 51.2742 107.389 51.4976 107.496C51.4976 107.497 51.4977 107.497 51.498 107.498C51.4982 107.499 51.4986 107.499 51.4991 107.5C51.4995 107.5 51.5001 107.5 51.5007 107.501C51.5014 107.501 51.502 107.501 51.5027 107.501C51.6347 107.567 51.7464 107.725 51.8936 107.709C58.2301 107.003 58.4332 111.923 57.9356 116.415Z" fill="#2F2E41" />
                                <path d="M42.5063 263.528L47.1474 267.103L63.13 250.885L56.2802 245.609L42.5063 263.528Z" fill="#A0616A" />
                                <path d="M43.1171 260.35L50.7904 265.562L48.3718 268.708L51.64 278.903C51.8015 279.406 51.798 279.948 51.63 280.449C51.4619 280.951 51.1382 281.385 50.7061 281.689C50.2739 281.993 49.756 282.151 49.2278 282.14C48.6997 282.128 48.189 281.948 47.7704 281.626L39.0361 274.898L39.923 270.627L36.9428 273.286L33.6497 270.75L43.1171 260.35Z" fill="#2F2E41" />
                                <path d="M27.4462 243.736L27.446 243.675L29.6456 239.881L27.3995 234.942L27.3321 222.253L27.6962 220.348C28.1859 217.786 28.0435 215.143 27.2812 212.649L27.2401 204.925C27.1612 202.99 30.1557 201.288 29.4574 199.675C22.8569 184.428 36.1975 172.797 36.2876 172.704L36.5602 172.42L38.0394 173.541L39.4472 173.543L58.4456 177.008L58.5828 177.264C59.3301 178.667 60.7722 183.503 60.8577 185.692C61.8046 186.789 72.5899 199.282 73.5487 200.15C80.0276 206.017 83.737 215.73 85.3714 220.997C85.7774 222.299 85.8592 223.68 85.6098 225.021C85.3604 226.361 84.7873 227.62 83.9405 228.689L59.4133 255.581L52.8128 249.482L66.0843 225.274L65.1272 222.818C63.4101 222.501 61.8561 221.597 60.7304 220.261L58.0431 217.068C57.299 216.187 56.8262 215.108 56.6819 213.963L55.0522 213.767L49.2144 207.631L45.1644 244.345L44.0633 247.246L24.8878 269.811L16.7642 264.729L27.4462 243.736Z" fill="#2F2E41" />
                                <path d="M24.5178 86.0894C24.1494 86.5536 23.8872 87.0931 23.7496 87.6698C23.612 88.2464 23.6024 88.8463 23.7214 89.4271C23.8405 90.0079 24.0853 90.5555 24.4387 91.0313C24.792 91.5071 25.2453 91.8996 25.7666 92.1811L19.6692 110.25L24.648 129.399L32.6765 125.766L27.7223 111.322L31.4288 90.2993C31.8432 89.3913 31.9105 88.3628 31.6179 87.4085C31.3253 86.4542 30.6932 85.6406 29.8413 85.1216C28.9895 84.6027 27.977 84.4146 26.9959 84.593C26.0147 84.7713 25.133 85.3038 24.5178 86.0894V86.0894Z" fill="#A0616A" />
                                <path d="M31.8665 145.176C31.8665 145.176 29.9808 146.189 28.6694 141.844C27.6195 138.365 20.4747 119.478 20.3459 114.434C19.6542 114.29 19.0298 113.92 18.5713 113.383C18.1127 112.845 17.8459 112.169 17.813 111.463L28.1901 110.025C30.011 111.549 29.0984 112.846 28.399 113.463L31.9334 119.456L39.3208 135.634L33.2425 144.467L31.8665 145.176Z" fill="#008080" />
                                <path d="M37.1321 120.461C38.1977 117.244 40.1086 114.373 42.6642 112.15C45.2198 109.927 48.326 108.433 51.6569 107.826C51.6702 107.821 51.7144 107.804 51.7719 107.777C51.8073 107.755 51.8516 107.733 51.8958 107.711C51.7631 107.635 51.6348 107.569 51.502 107.502C51.5014 107.502 51.5009 107.502 51.5003 107.502C51.4998 107.502 51.4993 107.501 51.4989 107.501C51.4985 107.501 51.4981 107.5 51.4979 107.5C51.4977 107.499 51.4976 107.498 51.4976 107.498C51.3826 107.392 51.2676 107.285 51.1481 107.183L50.8118 107.197C50.8118 107.197 38.9947 105.323 37.1321 120.461Z" fill="#FD6584" />
                                <path d="M189.616 223.917C190.906 221.976 190.142 219.198 187.911 217.712C185.68 216.226 182.825 216.595 181.535 218.535C180.246 220.476 181.009 223.253 183.24 224.74C185.472 226.226 188.326 225.857 189.616 223.917Z" fill="#F2F2F2" />
                                <path d="M163.436 151.491C164.725 149.55 163.962 146.773 161.731 145.287C159.499 143.801 156.645 144.169 155.355 146.11C154.065 148.05 154.829 150.828 157.06 152.314C159.291 153.8 162.146 153.431 163.436 151.491Z" fill="#F2F2F2" />
                                <path d="M272.918 262.989C274.207 261.048 273.444 258.271 271.213 256.785C268.981 255.299 266.127 255.667 264.837 257.608C263.547 259.548 264.311 262.326 266.542 263.812C268.774 265.298 271.628 264.929 272.918 262.989Z" fill="#F2F2F2" />
                                <path d="M51.5733 29.989C52.863 28.0485 52.0996 25.2708 49.8682 23.7848C47.6368 22.2987 44.7824 22.6671 43.4927 24.6076C42.203 26.548 42.9664 29.3258 45.1978 30.8118C47.4291 32.2978 50.2835 31.9294 51.5733 29.989Z" fill="#F2F2F2" />
                                <path d="M154.867 7.11784C156.157 5.17738 155.394 2.39968 153.162 0.913651C150.931 -0.572373 148.077 -0.203989 146.787 1.73647C145.497 3.67693 146.261 6.45466 148.492 7.94069C150.723 9.42671 153.578 9.0583 154.867 7.11784Z" fill="#F2F2F2" />
                                <path d="M105.981 199.003C107.271 197.062 106.507 194.284 104.276 192.798C102.045 191.312 99.1901 191.681 97.9004 193.621C96.6107 195.562 97.3741 198.339 99.6055 199.825C101.837 201.311 104.691 200.943 105.981 199.003Z" fill="#E6E6E6" />
                                <path d="M319.054 300H9.50751C9.35748 299.999 9.21374 299.94 9.10781 299.833C9.00187 299.727 8.94238 299.583 8.94238 299.433C8.94238 299.282 9.00187 299.138 9.10781 299.032C9.21374 298.926 9.35748 298.866 9.50751 298.865H319.054C319.204 298.865 319.349 298.925 319.455 299.031C319.561 299.138 319.621 299.282 319.621 299.433C319.621 299.583 319.561 299.727 319.455 299.834C319.349 299.94 319.204 300 319.054 300V300Z" fill="#CCCCCC" />
                                <path d="M233.729 29.2565C235.833 29.2565 237.538 27.5499 237.538 25.4447C237.538 23.3394 235.833 21.6328 233.729 21.6328C231.626 21.6328 229.921 23.3394 229.921 25.4447C229.921 27.5499 231.626 29.2565 233.729 29.2565Z" fill="#3F3D56" />
                            </g>
                            <rect x="119" y="16" width="71" height="71" fill="url(#pattern0)" />
                            <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                    <use xlink:href="#image0_3695_2743" transform="scale(0.00429185)" />
                                </pattern>
                                <clipPath id="clip0_3695_2743">
                                    <rect width="348" height="300" fill="white" />
                                </clipPath>
                                <image id="image0_3695_2743" width="233" height="233" href="/images/img_sign_up_form.png"/>                           </defs>
                        </svg>
                            `
                       }}></div>
                    </div>
                </div>
                <div>
                <SignUpF/>
                </div>
            </div>
        </div>
    )
}