import React from 'react';
// import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import "./i18n"
import axios from "axios"

axios.defaults.baseURL="https://izichange.com"; 
 
// const root = ReactDOM.createRoot(document.getElementById('root'));

const root = document.getElementById('root');
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

if (root.hasChildNodes()) {
  hydrate(<App />, root);
} else {
  render(<App />, root);
}
 