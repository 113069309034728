
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { clx } from "../../../../helpers/components"
import { useScrollPosition } from "../../../../helpers/hooks"
import { Button } from "../../../ui/Button"
import style from "./style.module.scss"
import "swiper/css"


export const Goal = React.forwardRef(({
    index = 1,
    textColor = "#fff",
    title,
    color,
    className,
    style: containerStyle
}, ref) => {
    return (
        <div style={containerStyle} ref={ref} className={clx(style.goal, className)}>
            <p
                className={style.index}
                style={{
                    "--color": color,
                    "--text-color": textColor,
                    ...style
                }}
            >{index}</p>
            <p>
                {
                    title
                }
            </p>
        </div>
    )
})

export function GoalSection() {
    const { t } = useTranslation("translations")
    const colors = [
        "#ED5462", "#486DBD", "#FEFF83", "#ED5462", "#486DBD"
    ] 
    const elRefs = React.useRef([])

    //Defining Refs
    // const topHeaderHeight=65
    const fromCenterToTopProcess = useRef(undefined);
    const goalContainerRef = useRef()
    const titleContainerRef = useRef();
    const goalContainerOffsetRef = useRef(null)
    const goalTraderHeight = React.useRef(0)
    const titleContainerElRef=useRef(null)
    const titleWrapperRef=useRef(null)

    //Functions
    function handleScroll() {
        const fromCenterToTopPercent = getFromCenterToTopPercent(goalContainerRef.current)
        const reverseFromCenterToTopPercent = 1 - (fromCenterToTopPercent);

        if (goalContainerOffsetRef.current) {
            const d = goalContainerOffsetRef.current // / 2
            goalContainerRef.current.style
                .setProperty('--offset-size', ((d) * reverseFromCenterToTopPercent) + "px");
        }
        const widowHeight = ((window.innerHeight / 2)) - 150
        goalContainerRef.current.style
            .setProperty('--scale-coef', (0.12 * reverseFromCenterToTopPercent));
        goalContainerRef.current.style
            .setProperty('--scroll-top-offset', (widowHeight * reverseFromCenterToTopPercent) + "px");
        goalContainerRef.current.style
            .setProperty('--animation-percent', (60 * (fromCenterToTopPercent)) + "px");
        //Tag Events
        //El animation
        const windowInnerWidth = window.innerHeight
        for (let el in elRefs.current) {
            const element = elRefs.current[el]
            if (element) {
                const rects = element.getBoundingClientRect();
                if ((rects.height + rects.y) < windowInnerWidth) {
                    element.classList.add(style.show)
                    if (el > 0) {
                        switch (Number(el)) {
                            case 1: {
                                const rects0 = elRefs.current[0].getBoundingClientRect()
                                goalTraderHeight.current = rects0.height + 80
                                document.querySelector("." + clx(style["goalSection"])).style
                                    .setProperty('--goal-traker-height', goalTraderHeight.current + "px")
                                break;
                            }
                            case 2: {
                                const rects1 = elRefs.current[1].getBoundingClientRect()
                                goalTraderHeight.current = goalTraderHeight.current + rects1.height + 80
                                document.querySelector("." + clx(style["goalSection"])).style
                                    .setProperty('--goal-traker-height', goalTraderHeight.current + "px")
                                break;
                            }
                            case 3: {
                                const rects2 = elRefs.current[2].getBoundingClientRect()
                                goalTraderHeight.current = goalTraderHeight.current + rects2.height + 80
                                document.querySelector("." + clx(style["goalSection"])).style
                                    .setProperty('--goal-traker-height', goalTraderHeight.current + "px")
                                break;
                            }
                            case 4: {
                                const rects3 = elRefs.current[3].getBoundingClientRect()
                                goalTraderHeight.current = goalTraderHeight.current + rects3.height + 60
                                document.querySelector("." + clx(style["goalSection"])).style
                                    .setProperty('--goal-traker-height', goalTraderHeight.current + "px")
                                break;
                            }
                        }
                    } else {
                        document.querySelector("." + clx(style["goalSection"])).style
                            .setProperty('--goal-traker-height', "0px")
                    }
                } else {
                    element.classList.remove(style.show)
                }


            }
        }
 
    }

    function getFromCenterToTopPercent(el) {
        const viewportHeight = window.innerHeight;
        const targetHeight = viewportHeight / 2;
        const elRect = el ? el.getBoundingClientRect() : false
        let value = elRect && (targetHeight >= elRect.y) ? (elRect.y >= 0 ? elRect.y / targetHeight : (fromCenterToTopProcess.current ?? 1)) : 1
        value = elRect.bottom < viewportHeight ? 1 - (elRect.bottom / viewportHeight) : value
        //Reverse value
        fromCenterToTopProcess.current = value
        return fromCenterToTopProcess.current;
    }

    //End Functions
    useScrollPosition(handleScroll)

    React.useEffect(() => {
        if (goalContainerRef.current) {
            goalContainerOffsetRef.current = window.innerWidth - goalContainerRef.current.getBoundingClientRect().width
        }

    }, [goalContainerRef.current])


    return (
        <div className={clx(style.goalWrapper)} >
            <div
                className={clx(style.goalSection)}
                ref={goalContainerRef}
            >
                <div
                    className={clx(style.goalContainer)}

                >
                    <div
                        className={style.titleWrapper}
                        ref={titleWrapperRef}
                    >
                        <div 
                            ref={titleContainerElRef}
                            className={style.titleContainerTopWrapper}
                        >
                            <div
                                className={style.titleContainer}
                                ref={titleContainerRef}
                            >
                                <h1 className="bold">
                                    <span>{t("ambassador-page.goal-section.title-1")}</span><br /><span>{t("ambassador-page.goal-section.title-2")} </span>
                                </h1>
                                <div>
                                    <Button className={""} ariaLabel="ambassador">
                                        <span className=" ">{t("ambassador-page.goal-section.cta-button")}</span>
                                        <span
                                            className="move"
                                            dangerouslySetInnerHTML={{
                                                __html: `
                                        <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 4.79283C1.30964 4.79283 0.75 5.35247 0.75 6.04283C0.75 6.73318 1.30964 7.29283 2 7.29283V4.79283ZM18.6087 7.29283C19.2991 7.29283 19.8587 6.73318 19.8587 6.04283C19.8587 5.35247 19.2991 4.79283 18.6087 4.79283V7.29283ZM18.6087 3.66393H17.3587H18.6087ZM21.2827 2.34785L20.5202 3.33835V3.33835L21.2827 2.34785ZM21.2827 9.73781L20.5202 8.7473L21.2827 9.73781ZM18.6087 8.42173H19.8587H18.6087ZM24.373 7.35891L25.1355 8.34942L24.373 7.35891ZM24.373 4.72675L25.1355 3.73624V3.73624L24.373 4.72675ZM2 7.29283H18.6087V4.79283H2V7.29283ZM23.6105 6.3684L20.5202 8.7473L22.0452 10.7283L25.1355 8.34942L23.6105 6.3684ZM19.8587 8.42173V3.66393H17.3587V8.42173H19.8587ZM20.5202 3.33835L23.6105 5.71725L25.1355 3.73624L22.0452 1.35734L20.5202 3.33835ZM19.8587 3.66393C19.8587 3.32297 20.25 3.13037 20.5202 3.33835L22.0452 1.35734C20.1311 -0.116135 17.3587 1.24837 17.3587 3.66393L19.8587 3.66393ZM20.5202 8.7473C20.25 8.95528 19.8587 8.76269 19.8587 8.42173L17.3587 8.42173C17.3587 10.8373 20.1311 12.2018 22.0452 10.7283L20.5202 8.7473ZM25.1355 8.34942C26.6491 7.18419 26.6491 4.90147 25.1355 3.73624L23.6105 5.71725C23.8241 5.88173 23.8241 6.20393 23.6105 6.3684L25.1355 8.34942Z" fill="white"/>
                                        </svg>
                                        
                                        `
                                            }}
                                        ></span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.goals}>
                        {
                            Array.from(new Array(5)).map((_, i) => (
                                <Goal
                                    ref={ref => {
                                        elRefs.current[i] = ref
                                    }}
                                    color={colors[i]}
                                    textColor={i === 2 ? "#23253D" : "#fff"}
                                    className={style["show-fade-bottom"]}
                                    key={i}
                                    style={{
                                        "--animation-duration": "200ms",
                                        "--animation-delay": "50ms"
                                    }}
                                    index={i + 1}
                                    title={t(`ambassador-page.goal-section.goals.${i + 1}`)}
                                />
                            ))
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}