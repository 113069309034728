import React from "react";
import { Banner } from "../components/pages/services/Banner";
import { CTASection } from "../components/pages/services/CTASection";
import { Layout } from "../components/shared/Layout";

export default function Services() {
    return ( 
        <Layout>
            <div 
                className="container small-max-width" >
                <Banner/>
                <CTASection />
            </div>
        </Layout>
    )
}