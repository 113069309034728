import { clx } from "../../../helpers/components"
import { Text } from "../Text"
import style from "./style.module.scss"
export const Checkbox = ({ className, checked, id, label, labelProps,onChange, ...props }) => {
    return (
        <>
            <div className={clx(style.checkboxContainer,  checked && style.checked)}>
                <div className={clx(style.checkbox, checked && style.checked)}>
                    
                    <span className={style.checkIcon}>
                        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="transparent" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </span>
                </div>
                <input type="checkbox" id={id} checked={checked} onChange={onChange instanceof Function ? onChange :()=>{}} {...props} />
            
            </div>
            <label htmlFor={id} {...labelProps}>
                    <Text variant="label">{label}</Text>
                </label>    
                {/* <Text variant="label" {...labelProps}>{label}</Text> */}
                
        </>
    )
}