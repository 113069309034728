import i18next from "i18next";
import { initReactI18next } from "react-i18next"
import BrowserLanguageDetector from "i18next-browser-languagedetector"
import fr_FR from "./assets/locales/fr_FR.json"
import en_EN from "./assets/locales/en_EN.json"

i18next
    .use(initReactI18next)
    .use(BrowserLanguageDetector)
    .init({
        interpolation: { escapeValue: false },  
        fallbackLng: "fr",
        debug:false,
        resources:{
            fr :{
                translations:fr_FR
            },
            en:{
                translations:en_EN
            }
        },
        detection: {
            order: ["navigator"],
            caches: ["localStorage"],
            lookupLocalStorage: "i18nextLng",
            lookupFromPathIndex: 0,
            checkWhitelist: true,
          },
    });
export default i18next;