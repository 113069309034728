import { useTranslation } from "react-i18next"
import { clx } from "../../../../helpers/components"
import { SectionHeader } from "../../../shared/TitleHeader"
import { AppDownloader } from "../../../ui/AppDownloader"
import { Button } from "../../../ui/Button"
import style from "./style.module.scss"
import { useNavigate } from "react-router-dom"

export function BannerCTA() {

    const { t } = useTranslation("translations")
    const navigate=useNavigate()

    return (
        <div className="container ">
            <div className={clx("py-6rem")}>
                <div className={clx(style.bannerCta, "my-30")}>
                    <div className={style.leftContainer}>
                        <SectionHeader
                            title={t("home-page.cta.title")}
                        />
                        <p>
                            {t("home-page.cta.description")}
                        </p>
                        <div>
                            <Button className={clx(style.button)} ariaLabel={t("home-page.cta.btnText")} onClick={()=>navigate("/sign-up")}>
                                {t("home-page.cta.btnText")}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                    <svg width="20" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 4.79283C1.30964 4.79283 0.75 5.35247 0.75 6.04283C0.75 6.73318 1.30964 7.29283 2 7.29283V4.79283ZM18.6087 7.29283C19.2991 7.29283 19.8587 6.73318 19.8587 6.04283C19.8587 5.35247 19.2991 4.79283 18.6087 4.79283V7.29283ZM18.6087 3.66393H17.3587H18.6087ZM21.2827 2.34785L20.5202 3.33835V3.33835L21.2827 2.34785ZM21.2827 9.73781L20.5202 8.7473L21.2827 9.73781ZM18.6087 8.42173H19.8587H18.6087ZM24.373 7.35891L25.1355 8.34942L24.373 7.35891ZM24.373 4.72675L25.1355 3.73624V3.73624L24.373 4.72675ZM2 7.29283H18.6087V4.79283H2V7.29283ZM23.6105 6.3684L20.5202 8.7473L22.0452 10.7283L25.1355 8.34942L23.6105 6.3684ZM19.8587 8.42173V3.66393H17.3587V8.42173H19.8587ZM20.5202 3.33835L23.6105 5.71725L25.1355 3.73624L22.0452 1.35734L20.5202 3.33835ZM19.8587 3.66393C19.8587 3.32297 20.25 3.13037 20.5202 3.33835L22.0452 1.35734C20.1311 -0.116135 17.3587 1.24837 17.3587 3.66393L19.8587 3.66393ZM20.5202 8.7473C20.25 8.95528 19.8587 8.76269 19.8587 8.42173L17.3587 8.42173C17.3587 10.8373 20.1311 12.2018 22.0452 10.7283L20.5202 8.7473ZM25.1355 8.34942C26.6491 7.18419 26.6491 4.90147 25.1355 3.73624L23.6105 5.71725C23.8241 5.88173 23.8241 6.20393 23.6105 6.3684L25.1355 8.34942Z" fill="#3257A8"/>
                                    </svg>
                                `
                                    }}
                                ></span>
                            </Button>
                        </div>
                        <div>
                            <AppDownloader />
                        </div>
                    </div>
                    <div className={style.rightContainer}>
                        <div className={style.imgContainer}>
                            <img src="/images/phone_5.webp" width='100%' height='98%' alt="Crypto form Start" className={style.imgContent}/>
                            <span className={style.sym}
                                dangerouslySetInnerHTML={{
                                    __html: `
                                            <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M65.3292 21.1308C58.3016 18.3573 51.3611 15.1603 42.4888 18.635C35.1992 21.4899 27.1227 28.9469 32.2245 33.7467C38.7647 39.8999 45.9101 31.5794 43.6344 26.5664C41.4169 21.6815 31.3595 20.2369 25.3945 25.0608C19.0006 30.2316 13.4659 43.0047 12.6757 57.3452" stroke="#008080" stroke-width="1.70082" stroke-linecap="round"/>
                                            <path d="M10.168 55.8962L12.608 58.3506L14.9601 56.0122" stroke="#008080" stroke-width="1.70082" stroke-linecap="round"/>
                                            <path d="M63.3062 23.0327L66.3166 21.3254L64.6803 18.4404" stroke="#008080" stroke-width="1.70082" stroke-linecap="round"/>
                                            </svg>
                                            
                                            `
                                }}
                            ></span>
                            <img className={style.cryptos_cta} src="/images/cryptos_cta.png" alt="Crypto form" />
                        </div>
                        <div className={style.note}>
                            <div>
                                <div className={style.imgsCaps}>
                                    <img src="/images/p_2.png" alt="Customer Image 1" width='100%' height ='98%' />

                                    <img src="/images/p_6.png" alt="Customer Image 2" width='100%' height ='98%' />
                                </div>
                            </div>
                            <div className={style.noteDescripton}>
                                <h6>{t("home-page.cta.note.title")}</h6>
                                <p>{t("home-page.cta.note.description")}</p>
                            </div>
                            <div className={style.checknote}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70383 19.8201C14.9876 19.8201 19.2709 15.5367 19.2709 10.253C19.2709 4.96925 14.9876 0.685913 9.70383 0.685913C4.42006 0.685913 0.136719 4.96925 0.136719 10.253C0.136719 15.5367 4.42006 19.8201 9.70383 19.8201ZM13.0863 6.87054C13.46 6.49692 14.0657 6.49692 14.4394 6.87054C14.813 7.24416 14.813 7.84992 14.4394 8.22353L9.03088 13.6319L9.02734 13.6355C8.93071 13.7321 8.81859 13.8038 8.6989 13.8504C8.52937 13.9165 8.34482 13.9324 8.16812 13.8982C7.9872 13.8632 7.81442 13.7756 7.67434 13.6355L7.67071 13.6318L4.96838 10.9295C4.59475 10.5559 4.59475 9.95013 4.96838 9.57653C5.34199 9.20294 5.94775 9.20294 6.32137 9.57653L8.35085 11.606L13.0863 6.87054Z" fill="#008080"/>
                                            </svg>
                                        
                                        `
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}