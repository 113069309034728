import { clx } from "../../../../helpers/components"
import style from "./style.module.scss"

export function PageTitle({title}){
    // var dc = document.querySelector('.ftMobile') 
    return(
        // <>
        //     <section>
                <div className={style.containerBanner}>
                    <div className={clx("container", style.bannerTitle)}>
                        <h2>{title}</h2>
                    </div>
                </div>
        //     </section>
        // </>
    )
}