import { useTranslation } from "react-i18next"
import style from "./style.module.scss"
export function Banner() {
    const {t}=useTranslation("translations")
    return (
        <div className={style.banner}>
            <div className={style.leftContainer}>
                <div>
                    <h6>{t("aboutus-page.subtitle")}</h6>
                    <h2>
                    {t("aboutus-page.title-1")} <br />{t("aboutus-page.title-2")}
                    </h2>
                    <p>
                        {t("aboutus-page.description")}
                    </p>
                </div>
                <div>

                </div>
            </div>
            <div className={style.rightContainer}>
                <div className={style.rightContentContainer}>
                    <div style={{width: '100%', height: '230px'}}>
                        <img src="/images/interface_mob.png" alt="Application Interface de choix de moyens de paiments" width="100%" height="100%" />
                    </div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `
                        <svg width="196" height="118" viewBox="0 0 196 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M191.283 81.2122C180.476 62.4256 170.622 42.845 144.505 34.7778C123.048 28.1497 91.5969 30.439 94.4608 50.3281C98.1322 75.8251 129.038 69.934 132.895 54.6193C136.654 39.6959 116.674 18.4738 94.6677 18.6312C71.0791 18.7999 35.8261 37.5599 8.41462 68.3183" stroke="#008080" stroke-opacity="0.15" stroke-width="4" stroke-linecap="round"/>
                            <path d="M182.04 80.8604L191.846 82.4122L193.333 73.0148" stroke="#008080" stroke-width="3" stroke-linecap="round"/>
                        </svg>

                        `
                        }}
                    >

                    </span>
                    <div style={{width: '100%', height:'230px'}}>
                        <img src="/images/interface_mob_1.png" alt="Application Interface d'achat et vente" width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    )
}