import { clx } from "../../../helpers/components"
import { Link } from "../../ui/Link"
import { Text } from "../../ui/Text"
import style from "./style.module.scss"
export const SectionHeader = ({ title, subtitle, description, position = "left", className /* center */ }) => {
    return (
        <div className={clx(style.titleSection, style[position], className)}>
            {
                subtitle && (
                    <Text variant="subtitle" color="color-primary">
                        {subtitle}
                    </Text>
                )
            }

            {
                title && (
                    <Text variant="h3" >
                        {title}
                    </Text>
                )
            }
            {
                description && (
                    <Text variant="h6">
                        {description}
                    </Text>
                )
            }
        </div>
    )
}

export function SectionHeaderLink({ href, title, subtitle, description, showIcon, linkShowIcon, linkLTitle, LinkProps, position = "left", SectionHeaderProps, className, ...props }) {
    return (
        <div className={clx(style.titleLink, className)}{...props}>
            <SectionHeader
                title={title}
                subtitle={subtitle}
                description={description}
                position={position}
                {...SectionHeaderProps}
            />
            {
                linkLTitle && (
                    <Link showIcon={linkShowIcon} title={linkLTitle} {...LinkProps} />
                )
            }

        </div>
    )
}
