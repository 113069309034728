import { Text } from "../Text"
import style from "./style.module.scss"
export const CTABoxIcon = ({bgColor, iconColor, title, description, tileIcon, onClick}) => {
    const handleRedirect = () => {
        onClick({title})
    }
    return (
        <div className={style.cta} onClick={handleRedirect}
            style={{
                ["--izi-cta-bg-color"]: bgColor ?? "var(--izi-bg-color)",
                ["--izi-cta-icon-color"]: iconColor ?? "var(--izi-primary-color)"
            }}
        >
            <div className={style.headIcon}>
                {
                    tileIcon  
                }
            </div>
            <div className={style.containerContent}>
                <Text variant="h5">{title}</Text>
                <Text variant="p">
                    {description}
                </Text>
                <div>
                    <svg width="41" height="17" viewBox="0 0 41 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 7.25C1.30964 7.25 0.75 7.80964 0.75 8.5C0.75 9.19035 1.30964 9.75 2 9.75V7.25ZM28.6943 9.75C29.3846 9.75 29.9443 9.19035 29.9443 8.5C29.9443 7.80964 29.3846 7.25 28.6943 7.25V9.75ZM28.6943 4.67524H27.4443H28.6943ZM32.9921 2.55926L32.2294 3.54965V3.54965L32.9921 2.55926ZM32.9921 14.4407L32.2294 13.4504L32.9921 14.4407ZM28.6943 12.3248H29.9443H28.6943ZM37.9589 10.616L38.7216 11.6064L37.9589 10.616ZM37.9589 6.38402L38.7216 5.39364V5.39364L37.9589 6.38402ZM2 9.75H28.6943V7.25H2V9.75ZM37.1963 9.62559L32.2294 13.4504L33.7547 15.4311L38.7216 11.6064L37.1963 9.62559ZM29.9443 12.3248V4.67524H27.4443V12.3248H29.9443ZM32.2294 3.54965L37.1963 7.37441L38.7216 5.39364L33.7547 1.56888L32.2294 3.54965ZM29.9443 4.67524C29.9443 3.49589 31.2966 2.83136 32.2294 3.54965L33.7547 1.56888C31.1768 -0.416258 27.4443 1.42271 27.4443 4.67524H29.9443ZM32.2294 13.4504C31.2966 14.1686 29.9443 13.5041 29.9443 12.3248H27.4443C27.4443 15.5773 31.1768 17.4163 33.7547 15.4311L32.2294 13.4504ZM38.7216 11.6064C40.7595 10.037 40.7595 6.96295 38.7216 5.39364L37.1963 7.37441C37.9346 7.94296 37.9346 9.05704 37.1963 9.62559L38.7216 11.6064Z" fill="#008080" />
                    </svg>

                </div>
            </div>
        </div>
    )
}