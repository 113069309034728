import { A2fForm } from "../components/shared/AuthForm/A2f"
import { BasicLayout } from "../components/shared/Layout"
import { RecaptchaProvider } from "../components/shared/RecaptchaProvider"


export const A2f = () => {
    return (
        <RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <A2fForm />
            </BasicLayout>
        </RecaptchaProvider>


    )
}