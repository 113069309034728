import { Banner } from "../components/pages/apropos/Banner";
import { CTASection } from "../components/pages/apropos/CTASection";
// import { Teams } from "../components/pages/apropos/Team";
import { Layout } from "../components/shared/Layout";

export function Apropos() {
    return (
        <Layout>
            <div className="container-content">
                <Banner/>
                {/* <Teams/> */}
                <CTASection/>
            </div>
           
        </Layout>
    )
}