import "./assets/styles/style.scss"
import 'swiper/css'
import "particles.js"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Login } from "./pages/Login";
import { A2f } from "./pages/A2f";
import { Twofactor } from "./pages/Twofactor";
import Services from "./pages/Services";
import { SignUp } from "./pages/SignUp";
import { Acceuil } from "./pages/Acceuil";
import { Apropos } from "./pages/Apropos";
import { FAQ } from "./pages/FAQ";
import { Ambassador } from "./pages/Ambassador";
import { Parteners } from "./pages/Partners";
import { PartnersForm } from "./pages/PartnersForm";
import { AmbassadorFormPage } from "./pages/AmbassadorForm";
import { CookiesPolicyPage, GeneralConditionsPage, LexiquePage, MentionLegalesPage, WarningPage, PolitiqueConfPage } from "./pages/Conditions";
import { EmailConfirmation } from "./pages/EmailConfirmation";
import { AppDowload } from "./pages/AppDownload";
import { Disconnect } from "./pages/Disconnect";
import { ForgotPass } from "./pages/ForgotPassword";
import { DroitClient } from "./pages/DroitClient";
import { BandeauBasPage } from './components/pages/acceuil/CookieBanderole';
import {CafeCrypto} from './pages/CafeCrypto';
import { SpeakerForm } from "./pages/SpeakerForm";


function App() {
  const urlParams = new URLSearchParams(window.location.search.split('?')[1]);
  if(urlParams.get('token'))
    localStorage.setItem('csrf', urlParams.get('token'));

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/disconnect" element={<Disconnect />} />
          <Route path="/services" element={<Services />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ForgotPass />} />
          <Route path="/a2f" element={<A2f />} />
          <Route path="/check_a2f" element={<Twofactor />} /> 
          <Route path="/a-propos" element={<Apropos />} />
          <Route path="/vos-droits" element={<DroitClient />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/new-partner" element={<PartnersForm />} />
          <Route path="/ambassadors" element={<Ambassador />} />
          <Route path="/partners" element={<Parteners />} />
          <Route path="/new-ambassador" element={<AmbassadorFormPage />} />
          <Route path="/general-conditions" element={<GeneralConditionsPage />} />
          <Route path="/politique-confidentialite" element={<PolitiqueConfPage />} />
          <Route path="/lexical" element={<LexiquePage />} />
          <Route path="/cookies" element={<CookiesPolicyPage />} />
          <Route path="/legal-mentions" element={<MentionLegalesPage />} />
          <Route path="/warning" element={<WarningPage />} />
          <Route path="/email-confirmation" element={<EmailConfirmation />} />
          <Route path="/" element={<Acceuil />} />
          <Route path="/download_app" element={<AppDowload />} />
          <Route path="/cafe_crypto" element={<CafeCrypto />} />
          <Route path="/new-speaker" element={<SpeakerForm />} />

        </Routes>
      </BrowserRouter> 
      <BandeauBasPage /> 
    </div>

  );
}

export default App;
