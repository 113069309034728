import PropTypes from "prop-types"
import React from "react"
import { clx, getVariableColor } from "../../../helpers/components"
import { LoaderSpinner } from "../Spinner"
import style from "./style.module.scss"

export const Button =React.forwardRef( ({
    children,
    disabled,
    style: buttonStyle,
    color = "#fff",
    variant = "contained",
    bgColor = "izi-primary-gradient-color",
    className,
    ariaLabel = "button",
    loading = false,
    ...props
}, ref) => {
    return (
        <button
            ref={ref}
            style={{
                ["--izi-button-color"]: getVariableColor(color),
                ["--izi-button-bg-color"]: getVariableColor(bgColor),
                ...(disabled ? { opacity: 0.5 } : {}),
                ...buttonStyle
            }}
            className={clx(
                style.button,
                variant === "outlined" ? style.outlined :style.normal,
                variant === "contained" ? style.contained:style.normal,
                variant === "cleared" ? style.cleared:style.normal,
                loading && style.loading,
                className
            )}
            {...(disabled || loading ? { disabled: "disabled" } : {})}
            {...props}
            aria-label={ariaLabel}
        >
            {
                loading ?
                <div className={style.children}>{children}</div>
                :
                children
            }
            {
                loading && (
                    <div className={style.loader}>
                        <LoaderSpinner />
                    </div>
                )
            }

        </button>
    )
})

Button.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    variant: PropTypes.oneOf(["outlined", "contained", "cleared"]),
    className: PropTypes.string,
    ariaLabel: PropTypes.string
}

