import React, {useState, useRef} from 'react';
import { clx } from "../../../../helpers/components";
import { Button } from "../../../ui/Button";
import bannerStyle from "../Banner/style.module.scss";
import style from "./style.module.scss"
import { useTranslation } from "react-i18next";
/* import { ModalContainer } from '../../../ui/ModalContainer'; */
import { MdClose } from "react-icons/md";
import { TextField } from "../../../ui/TextField"
import axios from "axios"
import { BASE_URL } from '../../../../helpers/func';

export function CTASection() {
    const {t, i18n}=useTranslation("translations")
    const [open, setOpen] = React.useState(null)
    const [inputData, setInputData] = useState('')
    // const [error, setError] = useState('');
    const [loading, setLoading] = React.useState(false)
    const [selectedFileName, setSelectedFileName] = useState('');
    
    // const handleBtn = () => {
    //     setOpen(true)

    // }
    const handleClose = () => {
        setOpen(null)
        setInputData('')
        handleFileChange('')
    }

    const handleFileChange = (selectedFile) => {
        setSelectedFileName(selectedFile.name);
        // Faites autre chose avec le fichier si nécessaire.
    };

    const handleSubmit = () => {
        if (selectedFileName && inputData) { 
                sendData(inputData, selectedFileName)
        }
    }

    const sendData = (email, fileContent) => {
        const params = new URLSearchParams();

        params.append('email', email); 
        params.append('fileContent', fileContent);
        setLoading(true)

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'APPVERSION': "2.0.1"
            },
            data: params,
            url: BASE_URL+"/api/pu_verification_code"
        };

        axios(options)
            .then(({ data }) => {
                if (data.succes) {
                    setLoading(true)
                }
            })
            .catch(e => { })
            .finally(() => setLoading(false))
    }
      
    return (
        <div className={bannerStyle.banner}>
            <div className={bannerStyle.leftContainer}>
                <div>
                    <h2>
                        {t("aboutus-page.cta-section.title-1")} <br />{t("aboutus-page.cta-section.title-2")}
                    </h2>
                    <p>
                    {t("aboutus-page.cta-section.description")}
                    </p> 
                </div>
                <p className={style.top}>
                    <span className={style.sendData}>{t('home-page.cta-section.sendFile')}: <a href='mailto:info@izichange.com' aria-label="mailto:info@izichange.com" target="_blank" rel="noreferrer"><b>info@izichange.com</b></a></span>
                </p>
                 
                
            </div> 
            <div className={clx(bannerStyle.rightContainer, style.imgSection)} style={{width:'100%', height:'80%'}}>
                <img src="/images/Work_with_izichange.png" alt="Employé Souriant"width="100%" height="100%" />
            </div>
        </div>
    )
} 

export const CustomFileInput = ({ onChange }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
        onChange(selectedFile);
    } else {
        alert("Veuillez sélectionner un fichier PDF.");
    }
  };

  return (
    <div className={style.customFileInput}>
      <div className={style.fileInputButton} onClick={handleButtonClick}>
        <span className={style.icon}>📁</span> Choisissez un fichier
      </div>
      <input
        ref={fileInputRef}
        type="file"
        className={style.hiddenInput}
        accept=".pdf"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default CustomFileInput;
