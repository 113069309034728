import { useTranslation } from "react-i18next"
import { clx } from "../../../helpers/components"
import { Button } from "../../ui/Button"
// import { PartenerSectionTitle } from "../../ui/PartenerSectionTItle"
// import { TextField } from "../../ui/TextField"
import style from "./style.module.scss"
// import ReactDOM from "react-dom"
import React, {useState, useEffect} from "react"
import { ModalContainerADP } from "../../ui/ModalContainer"
import { ProgressSpeakerForm } from "../../shared/ProgressSpeakerForm"
import axios from "axios"
import { BASE_URL } from "../../../helpers/func"

export function SpeakerFormular() {

    const { t, i18n } = useTranslation("translations")
    // const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)
    const [selectedButton, setSelectedButton] = useState(null)
    
  // Gestionnaire de clics pour les boutons
    const handleButtonClick = (buttonValue) => { 
        setSelectedButton(buttonValue);
    };

    useEffect(() => {
        const CheckAndHideFooter = () => {
            // const notifExist = localStorage.getItem('notif')
            const ftElement = document.querySelector('#preloader');
            const footers = document.getElementById('footerCustom');
            // if(notifExist || !notifExist) {
            if (ftElement || footers) { 
                if(ftElement)
                    ftElement.style.display = 'none';
                if (footers) 
                    footers.style.display = 'none'; 
            }
                
            // } 
        }
        
        CheckAndHideFooter();
        
    }, [])

    
    
    const stepLen = 3
    const form = {
        name: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "text",
            label: ("speakers.speakersForm.name.label"),
            placeholder: ("speakers.speakersForm.name.placeholder")
        },
        email: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty", "email"],
            step: 1,
            type: "text",
            label: ("speakers.speakersForm.email.label"),
            placeholder: ("speakers.speakersForm.email.placeholder")
        },
        country: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "country-select",
            label: ("partners.partnersForm.country.label"),
            placeholder: ("partners.partnersForm.country.placeholder")
        },
        anneeExperience: {
            error:false,
            isValid: true,
            errorMessage: "",
            validators: ["notSelect"],
            step: 2,
            type:"crypto-experience",
            label: ("speakers.speakersForm.selectButton.label"),
            placeholder:("speakers.speakersForm.selectButton.placeholder")
        },
        whatsappNumber: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 1,
            type: "tel",
            label: ("speakers.speakersForm.phone.label"),
            placeholder: ("speakers.speakersForm.phone.placeholder")
        },
        question1: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "textfield",
            label: ("speakers.speakersForm.questions.1.label"),
            placeholder: ("speakers.speakersForm.questions.1.placeholder"),
        },
        question2: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 2,
            type: "textfield",
            label: ("speakers.speakersForm.questions.2.label"),
            placeholder: ("speakers.speakersForm.questions.2.placeholder"),
        },
        question3: {
            value: "",
            error: false,
            isValid: true,
            errorMessage: "",
            validators: ["notEmpty"],
            step: 3,
            type: "textfield",
            label: ("speakers.speakersForm.questions.3.label"),
            placeholder: ("speakers.speakersForm.questions.3.placeholder"),
        },

    }
    //const imgSrc = "/images/speaker_image.png" 
    const handleSubmit = (form, setLoading, setSuccessModal, setOpenErrorModal,reset) => {
        const params = new URLSearchParams();

        function base64EncodeUnicode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => 
                String.fromCharCode('0x' + p1)
            ));
        }
        
        const datas = {
            "lastname": form.name.value,
            "tel": form.whatsappNumber.value,
            "email": form.email.value,
            "additionnalFields": JSON.stringify({
                "country": form.country.value,
                "anneeExperience": selectedButton,
                "experienceQuest": form.question1.value,
                "topicQuest": form.question2.value,
                "profilsLink": encodeURIComponent(form.question3.value),
            })
        }

        const encodedData = base64EncodeUnicode(JSON.stringify(datas));
        
        // Object.keys(datas).forEach((key) => {
        //     params.append(key, datas[key]);
        // })

        params.append("data", encodedData);

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'appversion':"2.0.1"
            },
            withCredentials: true, // Include cookies in the request
            data: params,
            url: BASE_URL+"/api/pu_new/speakers"
        };

        setLoading(true);

        axios(options).then(({ data }) => {   
                if(data.status==201){ 
                    reset();
                    setSuccessModal(true);
                }
                else{

                    setOpenErrorModal("global-form.errors.submission-error-retry")
                }
            })
            .catch((error) => {
                setOpenErrorModal("global-form.errors.submission-error-retry")
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <ProgressSpeakerForm key={"speaker"} 
                defaultForm={form}
                stepLen={stepLen}
                handleButtonClick = {handleButtonClick}
                imgAlt={t("global-form.errors.should-fill-all-field")}
                successModalMessage={t("speakers.speakersForm.successModal.title")}
                successModalBtnTitle={t("speakers.speakersForm.successModal.btnTitle")}
                onSubmit={handleSubmit} 
                selectedButton = {selectedButton}
                nextBtnLabel={t("partners.partnersForm.nextBtn")}
                prevBtnLabel={t("partners.partnersForm.prevBtn")}
            />
        </>
    )
}
