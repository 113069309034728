import { clx } from "../../../helpers/components";
import { Text } from "../Text";
import style from "./style.module.scss";
import "./intel.scss";
import React, { useMemo } from "react";
import RSelect, { components } from "react-select"
import { IntelInput } from "./IntelInput";
import { COUNTRIES, getCountryFlagURL } from "../../../helpers/coutriesHelper";
import { useTranslation } from "react-i18next";

export const TextField = ({
    className,
    containerProps,
    label,
    labelProps,
    variant="contained",
    endAdornment,
    error,
    errorMessage:message ,
    ...props
}) => {
    const {t}=useTranslation("translations");
    let errorMessage=message ?? t("global-form.errors.invalid-field")
    return (
        <div style={{
            ["--izi-border-color"]: "var(--izi-primary-color)",
            ...style
        }} className={clx(style.formContainer, style[variant], error && style.error)}>
            <Text {...labelProps}>{label}</Text>
            <div
                className={clx(style.inputContainer, className)}

                {...containerProps}
            >

                <input type="text" {...props} />
                {
                    endAdornment && (
                        <span>
                            {endAdornment}
                        </span>
                    )
                }

            </div>
            {
                error && (
                    <Text className={style.errorMessage}>{errorMessage}</Text>
                )
            }
        </div>
    )
}

export const PhoneField = ({
    className,
    containerProps,
    label,
    error,
    onChange,
    onPhoneNumberBlur,
    labelProps,
    endAdornment = null,
    errorMessage = "Ce champ est invalide",
    value = "",
    ...props
}) => {
    const { className: containerClass, style: containerStyle, ...container } = containerProps ?? {}


    return (
        <div
            className={clx(style.formContainer, error && style.error, style.phoneInputContainer, containerClass)}
            style={{
                ["--izi-border-color"]: "var(--izi-primary-color)",
                ...containerStyle,

            }}
            {...container}
        >
            <Text {...labelProps}>{label}</Text>
            <div className={clx(style.inputContainer, "telFormContainer")}>
                <IntelInput
                    intlTelInputProps={{
                        customContainer: clx("intl-tel-input", style.formContainer, className)
                    }}
                    onPhoneNumberChange={(_, { isValid, value, country, fullNumber, ext }) => {
                        if (onChange instanceof Function) onChange({ isValid, value, country, fullNumber, ext })
                    }}
                    onPhoneNumberBlur={(_, { isValid, ...props }) => {
                        if (onPhoneNumberBlur instanceof Function) {
                            onPhoneNumberBlur(isValid);
                        }

                    }}

                    value={value}
                    {...props}
                />
                {
                    endAdornment && (
                        <div className={style.endAdornmentContainer}>{endAdornment}</div>
                    )
                }


            </div>
            {
                error && (
                    <Text className={style.errorMessage}>{errorMessage}</Text>
                )
            }

        </div>
    )
}


const RControl = ({ children, className, ...props }) => {
    return (
        <components.Control className={clx(className, style.Control)} {...props}>
            {children}
        </components.Control>
    )
}

const RIndicatorSeparator = () => {
    return (
        <span></span>
    )
}

const RMenu = ({
    children,
    ...props
}) => {
    return (
        <components.Menu className={clx(style.Menu)}  {...props}>
            {children}
        </components.Menu>
    )
}

const ROption = ({
    children,
    ...props
}) => {
    return (
        <div className={clx(style.OptionContainer)}>
            <components.Option className={clx(style.Option)}  {...props}>
                {children}
            </components.Option>
        </div>
    )
}

export const CountrySelectBox = ({
    ...props
}) => {
    const listCountries = useMemo(() => (
        COUNTRIES.map((c, i) => ({ label: c.name, value: c.code, data: c }))
    ), [])
    return (
        <SelectBox
            options={listCountries}
            formatOptionLabel={(option) => (
                <div className={style.options}>
                    <img
                        src={getCountryFlagURL(option.data.code)}
                        alt={option.label}
                    />
                    <span>{option.label}</span>
                </div>
            )}
            {...props}
        />
    )
}


export const SelectBox = ({
    options,
    label,
    labelProps,
    placeholder = "Selectionner une option",
    error,
    errorMessage,
    ...props
}) => {
    return (
        <div
            className={clx(style.selectContainer, error && style.error)}
            style={{
                ["--izi-border-color"]: "var(--izi-primary-color)",
            }}
        >
            <Text {...labelProps}>{label}</Text>
            <RSelect
                className={clx(style.inputContainer, style.selectBox)}
                style={{
                    ["--izi-border-color"]: "var(--izi-primary-color)",
                    ...style
                }}
                styles={{
                    option: (base, props) => {
                        return (props.isFocused ? {
                            ...base,
                            background: "var(--color-primary-light-secondary)",
                            color: "var(--color-dark)"

                        } : (
                            props.isSelected ?
                                {
                                    ...base,
                                    background: "var(--color-primary)",
                                } :
                                {
                                    ...base,
                                }
                        ))
                    }
                }}
                options={options}
                placeholder={placeholder}
                components={{
                    Control: RControl,
                    IndicatorSeparator: RIndicatorSeparator,
                    Menu: RMenu,
                    Option: ROption
                }}
                menuPlacement="auto"
                {...props}
            />
            {
                error && (
                    <Text className={style.errorMessage}>{errorMessage}</Text>
                )
            }
        </div>
    )
}