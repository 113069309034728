import React from "react";
import { clx } from "../../../helpers/components";
import style from "./style.module.scss"

export function AnimatedText({ children, ...props }) {
    if (typeof children === "string") {
        return (
            <AnimateText {...props }>{children}</AnimateText>
        )
    }
    return children

}

function AnimateText({
    children,
    duration = 200,
    delay = 100,
    initialDelay = 0,
    top=20
}) {
    const [key, setKey]=React.useState(0)
    React.useEffect(()=>{
        setKey(prev=>prev ?  0 : 1)
    }, [children])
    return (
        <div key={key} style={{
            display:"inline-flex",
            flexWrap:"wrap"
        }}>
            {
                children.split(" ").map((c, i) => (
                    <p key={i} className={clx(style.animate)} style={{
                        marginRight:10, position:"relative",
                        ["--duration"]:duration+"ms",
                        ["--delay"]:initialDelay+(i * delay )+"ms",
                        ["--top"]:top+"px"
                    }}
                    >
                        {c}
                    </p>
                ))
            }
        </div>
    )
}