import React from "react";
import { AdvantagesSection } from "../components/pages/ambassador/advantagesSection";
import { AmbassadorBanner } from "../components/pages/ambassador/banner";
import { Header } from "../components/shared/Header";
import { BasicLayoutContainerWithoutHeader } from "../components/shared/Layout";
import style from "../components/pages/ambassador/banner/style.module.scss"
import { GoalSection } from "../components/pages/ambassador/goalSection";
import { JoinComunity } from "../components/pages/ambassador/JoinCommunity";

export function Ambassador() {
    React.useEffect(() => {
        CheckAndHideFooter()
    }, [])
    return (

        <BasicLayoutContainerWithoutHeader>
            <>
                <section>
                    <div className={style.bannerHeader}>
                        <Header />
                        <AmbassadorBanner />
                    </div>
                    <AdvantagesSection />
                    <GoalSection/>
                    <JoinComunity/>
                </section>
            </>
        </BasicLayoutContainerWithoutHeader>
    )
}

export const CheckAndHideFooter = () => {
    const notifExist = localStorage.getItem('notif')
    const ftElement = document.querySelector('#preloader');
    if(notifExist || !notifExist) {
        if (ftElement) { 
            ftElement.style.height = '100%';
        }
        
    }
}