import React from 'react';
import { useTranslation } from "react-i18next"
import { getVariableColor } from "../../../../helpers/components"
import { SectionHeader } from "../../../shared/TitleHeader"
import { Text } from "../../../ui/Text"
import style from "./style.module.scss"



  

export const TestimonialSection = () => {

    const { t } = useTranslation("translations")

    return (
        <div className="container">
            <div className={style.testimonialContainer}>
                <SectionHeader
                    title={t("home-page.testimonial-section.title")}
                    position="center"
                />
                <div className={style.testimonialsWrapper}>
                    <div>
                        <ClientTestimonial
                            imgSrc={"/images/testimony_cameroun.webp"}
                            clientName="Fabrice TAKEMLAC"
                            clientCountryName={"Douala Cameroun"}
                            color="#3257A8"
                            bgColor="#3257A81A"
                            message={t("home-page.testimonial-section.testimonial-1")}
                        // clientProfession= "Crypto Tradeur"
                        />
                    </div>
                    <div>
                        <ClientTestimonial
                            imgSrc={"/images/testimony_mali.webp"}
                            clientName="ISSA GUINDO"
                            clientCountryName={"Mali"}
                            color="#DC3F4D"
                            bgColor="#DC3F4D1A"
                            message={t("home-page.testimonial-section.testimonial-2")}
                        // clientProfession= "Enseignant"
                        />
                    </div>
                    <div>
                        <ClientTestimonial
                            imgSrc={"/images/testimony_senegal.webp"}
                            clientName="Matou Lahi"
                            clientCountryName={"Sénégal"}
                            color="color-dark"
                            bgColor="#FEFF8333"
                            message={t("home-page.testimonial-section.testimonial-3")}
                        // clientProfession= "Passionné des Cryptos, le Metaverse et l’IA "
                        />
                    </div>
                    <div>
                        <ClientTestimonial
                            imgSrc={"/images/testimony_niger.webp"}
                            clientName="Malik Al-ashtar Hamissou"
                            clientCountryName={"Niger"}
                            color="#008080"
                            bgColor="#0080801A"
                            message={t("home-page.testimonial-section.testimonial-4")}
                        // clientProfession= "Bloggeurs"
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export const ClientTestimonial = ({
    imgSrc,
    message,
    clientName,
    clientCountryName,
    color,
    bgColor,
    // clientProfession,
}) => {
    const { t } = useTranslation("translations")
    return (
        <div
            className={style.testimonial}
            style={{
                ["--izi-testimonial-color"]: getVariableColor(color),
                ["--izi-bg-color"]: getVariableColor(bgColor),
            }}
        >
            <div className={style.imageContainer}>
                <img src={imgSrc} alt={t("home-page.testimonial-section.clientTitle") + clientName}/>
            </div>
            <div className={style.contentContainer}>
                <div>
                    <Text variant="h6">
                        {message}
                    </Text>
                </div>

                {/* <div className={style.BlocprofessionStyle}>
                    <Text variant="span" className={style.professionStyle}>{clientProfession}</Text>
                </div> */}

                <div>
                    <div className={style.testimonialFooter}>
                        <Text>{clientName}</Text>
                        <span></span>
                        <Text> {clientCountryName}</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}