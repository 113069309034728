import { PartnersFormular } from "../components/pages/partnersForm";
import { BasicLayout } from "../components/shared/Layout";

/*
    "partnersForm":{
        "title":"Formulaire de demande ",
        "name":{
            "label":"Nom",
            "placeholder":"Entrez votre nom"
        },
        "firstname":{
            "label":"Nom",
            "placeholder":"Entrez votre prénom"
        } ,  
        "companyName":{
            "label":"Nom de votre entreprise  (Facultatif)",
            "placeholder":"Ex: Izichange"
        } ,
        "country":{
            "label":"Pays de residence",
            "placeholder":"Entrez votre pays de résidence"
        } ,
        "email":{
            "label":"Votre Email",
            "placeholder":"Entrez votre email"
        },
        "workDomain":{
            "label":"Domaine d’activité",
            "placeholder":"Ex: Cryptomonnaies, Finances"
        } ,
        "otherSource":{
            "label":"Autres sources de revenus (facultatif)",
            "placeholder":"Ex: NFT, Trading"
        },
        "transaction":{
            "label":"Volume de transaction moyenne par mois",
            "placeholder":"Combien faites vous de ventes par moi ?"
        },
        "whatsappNumber":{
            "label":"Numéros whatsapp",
            "placeholder":"Entrez votre numéro whatsapp"
        }
    }
*/

export function PartnersForm() {
    return (
        <BasicLayout >
            <PartnersFormular/>
        </BasicLayout >
    )
}