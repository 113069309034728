import style from "./style.module.scss"
import { SectionHeader } from "../../../shared/TitleHeader"
import { useTranslation } from "react-i18next"
import { AppDownloader } from "../../../ui/AppDownloader"

export const CTASection = () => {
    const { t } = useTranslation("translations")
    return (
        <div className="container">
            <div className={style.containerWrapper}>
                <div className={style.container}>
                    <div className={style.descriptionContainer}>
                        <SectionHeader
                            subtitle={<>{t("home-page.cta-section.subtitle")}</>}
                            title={<>{t("home-page.cta-section.title")}<br />{t("home-page.cta-section.title_1")}</>}
                        />
                        <div className={style.qr}>
                            <AppDownloader color="var(--color-blue-dark)" />
                        </div>
                    </div>
                    <div className={style.wrapper}>
                        <div className={style.imgContainer}>
                            <div className={style.img1}>
                                <img src="/images/app4.png" alt={t("home-page.cta-section.appInPhoneImgAlt-1")} width='100%' height='100%' />
                            </div>
                            <div className={style.img2}>
                                <img src="/images/app3.png" alt={t("home-page.cta-section.appInPhoneImgAlt-2")} width='100%' height='100%'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}