import { useTranslation } from "react-i18next"
import { clx, IntersectionWatcher } from "../../../../helpers/components"
import style from "./style.module.scss"

export function Conditions() {

    const { t } = useTranslation("translations")

    return (
        <div className={clx(style.wrapper, "container")}>
            <div className={style.conditionContainer}>
                <IntersectionWatcher onlyFirstIntersection>
                    {
                        ({ ref, isIntersecting }) => (
                            <h3
                                style={{
                                    "--animation-duration": "400ms",
                                    "--animation-delay": "1400ms"
                                }}
                                ref={ref} className={clx(style["show-fade-left"], isIntersecting && style.show)}>{t("partners.conditions.title")}</h3>
                        )
                    }
                </IntersectionWatcher>
                <IntersectionWatcher onlyFirstIntersection>
                    {
                        ({ ref, isIntersecting }) => (
                            <div className={style.hideMd} style={{width:'100%', height: '40%'}}>
                                <img
                                    style={{
                                        "--animation-duration": "400ms",
                                        "--animation-delay": "600ms"
                                    }}
                                    ref={ref} className={clx(style["show-fade-left"], isIntersecting && style.show)} src="/images/parteners-cond.png" width='300px' height='100%' alt="Partners " />
                            </div>
                        )
                    }
                </IntersectionWatcher>
                <div className={style.conditions}>
                    <div>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        ref={ref}
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "300ms"
                                        }}
                                        className={clx(style["show-fade-bottom"], isIntersecting && style.show)}
                                    >
                                        <Condition
                                            color=""
                                            bgColor=""
                                            title={t("partners.conditions.conditions.1.title")}
                                            description={
                                                <>


                                                    {t("partners.conditions.conditions.1.description-1")}
                                                    <span className="bold">

                                                        {t("partners.conditions.conditions.1.description-2")}
                                                    </span>
                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "600ms"
                                        }}
                                        ref={ref} className={clx(style.showMd, style["show-fade-bottom"], isIntersecting && style.show)}>
                                        <Condition
                                            title={t("partners.conditions.conditions.3.title")}
                                            bgColor={"#FFE7E9"}
                                            color={"#DC3F4D"}
                                            description={
                                                <>

                                                    {t("partners.conditions.conditions.3.description-1")}
                                                    <span className="bold">
                                                        {t("partners.conditions.conditions.3.description-2")}
                                                    </span>


                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "800ms"
                                        }}
                                        ref={ref} className={clx(style.hideMd, style["show-fade-bottom"], isIntersecting && style.show)}>
                                        <Condition
                                            bgColor={"#FFE7E9"}
                                            color={"#DC3F4D"}
                                            title={t("partners.conditions.conditions.2.title")}
                                            description={
                                                <>
                                                    {t("partners.conditions.conditions.2.description-1")}
                                                    <span className="bold">
                                                        {t("partners.conditions.conditions.2.description-2")}
                                                    </span>

                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>
                    </div>

                    <div className={style.showMd} style={{width:'100%', height: '40%'}}>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <img
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "1600ms",
                                        }}
                                        ref={ref} className={clx(style["show-fade-bottom"], isIntersecting && style.show)} src="/images/parteners-cond.png" width='300px' height='100%' alt="Partners " />
                                )
                            }
                        </IntersectionWatcher>
                    </div>
                    <div>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "1200ms"
                                        }}
                                        ref={ref} className={clx(style.hideMd, style["show-fade-bottom"], isIntersecting && style.show)}>
                                        <Condition
                                            title={t("partners.conditions.conditions.3.title")}
                                            bgColor={"#FFE7E9"}
                                            color={"#DC3F4D"}
                                            description={
                                                <>
                                                    {t("partners.conditions.conditions.3.description-1")}
                                                    <span className="bold">
                                                        {t("partners.conditions.conditions.3.description-2")}
                                                    </span>

                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>
                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "1400ms"
                                        }}
                                        ref={ref} className={clx(style.showMd, style["show-fade-bottom"], isIntersecting && style.show)}>
                                        <Condition
                                            bgColor={"#FFE7E9"}
                                            color={"#DC3F4D"}
                                            title={t("partners.conditions.conditions.2.title")}
                                            description={
                                                <>
                                                    {t("partners.conditions.conditions.2.description-1")}
                                                    <span className="bold">
                                                        {t("partners.conditions.conditions.2.description-2")}
                                                    </span>

                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>

                        <IntersectionWatcher onlyFirstIntersection>
                            {
                                ({ ref, isIntersecting }) => (
                                    <div
                                        style={{
                                            "--animation-duration": "400ms",
                                            "--animation-delay": "1600ms"
                                        }}
                                        ref={ref} className={clx(style["show-fade-bottom"], isIntersecting && style.show)}>
                                        <Condition
                                            title={t("partners.conditions.conditions.4.title")}
                                            description={
                                                <>
                                                    {t("partners.conditions.conditions.4.description-1")}
                                                    <span className="bold">
                                                        {t("partners.conditions.conditions.4.description-2")}
                                                    </span>

                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </IntersectionWatcher>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Condition = ({
    title,
    description,
    bgColor,
    color
}) => {
    return (
        <div className={style.condition}>
            <div
                style={{
                    "--izi-background-color": bgColor,
                    "--izi-color": color
                }}
            >{title}</div>
            <p>{description}</p>
        </div>
    )
}