import { AmbassadorRegistrationForm } from "../components/pages/ambassadorForm"
import { BasicLayout } from "../components/shared/Layout"

export const AmbassadorFormPage = () => {
    return (
        <BasicLayout>
            <AmbassadorRegistrationForm/>
        </BasicLayout>


    )
}