import { SignUpForm } from "../components/shared/AuthForm/SignUp"
import { BasicLayout } from "../components/shared/Layout"
import { RecaptchaProvider } from "../components/shared/RecaptchaProvider"

export const SignUp = () => {
    return (
        <RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <SignUpForm />
            </BasicLayout>
        </RecaptchaProvider>

    )
}