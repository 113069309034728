import { clx } from "../../../helpers/components"
import "./style.scss"
export const Link=({
    title="Lien",
    showIcon=true,
    className,
    ...props
})=>{
    return(
        <span className={clx("_link", className)} {...props}>
            <span className="text">{title}</span>
            {
                showIcon &&(
                    <span className="icon">

                        <svg width="56" height="13" viewBox="0 0 56 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 5.61833C1.30964 5.61833 0.75 6.17797 0.75 6.86833C0.75 7.55868 1.30964 8.11833 2 8.11833V5.61833ZM46.5 8.11833C47.1904 8.11833 47.75 7.55868 47.75 6.86833C47.75 6.17797 47.1904 5.61833 46.5 5.61833V8.11833ZM46.5 4.00368H45.25H46.5ZM49.72 2.41887L48.9575 3.40938V3.40938L49.72 2.41887ZM49.72 11.3178L48.9575 10.3273L49.72 11.3178ZM46.5 9.73297H47.75H46.5ZM53.4413 8.45314L54.2038 9.44365L53.4413 8.45314ZM53.4413 5.28352L54.2038 4.29301V4.29301L53.4413 5.28352ZM2 8.11833H46.5V5.61833H2V8.11833ZM52.6788 7.46263L48.9575 10.3273L50.4825 12.3083L54.2038 9.44365L52.6788 7.46263ZM47.75 9.73297V4.00368H45.25V9.73297H47.75ZM48.9575 3.40938L52.6788 6.27402L54.2038 4.29301L50.4825 1.42836L48.9575 3.40938ZM47.75 4.00368C47.75 3.3813 48.4643 3.02973 48.9575 3.40938L50.4825 1.42836C48.3454 -0.216776 45.25 1.30671 45.25 4.00368H47.75ZM48.9575 10.3273C48.4643 10.7069 47.75 10.3554 47.75 9.73297H45.25C45.25 12.4299 48.3454 13.9534 50.4825 12.3083L48.9575 10.3273ZM54.2038 9.44365C55.8938 8.14266 55.8938 5.59399 54.2038 4.29301L52.6788 6.27402C53.0688 6.57425 53.0688 7.16241 52.6788 7.46263L54.2038 9.44365Z" fill="#008080"/>
                        </svg>

                    </span>
                )
            }
        </span>
    )
}