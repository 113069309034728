import { clx } from "../../../../helpers/components";
import style from "./style.module.scss"

export function FaqAccordion({ className, title, children, isOpen = false, onOpen: handleOpen, ...props }) {

    return (
        <div className={clx(style.accordion, className)} {...props}>
            {/* Title */}
            <div className={clx(style.titleSection)} onClick={(e) => {
                handleOpen(e)
            }}>
                <h5 className={clx(isOpen && style.opened)}>{title}</h5>
                <div className={clx(style.iconBtn)}>
                    <button
                        className={clx(isOpen && style.on)}
                        dangerouslySetInnerHTML={{
                            __html: `
                                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1" stroke="#2D264B" stroke-width="1.5" stroke-linecap="round"></path></svg>
                            `
                        }}
                    >

                    </button>
                </div>
            </div>
            {/* Content */}
            <div className={clx(style.contentContainer, !isOpen && style.closed)}>
                <div className={clx(style.contentWrapper, "content")} >
                    <div className={clx(style.content)}>
                        <div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}