import { Trans, useTranslation } from "react-i18next";
import style from "./style.module.scss";
import React, {useEffect, useState} from "react" 
import {DownloadPDF} from '../../pages/generalConditions'

 

export function DroitForm() {
    const { t } = useTranslation("translations")  
    const downloadButtonStyle = {
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        borderRadius: '0.7rem',
        padding: '0.5rem 0.7rem',
        cursor: 'pointer',
        fontSize: '0.7rem',
        transition: 'backgroundColor 0.3s ease',
        marginTop: '15px',
        fontFamily: 'Typold Regular'
        
    };

    const link = {
        color: "#008080"
    }
 
    return (
        <>
            <section>
                <div className={style.container_content}>
                    <div className={style.conditions}>
                        <div className={style.wrapperContent} style={{display: 'flex',justifyContent: 'center'}}> 
                            <div className="mt-3rem">
                                <div className={style.listTerms}>
                                    <h6 style={{marginBottom: 30}}>{t("right-page.title")}</h6>
                                    <p>{t("right-page.description1")}<span> <DownloadPDF label={t("right-page.clic")} buttonStyle={downloadButtonStyle} /></span></p><br/><br/>
                                    <p>{t("right-page.description2")}<a href="mailto:compliance@izichange.com" style={link}>compliance@izichange.com</a></p><br/><br/>
                                    <p>{t("right-page.description3")}</p>
                                </div>
                            </div>
                         </div>
                    </div>
               </div>

            </section>
        </>
    )
}
  

