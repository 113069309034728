import React from "react";
import { useTranslation } from "react-i18next";
import { FaqSection } from "../components/pages/faq/FaqSection";
import { PageTitle } from "../components/pages/faq/PageTitle";
import { Layout } from "../components/shared/Layout";

export function FAQ() {
    const {t}=useTranslation("translations")
    /*
        "faq-page":{
            "title":"Foire aux question"
        }
    */
        React.useEffect(() => {
            CheckAndHideFooter()
        }, [])
    return (
        <Layout>
            <>
                <section>
                    <PageTitle title={t("faq-page.title")}/>
                    <div className="container-content" style={{marginTop:"2rem"}}>
                        <FaqSection />
                    </div>
                </section>
            </>
        </Layout>
    )
}

export const CheckAndHideFooter = () => {
    const notifExist = localStorage.getItem('notif')
    const ftElement = document.querySelector('#preloader');
    if(notifExist || !notifExist) {
        if (ftElement) { 
            ftElement.style.height = '100%';
        }
        
    }
}
