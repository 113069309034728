import { clx } from "../../../../helpers/components"
import { Button } from "../../../ui/Button"
import { TextField } from "../../../ui/TextField"
import style from "./style.module.scss"
import { motion } from "framer-motion"
import { AnimatedBannerImage } from "../../../shared/AnimatedBannerImage"
import React, {useState, useEffect} from "react"
import { fadeScaleOut, useEntryAnimation } from "../../../shared/FramerMotionAnimations"
import { AnimatedText } from "../../../shared/AnimatedText"
import { useNavigate } from "react-router-dom"
import Joi from "joi"
import { Trans, useTranslation } from "react-i18next"
import { Apdp } from "../../apdp"


export const Banner = () => {
    const { t } = useTranslation("translations")
    const bannerRef = React.useRef()
    const { isIntersecting } = useEntryAnimation(bannerRef.current)

    const [isDivShow, setIsDivShow] = useState(false)

    useEffect(() => {
        window.onload = () => {
            const notifExist = localStorage.getItem('notif')
            if(notifExist) {
                setIsDivShow(false)
                document.body.style.overflow = 'auto';
            } else {
                setIsDivShow(true);
            }
        }
    }, [])

    const handleCheck = () => {
        setIsDivShow(false)
        localStorage.setItem('notif', 'close')
        document.body.style.overflow = 'auto';
    }

    return (
        <div className={clx("container")} >
            {/* {
                isDivShow && <div>
                    <>
                        <button onClick={handleCheck}>Fermer</button>
                    </>
                    <p>
                        <span>{t('warning-msg.text-title')}</span><br/><br/>
                        
                        <div>
                            {t('warning-msg.text1')}<br/><br/>
                            {t('warning-msg.text2')}<br/><br/>
                            {t('warning-msg.text3')}<br/><br/>
                            {t('warning-msg.text4')} <a href="http://arcadius.kougblenou@izichange.com" aria-label="Contact Arcadius Kougblenou via email">arcadius.kougblenou@izichange.com</a> {t('warning-msg.text5')}  <strong>{t('warning-msg.text6')}, {t('warning-msg.text7')}, {t('warning-msg.text8')} </strong> {t('warning-msg.text9')} <br/><br/>
                            {t('warning-msg.text10')} <a href="http://apdp.bj/wp-content/uploads/2018/11/Modèle-de-
                            lettre-plainte-Validé.pdf" aria-label="Download PDF: Modèle de lettre plainte Validé">http://apdp.bj/wp-content/uploads/2018/11/Modèle-de-
                            lettre-plainte-Validé.pdf</a> .<br/><br/>
                            {t('warning-msg.text11')}
                        </div>
                    </p>
                </div>
                
            } */}
            
            <motion.div
                className={clx(style.bannerContainer)}
                ref={bannerRef}
            >
                <motion.div

                >
                    <motion.div className={style.headSubtitle}>

                        <AnimatedText duration={100}>
                            {t("home-page.banner.subtitle-1")}
                        </AnimatedText>

                    </motion.div>
                    <motion.div className={style.headTitle}>
                        <div className={style.flexDisplayOnDesktop}>
                        <AnimatedText initialDelay={3 * 200} duration={100}>
                            {t("home-page.banner.headline")}
                        </AnimatedText>
                        </div>
                        <span className={style.flexDisplayOnMobile}>{t("home-page.banner.headline")}</span>
                    </motion.div>
                    <motion.div className={style.subHeader}>
                        <AnimatedText initialDelay={9 * 200} duration={100}>
                            <Trans t={t} i18nKey={"home-page.banner.subtitle-2"}></Trans>
                            <span className="ml-4 bolded">
                                {t("home-page.banner.subtitle-3")}
                            </span>
                        </AnimatedText>
                        <EmailField />
                    </motion.div>

                </motion.div>
                <motion.div variants={fadeScaleOut} initial={"initial"} animate={"animate"} className={style.animationContainer}>
                    <AnimatedBannerImage open={isIntersecting} />
                </motion.div>
            </motion.div>

        </div>
    )
}

const EmailField = () => {
    const [email, setEmail] = React.useState("")
    const [error, setError] = React.useState(null)
    const navigate = useNavigate()
    const handleEmailInput = (e) => {
        if (e) {
            setError(null)
            setEmail(e.target.value)
        }

    }
    const { t } = useTranslation("translations")
    const handleBlur = (e) => {
        const { error } = Joi.string().email({ tlds: false }).validate(e.target.value)
        if (error) {
            setError(error)
        }
    }
    const handleButtonClick = () => {
        if (!error) {
            navigate("/sign-up", {
                state: {
                    newUserEmail: email
                }
            })
        }
    }
    return (
        <div>
            <motion.div className={clx("flex", style.input)}>
                <div>
                    <TextField
                        placeholder={t("home-page.banner.inputPlacaholder")}
                        className={clx(style.textfield)}
                        type="email"
                        onChange={handleEmailInput}
                        value={email}
                        onBlur={handleBlur}
                    />

                </div>
                <div>
                    <Button onClick={handleButtonClick} className={clx(style.button)} ariaLabel={t("home-page.banner.startCtaBtnText")}>
                        {t("home-page.banner.startCtaBtnText")}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: `
                                        <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 4.79283C1.30964 4.79283 0.75 5.35247 0.75 6.04283C0.75 6.73318 1.30964 7.29283 2 7.29283V4.79283ZM18.6087 7.29283C19.2991 7.29283 19.8587 6.73318 19.8587 6.04283C19.8587 5.35247 19.2991 4.79283 18.6087 4.79283V7.29283ZM18.6087 3.66393H17.3587H18.6087ZM21.2827 2.34785L20.5202 3.33835V3.33835L21.2827 2.34785ZM21.2827 9.73781L20.5202 8.7473L21.2827 9.73781ZM18.6087 8.42173H19.8587H18.6087ZM24.373 7.35891L25.1355 8.34942L24.373 7.35891ZM24.373 4.72675L25.1355 3.73624V3.73624L24.373 4.72675ZM2 7.29283H18.6087V4.79283H2V7.29283ZM23.6105 6.3684L20.5202 8.7473L22.0452 10.7283L25.1355 8.34942L23.6105 6.3684ZM19.8587 8.42173V3.66393H17.3587V8.42173H19.8587ZM20.5202 3.33835L23.6105 5.71725L25.1355 3.73624L22.0452 1.35734L20.5202 3.33835ZM19.8587 3.66393C19.8587 3.32297 20.25 3.13037 20.5202 3.33835L22.0452 1.35734C20.1311 -0.116135 17.3587 1.24837 17.3587 3.66393L19.8587 3.66393ZM20.5202 8.7473C20.25 8.95528 19.8587 8.76269 19.8587 8.42173L17.3587 8.42173C17.3587 10.8373 20.1311 12.2018 22.0452 10.7283L20.5202 8.7473ZM25.1355 8.34942C26.6491 7.18419 26.6491 4.90147 25.1355 3.73624L23.6105 5.71725C23.8241 5.88173 23.8241 6.20393 23.6105 6.3684L25.1355 8.34942Z" fill="white"/>
                                        </svg>
                                        
                                        `
                            }}
                        >

                        </span>
                    </Button>
                </div>
            </motion.div>
            {
                error ? (
                    <div>
                        <p className={style.errorNotif}> {t("home-page.banner.validations.emailValidation")}</p>
                    </div>
                )
                    : <></>
            }

        </div>
    )
}
