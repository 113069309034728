import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clx } from "../../../../helpers/components";
import { Button } from "../../../ui/Button";
import { PartenerSectionTitle } from "../../../ui/PartenerSectionTItle";
import style from "./style.module.scss"



export function BecomePartners() {
    const { t } = useTranslation("translations")
    const navigate=useNavigate()
    const goTo=(url)=>()=>navigate(url)
    return (
        <div className={clx("container", style.wrapper)}>
            <div className={style.titleContainer}>
                <PartenerSectionTitle
                    title={t("partners.become-parteners.title")}
                />
                <p>{t("partners.become-parteners.description")}</p>
                <Button onClick={goTo("/new-partner")} ariaLabel={t("partners.become-parteners.btnTitle")} className={clx("button", /*style["show-fade"], style.button, isIntersecting && style.show*/)} >
                    {t("partners.become-parteners.btnTitle")}
                    <span
                        className="move"
                        dangerouslySetInnerHTML={{
                            __html: `
                                        <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 4.79283C1.30964 4.79283 0.75 5.35247 0.75 6.04283C0.75 6.73318 1.30964 7.29283 2 7.29283V4.79283ZM18.6087 7.29283C19.2991 7.29283 19.8587 6.73318 19.8587 6.04283C19.8587 5.35247 19.2991 4.79283 18.6087 4.79283V7.29283ZM18.6087 3.66393H17.3587H18.6087ZM21.2827 2.34785L20.5202 3.33835V3.33835L21.2827 2.34785ZM21.2827 9.73781L20.5202 8.7473L21.2827 9.73781ZM18.6087 8.42173H19.8587H18.6087ZM24.373 7.35891L25.1355 8.34942L24.373 7.35891ZM24.373 4.72675L25.1355 3.73624V3.73624L24.373 4.72675ZM2 7.29283H18.6087V4.79283H2V7.29283ZM23.6105 6.3684L20.5202 8.7473L22.0452 10.7283L25.1355 8.34942L23.6105 6.3684ZM19.8587 8.42173V3.66393H17.3587V8.42173H19.8587ZM20.5202 3.33835L23.6105 5.71725L25.1355 3.73624L22.0452 1.35734L20.5202 3.33835ZM19.8587 3.66393C19.8587 3.32297 20.25 3.13037 20.5202 3.33835L22.0452 1.35734C20.1311 -0.116135 17.3587 1.24837 17.3587 3.66393L19.8587 3.66393ZM20.5202 8.7473C20.25 8.95528 19.8587 8.76269 19.8587 8.42173L17.3587 8.42173C17.3587 10.8373 20.1311 12.2018 22.0452 10.7283L20.5202 8.7473ZM25.1355 8.34942C26.6491 7.18419 26.6491 4.90147 25.1355 3.73624L23.6105 5.71725C23.8241 5.88173 23.8241 6.20393 23.6105 6.3684L25.1355 8.34942Z" fill="white"/>
                                        </svg>
                                        `
                        }}
                    ></span>
                </Button>
            </div>
            <div className={style.banner}>
                   <img
                        title="Become Partners"
                        src="/images/banner-partner-cta.png"
                        style={{width: '100%', height:'100%'}}
                    />     
            </div>
        </div>
    )
}