import { Login as LoginForm } from "../components/shared/AuthForm/AuthForm"
import { BasicLayout } from "../components/shared/Layout"
import { RecaptchaProvider } from "../components/shared/RecaptchaProvider"
// import { asyncLoadScript } from "../helpers/components"

export const Login = () => {
    return (
        <RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <LoginForm />
            </BasicLayout>
        </RecaptchaProvider>


    )
}