import style from "./style.module.scss"

export function ProgressBar({
    progress=0
}) {
    return (
        <div className={style.container}>
            <div className={style.progress}
                style={{
                    "--progress":progress+"%"
                }}
            >
                <div className={style.bar}></div>
                <div className={style.bubleContainer}>
                    <p>{progress}%</p>
                    <div className={style.bulble}></div>
                </div>
            </div>
        </div>
    )
}