import { useTranslation } from "react-i18next";
import { clx, IntersectionWatcher } from "../../../../helpers/components";
import { BannerTitle } from "../../../shared/BannerTitle";
import style from "./style.module.scss"



export function PartnersBanner() {

    const { t } = useTranslation("translations")

    return (
        <div className={clx("container", style.wrapper)}>
            <div className={style.bannerContainer}>
                <div className={clx(style.bannerTitle)}>
                    <BannerTitle
                        title={t("partners.banner.title")}
                        subtitle={t("partners.banner.subtitle")}
                        btnTitle={t("partners.banner.btnTitle")}
                        buttonArrow
                        description={
                            <>
                                {t("partners.banner.description-1")}
                                <span className="bold">{t("partners.banner.description-2")}</span>
                                {t("partners.banner.description-3")}
                            </>
                        }
                    />
                </div>

                <IntersectionWatcher onlyFirstIntersection>
                    {
                        ({ ref, isIntersecting }) => (
                            <div ref={ref} className={clx(style["show-fade-bottom"], isIntersecting && style.show)}>
                                <div className={clx(style.bannerImg)} style={{width: '100%', height :'max-content'}}>
                                    <img src="/images/partnesrs-banner1.png" alt="Izichange Partners"  style={{width: '100%', height:'100%'}}/>
                                    <span
                                        className={style.arrowFlag}
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                <svg width="62" height="49" viewBox="0 0 62 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.7325 44.0163C51.7485 37.5875 52.1271 31.0962 46.62 25.1454C42.0953 20.2561 33.6802 16.1719 31.461 21.7039C28.6161 28.7957 37.4263 31.8742 40.6917 28.5151C43.8736 25.2418 41.8962 16.825 36.2227 13.5964C30.1412 10.1357 18.3032 9.71554 6.69609 13.5409" stroke="#008080" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round"/>
                                    <path d="M7.06555 11.1016L5.87844 13.7967L8.46123 14.9343" stroke="#008080" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                            
                            `
                                        }}
                                    ></span>
                                    <div className={clx(style.userFlag)}>
                                        <img
                                            className={style.userImg}
                                            src="/images/user-partners.png" alt=""
                                        />
                                        <p>
                                            {t("partners.banner.tarif")}
                                        </p>
                                        <img
                                            src="/images/Bitmap.png" alt="User Partner" style={{width: "25%", height: "25%"}}
                                        />
                                    </div>
                                    <div className={style.tag}>
                                        <div>
                                            <span>{t("partners.banner.flag.title")}</span>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: `
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 21.8327C16.983 21.8327 21.8333 16.9824 21.8333 10.9993C21.8333 5.01626 16.983 0.166016 11 0.166016C5.01687 0.166016 0.166626 5.01626 0.166626 10.9993C0.166626 16.9824 5.01687 21.8327 11 21.8327ZM14.8301 7.16919C15.2532 6.74612 15.9391 6.74612 16.3622 7.16919C16.7853 7.59226 16.7853 8.27819 16.3622 8.70125L10.2379 14.8255L10.2339 14.8295C10.1245 14.9389 9.99755 15.02 9.86203 15.0729C9.67006 15.1476 9.46109 15.1657 9.26099 15.127C9.05614 15.0873 8.86049 14.9882 8.70186 14.8295L8.69776 14.8254L5.63776 11.7654C5.21469 11.3423 5.21469 10.6564 5.63776 10.2333C6.06083 9.81028 6.74676 9.81028 7.16982 10.2333L9.46791 12.5314L14.8301 7.16919Z" fill="#008080"/>
                                    </svg>
                                
                                `
                                                }}
                                            ></span>
                                        </div>
                                        <p>{t("partners.banner.flag.description")}</p>
                                    </div>
                                </div>
                            </div>

                        )
                    }
                </IntersectionWatcher>
            </div>


        </div>
    )
}