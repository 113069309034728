import style from "./style.module.scss"
import PropTypes from "prop-types"
export function Logo({ size = "sm" }) {
    return (
        <span className={style.logo}>
            <svg className={style.sm} width="110" height="30" viewBox="0 0 90 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.012 12.0556H20.774V4.48401H23.012V12.0556Z" fill="#008081" />
                <path d="M31.5207 10.1975V12.0556H24.7897V10.4645L28.5579 6.34214H24.9105V4.48401H31.4229V6.05842L27.6719 10.1975H31.5207Z" fill="#008081" />
                <path d="M33.0856 2.30875C33.0821 2.05806 33.156 1.81205 33.2976 1.60207C33.4393 1.39209 33.6424 1.22763 33.881 1.12965C34.1196 1.03166 34.383 1.00458 34.6377 1.05186C34.8923 1.09914 35.1266 1.21864 35.3107 1.39513C35.4948 1.57162 35.6205 1.79712 35.6716 2.04291C35.7228 2.28869 35.6971 2.54364 35.5979 2.77527C35.4987 3.00691 35.3305 3.20475 35.1146 3.3436C34.8987 3.48244 34.645 3.55601 34.3857 3.55492C34.0434 3.55349 33.7152 3.42209 33.4721 3.18901C33.2289 2.95593 33.0901 2.63982 33.0856 2.30875ZM35.4558 12.0556H33.3157V4.48399H35.4558V12.0556Z" fill="#008081" />
                <path d="M37.1817 8.26148C37.1605 7.73342 37.2527 7.20686 37.4524 6.71494C37.6521 6.22303 37.955 5.77644 38.3419 5.40329C38.7289 5.03014 39.1915 4.73852 39.7008 4.54679C40.21 4.35507 40.7548 4.2674 41.3008 4.2893C41.8802 4.27414 42.4562 4.38012 42.9894 4.59999C43.5226 4.81987 44.0004 5.14845 44.3902 5.56329L42.8081 6.87066C42.6292 6.6568 42.4017 6.48579 42.1432 6.37095C41.8848 6.25611 41.6024 6.20056 41.3181 6.20863C41.046 6.20501 40.7762 6.25698 40.5263 6.36114C40.2764 6.4653 40.0521 6.61931 39.868 6.81309C39.6839 7.00687 39.5442 7.23605 39.458 7.48565C39.3718 7.73524 39.3411 7.99962 39.3678 8.26148C39.3399 8.52496 39.3704 8.79115 39.4573 9.04233C39.5441 9.29351 39.6854 9.5239 39.8715 9.71815C40.0577 9.9124 40.2846 10.066 40.537 10.1688C40.7894 10.2716 41.0615 10.3212 41.3353 10.3143C41.639 10.3248 41.9404 10.2603 42.2112 10.127C42.4819 9.99366 42.7131 9.79583 42.8829 9.55217L44.4822 10.8707C44.0916 11.3144 43.603 11.6678 43.0524 11.9046C42.5018 12.1415 41.9032 12.2558 41.3008 12.2392C40.7548 12.2595 40.2102 12.1705 39.7014 11.9779C39.1925 11.7852 38.7303 11.493 38.3436 11.1196C37.9569 10.7462 37.6541 10.2996 37.4542 9.80782C37.2542 9.31601 37.1615 8.78957 37.1817 8.26148Z" fill="#008081" />
                <path d="M53.5835 7.19887V12.0556H51.4606V7.6495C51.4778 7.45367 51.4501 7.25659 51.3794 7.07232C51.3087 6.88806 51.1969 6.72115 51.0518 6.58351C50.9067 6.44588 50.732 6.34091 50.5401 6.27611C50.3483 6.2113 50.144 6.18825 49.9418 6.20861C49.7256 6.19039 49.5078 6.21416 49.3013 6.27851C49.0947 6.34286 48.9035 6.4465 48.739 6.58334C48.5744 6.72018 48.4398 6.88745 48.3431 7.07532C48.2463 7.26319 48.1894 7.46787 48.1757 7.67732V12.0556H46.024V0.812225H48.1642V5.26285C48.4653 4.94279 48.8348 4.68992 49.247 4.5218C49.6593 4.35368 50.1045 4.27434 50.5517 4.28928C50.9537 4.26781 51.3559 4.3282 51.7322 4.46652C52.1086 4.60485 52.4507 4.81804 52.7365 5.09229C53.0223 5.36655 53.2453 5.69576 53.3912 6.05864C53.5371 6.42152 53.6026 6.80997 53.5835 7.19887Z" fill="#008081" />
                <path d="M62.5467 7.54382V12.0556H60.6252L60.5332 11.0543C60.2183 11.4321 59.8182 11.7354 59.3635 11.9408C58.9088 12.1461 58.4116 12.2482 57.9098 12.2392C56.5176 12.2392 55.1829 11.4326 55.1829 9.86928C55.1829 8.16135 56.811 7.48819 58.347 7.48819C59.0443 7.49872 59.7371 7.59976 60.4066 7.78861V7.62171C60.4066 6.58694 59.8313 6.1085 58.6807 6.1085C57.8715 6.10207 57.0821 6.35003 56.4313 6.81503L55.6661 5.34076C56.6793 4.63343 57.9031 4.26434 59.1524 4.2893C61.3846 4.2893 62.5467 5.35189 62.5467 7.54382ZM60.4066 9.07373C59.8635 8.91587 59.2995 8.83529 58.7325 8.83451C57.9443 8.83451 57.323 9.10154 57.323 9.74688C57.323 10.3032 57.789 10.6036 58.4736 10.6036C59.4343 10.6036 60.4066 10.0139 60.4066 9.07373Z" fill="#008081" />
                <path d="M71.947 7.19888V12.0556H69.8242V7.64951C69.8352 7.45605 69.8036 7.26254 69.7315 7.08177C69.6594 6.90099 69.5485 6.73708 69.406 6.60087C69.2635 6.46466 69.0927 6.35926 68.905 6.29164C68.7172 6.22402 68.5168 6.19572 68.3169 6.20862C68.0994 6.18882 67.8801 6.21138 67.6719 6.27495C67.4636 6.33853 67.2707 6.44183 67.1044 6.57875C66.9381 6.71567 66.8019 6.88343 66.7039 7.07212C66.6058 7.26082 66.5478 7.4666 66.5334 7.67732V12.0556H64.3933V4.484H66.3148L66.4126 5.41307C66.7063 5.05214 67.0839 4.76329 67.5148 4.56996C67.9456 4.37663 68.4175 4.28428 68.8922 4.30041C69.295 4.27583 69.6987 4.33352 70.077 4.46973C70.4553 4.60594 70.7997 4.81762 71.0879 5.09101C71.376 5.36439 71.6014 5.69336 71.7494 6.05652C71.8974 6.41968 71.9648 6.80891 71.947 7.19888Z" fill="#008081" />
                <path d="M81.3129 5.7858L80.0588 5.8915C80.2523 6.29012 80.3525 6.72513 80.3522 7.16549C80.3522 8.89011 78.9887 10.064 77.0039 10.064C76.7221 10.0617 76.4411 10.0356 76.164 9.98608C76.0163 9.9859 75.8741 10.0407 75.767 10.1391C75.6599 10.2375 75.5961 10.3719 75.5887 10.5146C75.5887 10.8261 75.8246 10.9485 76.21 10.9485H77.2801C79.9782 10.9485 81.3072 11.6829 81.3072 13.3741C81.3072 15.0654 80.0357 16 77.2801 16C74.5244 16 73.1206 14.9819 73.1206 13.5132C73.1184 13.1764 73.2217 12.8468 73.4168 12.5678C73.6119 12.2888 73.8897 12.0734 74.2137 11.9499C74.04 11.7868 73.9025 11.5912 73.8096 11.3751C73.7166 11.1589 73.6701 10.9267 73.6729 10.6926C73.6595 10.3958 73.7435 10.1024 73.913 9.8546C74.0825 9.60677 74.3287 9.41716 74.6164 9.31292C74.3046 9.04394 74.0571 8.7125 73.8912 8.34164C73.7253 7.97079 73.6449 7.56943 73.6557 7.16549C73.6557 5.44088 74.9731 4.28928 76.9924 4.28928C77.4393 4.28763 77.8838 4.35332 78.3098 4.48399H81.3014L81.3129 5.7858ZM77.2858 12.5507H76.0489C75.857 12.6017 75.687 12.7108 75.5638 12.862C75.4406 13.0131 75.3707 13.1984 75.3643 13.3908C75.3643 13.9916 76.0144 14.3811 77.2858 14.3811C78.5572 14.3811 79.1325 14.0528 79.1325 13.3741C79.1325 12.6954 78.7068 12.5507 77.2801 12.5507H77.2858ZM78.4019 7.16549C78.4019 6.42001 77.9244 5.96939 77.0097 5.96939C76.0949 5.96939 75.6117 6.42001 75.6117 7.16549C75.6117 7.91097 76.0949 8.38385 77.0097 8.38385C77.9244 8.38385 78.3961 7.93323 78.3961 7.16549H78.4019Z" fill="#008081" />
                <path d="M86.2606 10.4645C86.9559 10.4739 87.6279 10.2227 88.136 9.76356L89.534 11.0542C88.6623 11.8352 87.512 12.2598 86.3238 12.2392C83.8846 12.2392 82.0724 10.6481 82.0724 8.23922C82.047 7.71253 82.1355 7.1865 82.3324 6.6948C82.5292 6.20309 82.8301 5.75654 83.2156 5.3837C83.6012 5.01087 84.0629 4.71996 84.5714 4.52959C85.0799 4.33923 85.6239 4.25359 86.1685 4.27817C88.7746 4.27817 90 6.04729 90 7.86092C89.9903 8.23381 89.9538 8.60559 89.8907 8.97358H84.2758C84.3571 9.41558 84.6051 9.8127 84.9717 10.0881C85.3383 10.3635 85.7976 10.4976 86.2606 10.4645ZM84.2758 7.5605H87.9174C87.9076 7.3495 87.8533 7.14266 87.758 6.95265C87.6626 6.76263 87.5281 6.59344 87.3628 6.45543C87.1974 6.31741 87.0047 6.21349 86.7964 6.15C86.5881 6.08652 86.3685 6.06482 86.1513 6.08623C85.7064 6.06824 85.2702 6.20822 84.9247 6.47979C84.5792 6.75135 84.3484 7.13574 84.2758 7.5605Z" fill="#008081" />
                <path d="M9.9527 0H5.81054C2.60147 0 0 2.51567 0 5.61891V9.62448C0 12.7277 2.60147 15.2434 5.81054 15.2434H9.9527C13.1618 15.2434 15.7632 12.7277 15.7632 9.62448V5.61891C15.7632 2.51567 13.1618 0 9.9527 0Z" fill="#008081" />
                <path d="M4.7865 5.90266V10.9096C4.7865 11.094 4.86226 11.2709 4.99713 11.4013C5.13199 11.5318 5.3149 11.605 5.50562 11.605C5.69635 11.605 5.87926 11.5318 6.01412 11.4013C6.14899 11.2709 6.22475 11.094 6.22475 10.9096V5.90266C6.22475 5.71822 6.14899 5.54134 6.01412 5.41093C5.87926 5.28051 5.69635 5.20724 5.50562 5.20724C5.3149 5.20724 5.13199 5.28051 4.99713 5.41093C4.86226 5.54134 4.7865 5.71822 4.7865 5.90266Z" fill="white" />
                <path d="M5.753 4.63422C6.26137 4.63422 6.67348 4.2357 6.67348 3.7441C6.67348 3.2525 6.26137 2.85397 5.753 2.85397C5.24463 2.85397 4.83252 3.2525 4.83252 3.7441C4.83252 4.2357 5.24463 4.63422 5.753 4.63422Z" fill="#DC3E4D" />
                <path d="M9.74561 5.40751L5.99465 9.53547L5.05691 10.5702C4.92575 10.7028 4.85254 10.8791 4.85254 11.0626C4.85254 11.246 4.92575 11.4224 5.05691 11.5549C5.19231 11.6827 5.37399 11.7542 5.56318 11.7542C5.75236 11.7542 5.93404 11.6827 6.06944 11.5549L9.78013 7.44924L10.7409 6.39221C10.872 6.25968 10.9453 6.08331 10.9453 5.89986C10.9453 5.71641 10.872 5.54005 10.7409 5.40751C10.6055 5.27974 10.4238 5.20822 10.2346 5.20822C10.0454 5.20822 9.86375 5.27974 9.72835 5.40751H9.74561Z" fill="white" />
                <path d="M5.56312 11.7552H10.2115C10.4018 11.7538 10.5838 11.68 10.7184 11.5499C10.8529 11.4198 10.9292 11.2438 10.9307 11.0598C10.9277 10.8763 10.851 10.7011 10.7168 10.5713C10.5825 10.4415 10.4013 10.3673 10.2115 10.3644H5.56312C5.3724 10.3644 5.18948 10.4377 5.05462 10.5681C4.91976 10.6985 4.84399 10.8754 4.84399 11.0598C4.84399 11.2443 4.91976 11.4211 5.05462 11.5516C5.18948 11.682 5.3724 11.7552 5.56312 11.7552Z" fill="white" />
                <path d="M10.2518 5.20724H5.68971C5.49945 5.2087 5.31741 5.28243 5.18287 5.41253C5.04833 5.54263 4.97209 5.71867 4.97058 5.90265C4.97497 6.08576 5.05214 6.26018 5.18607 6.38969C5.31999 6.51919 5.50036 6.59382 5.68971 6.59806H10.2518C10.4426 6.59806 10.6255 6.5248 10.7603 6.39438C10.8952 6.26397 10.971 6.08709 10.971 5.90265C10.971 5.71822 10.8952 5.54134 10.7603 5.41093C10.6255 5.28051 10.4426 5.20724 10.2518 5.20724Z" fill="white" />
            </svg>
            <svg className={style.md} width="168" height="31" viewBox="0 0 168 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.9558 23.3575H38.7783V8.6875H42.9558V23.3575Z" fill="#008081" />
                <path d="M58.8387 19.7574V23.3575H46.2741V20.2748L53.3081 12.2876H46.4996V8.6875H58.6561V11.7379L51.6543 19.7574H58.8387Z" fill="#008081" />
                <path d="M61.7596 4.47326C61.7533 3.98754 61.891 3.51091 62.1555 3.10407C62.4199 2.69723 62.799 2.37859 63.2444 2.18875C63.6899 1.9989 64.1816 1.94644 64.6569 2.03804C65.1322 2.12965 65.5695 2.36117 65.9132 2.70312C66.257 3.04507 66.4915 3.48198 66.587 3.95819C66.6825 4.4344 66.6346 4.92836 66.4494 5.37715C66.2642 5.82594 65.9502 6.20926 65.5472 6.47827C65.1442 6.74728 64.6706 6.88982 64.1866 6.88772C63.5475 6.88495 62.935 6.63036 62.4811 6.17876C62.0272 5.72717 61.7681 5.11471 61.7596 4.47326ZM66.1841 23.3578H62.1892V8.68779H66.1841V23.3578Z" fill="#008081" />
                <path d="M69.4058 16.0062C69.3663 14.9831 69.5384 13.9628 69.9112 13.0098C70.2839 12.0567 70.8493 11.1914 71.5716 10.4684C72.2939 9.74546 73.1575 9.18044 74.1081 8.80898C75.0587 8.43751 76.0756 8.26765 77.0949 8.31008C78.1764 8.28072 79.2516 8.48605 80.2469 8.91205C81.2421 9.33806 82.134 9.97468 82.8617 10.7784L79.9085 13.3115C79.5746 12.8971 79.1498 12.5658 78.6674 12.3433C78.1849 12.1208 77.6578 12.0132 77.1271 12.0288C76.6192 12.0218 76.1155 12.1225 75.6491 12.3243C75.1826 12.5261 74.7639 12.8245 74.4202 13.1999C74.0766 13.5754 73.8158 14.0194 73.6549 14.503C73.494 14.9866 73.4366 15.4988 73.4866 16.0062C73.4345 16.5167 73.4914 17.0324 73.6536 17.5191C73.8157 18.0057 74.0793 18.4521 74.4269 18.8285C74.7744 19.2048 75.1978 19.5025 75.669 19.7017C76.1402 19.9009 76.6482 19.997 77.1593 19.9836C77.7261 20.0039 78.2887 19.879 78.7942 19.6206C79.2996 19.3623 79.7311 18.979 80.0481 18.5069L83.0335 21.0615C82.3044 21.9213 81.3923 22.6059 80.3644 23.0648C79.3366 23.5237 78.2193 23.7453 77.0949 23.7131C76.0755 23.7524 75.0591 23.58 74.1092 23.2067C73.1593 22.8334 72.2965 22.2673 71.5747 21.5439C70.8529 20.8204 70.2877 19.9551 69.9144 19.0022C69.5412 18.0493 69.3681 17.0294 69.4058 16.0062Z" fill="#008081" />
                <path d="M100.022 13.9474V23.3573H96.0598V14.8205C96.0919 14.441 96.0401 14.0592 95.9082 13.7022C95.7763 13.3452 95.5675 13.0218 95.2967 12.7551C95.0259 12.4884 94.6997 12.2851 94.3416 12.1595C93.9834 12.0339 93.6021 11.9893 93.2247 12.0287C92.8212 11.9934 92.4146 12.0395 92.029 12.1642C91.6434 12.2889 91.2865 12.4897 90.9794 12.7548C90.6722 13.0199 90.4209 13.344 90.2404 13.708C90.0598 14.072 89.9536 14.4685 89.9279 14.8744V23.3573H85.9115V1.57324H89.9064V10.1963C90.4684 9.57621 91.1582 9.08627 91.9278 8.76054C92.6974 8.43481 93.5284 8.28108 94.3631 8.31003C95.1135 8.26844 95.8642 8.38544 96.5668 8.65344C97.2693 8.92145 97.9079 9.3345 98.4414 9.86587C98.9748 10.3972 99.3912 11.0351 99.6636 11.7382C99.9359 12.4412 100.058 13.1939 100.022 13.9474Z" fill="#008081" />
                <path d="M116.754 14.6165V23.3582H113.167L112.995 21.418C112.407 22.1501 111.661 22.7376 110.812 23.1356C109.963 23.5335 109.035 23.7313 108.098 23.7139C105.499 23.7139 103.008 22.151 103.008 19.1221C103.008 15.813 106.047 14.5087 108.914 14.5087C110.216 14.5291 111.509 14.7249 112.759 15.0908V14.7674C112.759 12.7626 111.685 11.8356 109.537 11.8356C108.027 11.8231 106.553 12.3035 105.338 13.2045L103.91 10.3481C105.801 8.97764 108.086 8.26253 110.418 8.3109C114.585 8.3109 116.754 10.3697 116.754 14.6165ZM112.759 17.5807C111.745 17.2749 110.692 17.1187 109.634 17.1172C108.163 17.1172 107.003 17.6346 107.003 18.885C107.003 19.9628 107.873 20.5449 109.151 20.5449C110.944 20.5449 112.759 19.4023 112.759 17.5807Z" fill="#008081" />
                <path d="M134.301 13.9478V23.3577H130.339V14.8209C130.359 14.446 130.3 14.0711 130.166 13.7209C130.031 13.3706 129.824 13.053 129.558 12.7891C129.292 12.5252 128.973 12.321 128.623 12.19C128.272 12.059 127.898 12.0041 127.525 12.0291C127.119 11.9908 126.71 12.0345 126.321 12.1577C125.932 12.2808 125.572 12.481 125.262 12.7463C124.951 13.0115 124.697 13.3366 124.514 13.7022C124.331 14.0678 124.223 14.4665 124.196 14.8748V23.3577H120.201V8.68769H123.788L123.97 10.4878C124.519 9.78846 125.223 9.22881 126.028 8.85423C126.832 8.47966 127.713 8.30074 128.599 8.33198C129.351 8.28436 130.104 8.39614 130.811 8.66005C131.517 8.92395 132.16 9.33408 132.697 9.86377C133.235 10.3934 133.656 11.0308 133.932 11.7344C134.209 12.4381 134.334 13.1922 134.301 13.9478Z" fill="#008081" />
                <path d="M151.784 11.2101L149.443 11.4149C149.804 12.1872 149.991 13.0301 149.991 13.8833C149.991 17.2247 147.446 19.4991 143.741 19.4991C143.215 19.4947 142.69 19.4442 142.173 19.3482C141.897 19.3478 141.632 19.4539 141.432 19.6446C141.232 19.8352 141.113 20.0957 141.099 20.3721C141.099 20.9758 141.539 21.2129 142.259 21.2129H144.256C149.293 21.2129 151.773 22.6357 151.773 25.9125C151.773 29.1893 149.4 31.0001 144.256 31.0001C139.112 31.0001 136.492 29.0276 136.492 26.182C136.488 25.5294 136.681 24.8909 137.045 24.3503C137.409 23.8097 137.927 23.3924 138.532 23.1531C138.208 22.8371 137.951 22.4581 137.778 22.0393C137.604 21.6205 137.518 21.1706 137.523 20.7171C137.498 20.142 137.655 19.5736 137.971 19.0934C138.287 18.6132 138.747 18.2459 139.284 18.0439C138.702 17.5228 138.24 16.8806 137.93 16.1621C137.621 15.4435 137.471 14.6659 137.491 13.8833C137.491 10.5418 139.95 8.31061 143.719 8.31061C144.554 8.30741 145.383 8.43469 146.178 8.68787H151.763L151.784 11.2101ZM144.267 24.3172H141.958C141.6 24.416 141.282 24.6273 141.053 24.9202C140.823 25.213 140.692 25.5721 140.68 25.9448C140.68 27.1089 141.894 27.8635 144.267 27.8635C146.64 27.8635 147.714 27.2275 147.714 25.9125C147.714 24.5975 146.919 24.3172 144.256 24.3172H144.267ZM146.35 13.8833C146.35 12.4389 145.459 11.5658 143.751 11.5658C142.044 11.5658 141.142 12.4389 141.142 13.8833C141.142 15.3276 142.044 16.2438 143.751 16.2438C145.459 16.2438 146.34 15.3708 146.34 13.8833H146.35Z" fill="#008081" />
                <path d="M161.02 20.2753C162.318 20.2934 163.572 19.8067 164.521 18.9172L167.13 21.4179C165.503 22.931 163.356 23.7536 161.138 23.7138C156.585 23.7138 153.202 20.631 153.202 15.9638C153.154 14.9433 153.32 13.9241 153.687 12.9714C154.055 12.0188 154.616 11.1536 155.336 10.4312C156.055 9.70884 156.917 9.1452 157.867 8.77637C158.816 8.40753 159.831 8.24161 160.848 8.28923C165.713 8.28923 168 11.7169 168 15.2308C167.982 15.9533 167.914 16.6736 167.796 17.3866H157.315C157.467 18.243 157.929 19.0124 158.614 19.546C159.298 20.0796 160.155 20.3395 161.02 20.2753ZM157.315 14.6488H164.113C164.094 14.2399 163.993 13.8392 163.815 13.471C163.637 13.1029 163.386 12.7751 163.077 12.5077C162.769 12.2403 162.409 12.0389 162.02 11.9159C161.631 11.7929 161.221 11.7509 160.816 11.7924C159.985 11.7575 159.171 12.0287 158.526 12.5549C157.881 13.081 157.45 13.8258 157.315 14.6488Z" fill="#008081" />
                <path d="M18.5784 0H10.8463C4.85607 0 0 4.87412 0 10.8866V18.6474C0 24.66 4.85607 29.5341 10.8463 29.5341H18.5784C24.5686 29.5341 29.4247 24.66 29.4247 18.6474V10.8866C29.4247 4.87412 24.5686 0 18.5784 0Z" fill="#008081" />
                <path d="M8.93481 11.4362V21.1372C8.93481 21.4945 9.07624 21.8372 9.32798 22.0899C9.57973 22.3426 9.92116 22.4846 10.2772 22.4846C10.6332 22.4846 10.9746 22.3426 11.2264 22.0899C11.4781 21.8372 11.6195 21.4945 11.6195 21.1372V11.4362C11.6195 11.0789 11.4781 10.7362 11.2264 10.4835C10.9746 10.2308 10.6332 10.0889 10.2772 10.0889C9.92116 10.0889 9.57973 10.2308 9.32798 10.4835C9.07624 10.7362 8.93481 11.0789 8.93481 11.4362Z" fill="white" />
                <path d="M10.739 8.97853C11.6879 8.97853 12.4572 8.20639 12.4572 7.25391C12.4572 6.30143 11.6879 5.5293 10.739 5.5293C9.79 5.5293 9.02072 6.30143 9.02072 7.25391C9.02072 8.20639 9.79 8.97853 10.739 8.97853Z" fill="#DC3E4D" />
                <path d="M18.1918 10.4769L11.19 18.4749L9.43954 20.4797C9.1947 20.7365 9.05804 21.0782 9.05804 21.4337C9.05804 21.7891 9.1947 22.1308 9.43954 22.3876C9.69228 22.6351 10.0314 22.7737 10.3846 22.7737C10.7377 22.7737 11.0769 22.6351 11.3296 22.3876L18.2562 14.4328L20.0496 12.3848C20.2944 12.128 20.4311 11.7863 20.4311 11.4309C20.4311 11.0754 20.2944 10.7337 20.0496 10.4769C19.7969 10.2294 19.4577 10.0908 19.1046 10.0908C18.7514 10.0908 18.4123 10.2294 18.1596 10.4769H18.1918Z" fill="white" />
                <path d="M10.3846 22.7758H19.0616C19.4168 22.773 19.7566 22.6301 20.0077 22.378C20.2589 22.126 20.4012 21.7849 20.404 21.4284C20.3985 21.0728 20.2553 20.7333 20.0047 20.4818C19.7542 20.2304 19.4159 20.0866 19.0616 20.0811H10.3846C10.0286 20.0811 9.68712 20.223 9.43538 20.4757C9.18363 20.7284 9.04221 21.0711 9.04221 21.4284C9.04221 21.7858 9.18363 22.1285 9.43538 22.3811C9.68712 22.6338 10.0286 22.7758 10.3846 22.7758Z" fill="white" />
                <path d="M19.1368 10.0889H10.6208C10.2657 10.0917 9.92588 10.2345 9.67475 10.4866C9.42361 10.7387 9.28128 11.0798 9.27847 11.4362C9.28666 11.791 9.43072 12.1289 9.68071 12.3798C9.9307 12.6308 10.2674 12.7754 10.6208 12.7836H19.1368C19.4928 12.7836 19.8343 12.6416 20.086 12.3889C20.3378 12.1363 20.4792 11.7936 20.4792 11.4362C20.4792 11.0789 20.3378 10.7362 20.086 10.4835C19.8343 10.2308 19.4928 10.0889 19.1368 10.0889Z" fill="white" />
            </svg>
        </span>



    )
}
Logo.propTypes = {
    size: PropTypes.oneOf(["sm", "md"])
}