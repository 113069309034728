import { Layout, BasicLayoutContainerWithoutHeader} from "../components/shared/Layout"
import { BasicLayout } from "../components/shared/Layout"
import React,{useEffect,useState} from "react"
import { Header } from "../components/shared/Header";
import style from "../components/pages/ambassador/banner/style.module.scss"
import CafeCryptoContent from "../components/pages/CafeCryptoContent";


export const CafeCrypto = () => { 

    return ( 
            <section>
                <div className={style.bannerHeader}>
                <BasicLayout isLight={true}>   
                    <CafeCryptoContent />
                </BasicLayout>
                    
                </div>
            </section>  )
}
