import PropTypes from "prop-types"
import React from "react"
// import { createElement } from "react"
import { clx, getVariableColor } from "../../../helpers/components"
import style from "./style.module.scss"

export function Text({variant="p", children, color, size, className, ...props}){
    const getTag=()=>{
        if(["subtitle"].includes(variant)){
            return "p"
        }
        return variant
    }
    const el =React.createElement(getTag(), {
        style:{
            ["--izi-color"]:getVariableColor(color, "izi-primary-dark-color") ,
            ["--izi-font-size"]:size ?? ""
        }, 
        className:clx(style[variant], style["text"], className),
        ...props
    }, children);
    return(
        el
    )
}

Text.propTypes={
    children:PropTypes.node,
    color:PropTypes.string,
    variant:PropTypes.string,
    size:PropTypes.string,
    className:PropTypes.string
}

