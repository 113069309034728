import { Advantages } from "../components/pages/partners/Advantages";
import { PartnersBanner } from "../components/pages/partners/banner";
import { BecomePartners } from "../components/pages/partners/BecomePartener";
import { Conditions } from "../components/pages/partners/Conditions";
import { Layout } from "../components/shared/Layout";

export function Parteners(){
    return(
        <Layout>
            <PartnersBanner/>
            <Conditions/>
            <Advantages/>
            <BecomePartners/>
        </Layout>
    )
}