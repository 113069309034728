
import React from "react";
import { useTranslation } from "react-i18next";
import { FaqAccordion } from "../FaqAccordion";
import style from "./style.module.scss"
/*
    {
        
    }
*/
// export const CheckAndHideFooter = () => {
//     const notifExist = localStorage.getItem('notif')
//     const ftElement = document.querySelector('#preloader');
//     if(notifExist || !notifExist) {
//         if (ftElement) { 
//             ftElement.style.height = '100%';
//         }
        
//     }
// }

export function FaqSection() {
    // React.useEffect(() => {
    //     CheckAndHideFooter()
    // }, [])

    // var dc = document.querySelector('.ftMobile') 
    const {t}=useTranslation("translations")
    const questions = [
        {
            title: t("faq-page.q-1.title"),
            description: <>
                {t("faq-page.q-1.description-1")}
                <ul>
                    <li>{t("faq-page.q-1.description-2")}</li>
                    <li>{t("faq-page.q-1.description-3")}</li>
                    <li>{t("faq-page.q-1.description-4")}</li>
                </ul>
    
            </>
        },
        {
            title: t("faq-page.q-2.title"),
            description: t("faq-page.q-2.description")
        },
        {
            title: t("faq-page.q-3.title"),
            description: <>
                {t("faq-page.q-3.description-1")}
                
                <ul>
                    <li>
                        {t("faq-page.q-3.description-2")}
                        
                    </li>
                    <li>
                        {t("faq-page.q-3.description-3")}
                        
                    </li>
                    <li>
                    {t("faq-page.q-3.description-4")}
                    
                    </li>
                    <li>
                    {t("faq-page.q-3.description-5")}
                    
                    </li>
                    <li>
                    {t("faq-page.q-3.description-6")}
                    
                    </li>
                    <li>
                    {t("faq-page.q-3.description-7")}
                    
                    </li>
                    <li>
                    {t("faq-page.q-3.description-8")}
                    
                    </li>
                </ul>
            </>
        },
        {
            title: t("faq-page.q-4.title"),
            description: t("faq-page.q-4.description")
        },
        {
            title: t("faq-page.q-5.title"),
            description: t("faq-page.q-5.description")
        },
        {
            title: t("faq-page.q-6.title"),
            description: t("faq-page.q-6.description")
        },
        {
            title:  t("faq-page.q-7.title"),
            description: <>
                {t("faq-page.q-7.description-1")}
                
                <ul>
                    <li>
                    {t("faq-page.q-7.description-2")}
                    
                    </li>
                    <li>
                    {t("faq-page.q-7.description-3")}
                    
    
                    </li>
                    <li> 
                    {t("faq-page.q-7.description-4")}
    
                    </li>
                </ul>
                <b>
                {t("faq-page.q-7.description-5")}
                
                </b>
                {t("faq-page.q-7.description-6")}
                
    
            </>
        },
        {
            title: t("faq-page.q-8.title"),
            description: t("faq-page.q-8.description")
        },
        {
            title: t("faq-page.q-9.title"),
            description: t("faq-page.q-9.description")
        },
        {
            title: t("faq-page.q-10.title"),
            description: t("faq-page.q-10.description")
        },
    
    ]
    const [index, setIndex] = React.useState(null)
    const handleOpen = (i) => {
        setIndex(prev => i === prev ? null : i)
    }
    return (
        <>
            <section>
                <div className={style.section}>
                    <div className={style.sectionContainer}>
                        {
                            questions.map((q, i) => (
                                <FaqAccordion
                                    key={i}
                                    title={q.title}
                                    isOpen={i === index}
                                    onOpen={() => handleOpen(i)}
                                >
                                    {q.description}
                                </FaqAccordion>
                            ))
                        }
                    </div>

                </div>
            </section>
        </>
    )
}