import { clx } from "../../../../helpers/components"
import { motion } from "framer-motion"
import style from "./style.module.scss"
import { SectionHeader } from "../../../shared/TitleHeader"
import { AnimatedGatewayList } from "../../../shared/AnimatedGatewayList"
import { fadeScaleOut, useEntryAnimation } from "../../../shared/FramerMotionAnimations"
import React from "react"
import { useTranslation } from "react-i18next"

export const CountrySection = () => {
    const [ref, setRef]=React.useState(null)
    const {t}=useTranslation("translations")
    const {hasAnimated}=useEntryAnimation(ref)
    const [windowWidth, setWindowWidth]=React.useState(null);
    const isMobile=windowWidth===null ? false : (windowWidth < 800 ? true: false)
    
    
    React.useEffect(()=>{
        setWindowWidth(window.innerWidth)
    }, [])

    return (
        <div className="container" id="countrysection">
            <motion.div initial={"initial"} animate={(hasAnimated||isMobile) && "animate"} variants={{...fadeScaleOut, initial:{...fadeScaleOut.initial}, animate:{...fadeScaleOut.animate, scale:1, type:"shake", transition:{duration: 1, delay:0.5}}}}  ref={setRef} className={clx(style.section)}>
                <motion.div>
                    <SectionHeader
                        subtitle={t('home-page.country-section.subtitle')}
                        title={<>{t('home-page.country-section.title-1')} <span className="bolded">{t('home-page.country-section.title-2')}</span> {t('home-page.country-section.title-3')}</>}
                        description={<>{t('home-page.country-section.description')} <span className="ml-2 bolded">{t('home-page.country-section.description-1')}</span></>}
                    />
                </motion.div>
                <motion.div>
                    <AnimatedGatewayList/>
                </motion.div>
            </motion.div>
        </div>
    )
}