import { useTranslation } from "react-i18next";
import { addMinutes, format } from "date-fns"
import React, { useState, useEffect, useContext } from "react"
import { Button } from "../../ui/Button";
import style from "./style.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert } from "../../shared/Alert";
import axios from "axios";
import { RecaptchaContext } from "../../shared/RecaptchaProvider";
import { BASE_URL } from "../../../helpers/func";

export const EmailPage = () => {
    const { t, i18n } = useTranslation("translations")
    const { apiKey } = useContext(RecaptchaContext)
    const { state } = useLocation()
    const navigate = useNavigate()
    const [isSent, setIsSent] = React.useState(null)

    const [isLoading, setIsLoading] = React.useState(false)
    const [loading, setLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)
    React.useEffect(() => {
        if (!(state?.auth_token)) {
            navigate("/login")
        }
    }, [state?.auth_token])
    const handleResend = () => {
        
        window.grecaptcha.ready(function callback(){
            window.grecaptcha.execute(apiKey)
                .then((token) => {
                    if (isLoading || isSent) return;
                    setIsSent(null)

                    const params = new URLSearchParams();
                    params.append("auth_token", state?.auth_token);
                    params.append("token", token);

                    const options = {
                        Accept: 'application/json',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'lang': i18n.language,
                            'appversion': "2.0.1"
                        },
                        data: params,
                        url: BASE_URL + "/api/pu_confirme_mail/web"
                    };

                    setIsLoading(true)
                    axios(options)
                        .then(({ data }) => { 
                            if (!data.succes) {
                                if (data.recaptcha) {
                                }
                            }
                            setIsSent(data.succes)
                        })
                        .catch(e => { 
                            setIsSent(false)
                        })
                        .finally(() => setIsLoading(false))
                })
        })
    }
    const CheckAndHideFooter = () => {
        const notifExist = localStorage.getItem('notif')
        const ftElement = document.querySelector('.ftMobile');
        const footers = document.getElementById('footerCustom');
        if (notifExist || !notifExist) { 
            if (ftElement)
                ftElement.style.display = 'none';
            if (footers)
                footers.style.display = 'none';

        }
    }


    useEffect(() => {
        //const notifExist = localStorage.getItem('notif')
        window.addEventListener('load', () => {
            setLoading(false);
            localStorage.setItem('isLoading', 'false');

        });
        window.addEventListener('beforeunload', () => {
            localStorage.setItem('isLoading', 'true');
        });

        const stateIsLoading = localStorage.getItem('isLoading');
        if (stateIsLoading === false) {
            setLoading(false);
        } else {
            setLoading(false);
        }


        CheckAndHideFooter();

        return () => {
            window.removeEventListener('load', () => {
                setLoading(false);
                localStorage.setItem('isLoading', 'false');
            });
            window.removeEventListener('beforeunload', () => {
                localStorage.setItem('isLoading', 'true');
            });
        };
    }, [])



    return (
        <div>
            <>
                {
                    loading ? (<div style={{ width: '100%', height: '100vh' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Loading ...</div>
                    </div>) : (<div className={style.container}>
                        <img src="/images/mail-sent.svg" alt='envoi mail' />
                        <div>
                            <p>{t("email-confirmation.description")}</p>
                            {isSent !== null && <AlertCounter isEmailSent={isSent} />}

                            <Button loading={isLoading} disabled={isLoading || isSent} onClick={handleResend} ariaLabel={t("email-confirmation.resendMail")}>
                                {t("email-confirmation.resendMail")}
                            </Button>
                        </div>
                    </div>)
                }
            </>
        </div>
    )
}

export const AlertCounter = ({ isEmailSent = true , timeOut= 15}) => {
    const [open, setOpen] = React.useState(false)
    const { t } = useTranslation("translations")
    const [startDate, setStartDate] = React.useState(null)
    const [date, setDate] = React.useState(null)
    const counter = React.useRef(null)

    React.useEffect(() => {

        if (isEmailSent) {
            setStartDate(Date.now())

        }
        setOpen(true)

    }, [isEmailSent])

    React.useEffect(() => {
        clearInterval(counter.current)
        if (startDate) {
            counter.current = setInterval(() => {
                if (!startDate) clearInterval(counter.current);
                //15min
                if (Date.now() >= addMinutes(startDate, timeOut)) {
                    clearInterval(counter.current)
                    setStartDate(null)
                    setOpen(false)
                    setDate(null)
                } else {
                    setDate(new Date(addMinutes(startDate, timeOut) - new Date()))
                }
            }, 1000)
        }
    }, [startDate])

    return (
        <div className="my-1rem">
            <Alert open={open} theme={isEmailSent ? "success" : "danger"} onClose={!isEmailSent ? () => {
                setOpen(false)
            } : null}>
                {
                    isEmailSent ? t("email-confirmation.sentSuccess") : t("email-confirmation.sentFailed")
                }
                {
                    isEmailSent && date && <span> {format(date, "mm:ss")}</span>
                }

            </Alert>
        </div>
    )
}